.form{
    &-items__list {
        width: calc(100% + px(30));
        margin-left: px(-15);
        margin-right: px(-15);
        display: flex;
        flex-wrap: wrap;
        @media(max-width:1024px){
            width: calc(100% + 20px);
            margin-left: -10px;
            margin-right: -10px;
        }
    }
    &-field{
        width: 50%;
        position: relative;
        padding: 0 px(15);
        margin-bottom: px(40);
        @media(max-width:1650px){
            margin-bottom: px(30);
        }
        @media(max-width:1024px){
            margin-bottom: 20px;
            padding: 0 10px;
        }
        @media(max-width:576px){
            width: 100% !important;
        }
        &.full-field{
            width:100%;
        }
        &.textarea-field{
            .form__field-input::after{
                display: none;
            }
        }

        //select dropdown
        .select-info{
            &.active{
                .icon-trigger{
                    transform: rotate(180deg);
                }
            }
            &.active+ .form__field__line::after{
                width: 100%;
            }
            .select-info{
                box-sizing: border-box;
                position: relative;
                width:100%;
                background: transparent;
                transition: .3s;
            }
            .select__list{
                width: 100%;
                overflow: hidden;
                overflow-y: auto;
                scrollbar-color: $baseBlack $baseWhite;
                scrollbar-width: thin;
                padding-right: px(15);
                max-height: px(81);
                @media(max-width:1024px){
                    padding-right: 7px;
                    max-height: 72px;
                }
                &::-webkit-scrollbar{
                    width: px(3);
                    background-color: $baseWhite;
                }
                &::-webkit-scrollbar-thumb{
                    width: px(3);
                    background-color: $baseBlack;
                }
            }
            .select__dropdown {
                position: absolute;
                width: 100%;
                background: transparent;
                display: none;
                padding: px(10);
                z-index: 10;
                margin-top: 0;
                background: linear-gradient(224.78deg, #538CFB 3.56%, #EE9DFB 96.77%);
                border-radius: 0;
                border-bottom-left-radius: px(5);
                border-bottom-right-radius: px(5);
                @media(max-width:1024px){
                    padding: 10px;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }

            .select__trigger {
                width: 100%;
                padding-bottom: px(10);
                border-radius: px(100);
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                position: relative;
                z-index: 1;
                transition: .3s;
                padding-right: px(17);
                @media(max-width:1024px){
                    padding-bottom: 10px;
                    border-radius: 100px;
                    padding-right: 17px;
                }
            }
            .select-arrow {
                width: px(12);
                height: px(8);
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 0;
                top:50%;
                transform: translateY(-70%);
                @media(max-width:1024px){
                    width: 10px;
                    height: 6px;
                }
                .icon{
                    width: px(12);
                    height: px(8);
                    fill: #707070;
                    transition: .3s;
                    @media(max-width:1024px){
                        width: 10px;
                        height: 6px;
                    }
                }
            }
            

            .select__value{
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-size: px(16);
                line-height: px(20);
                color: #707070;
                transition: .3s;
                -webkit-line-clamp: 1;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                @media(max-width:1024px){
                    font-size: 14px;
                    line-height: 18px;
                }
            }
            .select__option{
                width: fit-content;
                margin-bottom: px(10);
                @media(max-width:1024px){
                    margin-bottom: 10px;
                }
                cursor: pointer;
                &:last-child{
                    margin-bottom: 0;
                }
                &.active{
                    .select__option-info{
                        color: $baseBlack;
                    }
                }
                &:hover{
                    .select__option-info{
                        @media(min-width: 1025px){
                            color: $baseBlack;
                        }
                        
                    }
                }
            }

            .select__option-info{
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: px(16);
                transition: .3s;
                line-height: px(20);
                color: $baseWhite;
                width: fit-content;
                @media(max-width:1024px){
                    font-size: 14px;
                    line-height: 18px;
                }
            }
            
            .select__option.active{
                box-shadow: none;
                max-width: 100%;
            }
        }
    }
    &__field-name{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: px(16);
        line-height: px(20);
        color: #707070;
        margin-bottom: px(10);
        @media(max-width:1024px){
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 8px;
        }
    }
    
    &__field-input{
        width: 100%;
        position: relative;
        &::after{
            bottom: 0;
            left: 0;
            content: '';
            position: absolute;
            height: px(2);
            background-color: #EDEDED;
            border-radius: px(16);
            width: 100%;
            transition: .3s;
            @media(max-width:1024px){
                height: 2px;
                border-radius: 16px;
            }
        }
    }
    &__field__line{
        position: relative;
        width: 100%;
        &::after{
            background: linear-gradient(224.78deg, #538CFB 3.56%, #EE9DFB 96.77%);
            bottom: 0;
            left: 0;
            content: '';
            position: absolute;
            height: 2px;
            border-radius: 16px;
            width: 0%;
            z-index: 1;
            transition: .3s;
        }
    }
    &__input{
        width: 100%;
        position: relative;
        transition: .3s;
        display: flex;
        align-items: center;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: px(16);
        line-height: px(20);
        color: #707070;
        padding-bottom: px(10);
        
        @media(max-width:1024px){
            font-size: 14px;
            line-height: 18px;
            padding-bottom: 10px;
        }
        &::placeholder{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            color: #707070;
            transition: .3s;
        }
        &:focus+ .form__field__line::after{
            width: 100%;
        }
        &:focus::placeholder{
            color: $basePurple;
        }
    }
    &__textarea{
        border: 2px solid #EDEDED;
        border-radius: 3px;
        width: 100%;
        display: flex;
        min-height: 100px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: px(16);
        line-height: px(20);
        color: #707070;
        transition: .3s;
        padding: px(10);
        @media(max-width:1024px){
            font-size: 14px;
            line-height: 18px;
            min-height: 60px;
            padding: 10px;
        }
        &:focus{
            border-image-slice: 1;
            border-image-source: linear-gradient(224.78deg, #538CFB 3.56%, #EE9DFB 96.77%);
        }
    }

    &__checkbox{
        width: 100%;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: px(20);
        @media(max-width:1024px){
            margin-bottom: 20px;
        }
        input{
            display: none;
        }
        input:checked+ .form__checkbox-retangle{
            border-color: $baseBlue;
            .icon{
                opacity: 1;
            }
        }
        &:hover{
            .form__checkbox-retangle{
                border-color: $baseBlue;
            }
            .form__checkbox-text{
                color: $baseBlue;
            }
        }
    } 
    &__checkbox-retangle{
        min-width: px(20);
        min-height: px(20);
        max-width: px(20);
        max-height: px(20);
        margin-right: px(12);
        border: 1px solid #E0E0E0;
        border-radius: px(5);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
        @media(max-width:1024px){
            min-width: 20px;
            min-height: 20px;
            max-width: 20px;
            max-height: 20px;
            margin-right: 12px;
            border-radius: 5px;
        }
        .icon{
            width: px(11);
            height: px(9);
            opacity: 0;
            object-fit: contain;
            fill: $baseBlue;
            transition: .3s;
            @media(max-width:1024px){
                width: 11px;
                height: 9px;
            }
        }
    }
    &__checkbox-text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: px(12);
        transition: .3s;
        line-height: 100%;
        color: $secondaryBlack;
        @media(max-width:1024px){
            font-size: 12px;
        }
    }

    // radio

    // &__radio{
    //     width: auto;
    //     display: inline-flex;
    //     cursor: pointer;
    //     align-items: center;
    //     justify-content: flex-start;
    //     margin-bottom: 20px;
    //     input{
    //         display: none;
    //     }
    //     input:checked+ .form__radio-retangle{
    //         border-color: $basePurple;
    //         background-color: $basePurple;
    //         .icon{
    //             opacity: 1;
    //         }
    //     }
    //     &:hover{
    //         .form__radio-retangle{
    //             background-color: $basePurple;
    //         }
    //     }
    // }
    // &__radio-retangle{
    //     min-width: 20px;
    //     min-height: 20px;
    //     max-width: 20px;
    //     max-height: 20px;
    //     margin-right: 12px;
    //     border: 1px solid $baseWhite;
    //     border-radius: 5px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     transition: .3s;
    //     .icon{
    //         width: 11px;
    //         height: 9px;
    //         opacity: 0;
    //         object-fit: contain;
    //         fill: $baseWhite;
    //         transition: .3s;
    //     }
    // }
    // &__radio-text{
    //     font-family: 'Montserrat';
    //     font-style: normal;
    //     font-weight: 600;
    //     font-size: 16px;
    //     line-height: 20px;
    //     transition: .3s;
    //     color: $baseWhite;
    //     @media(max-width:1024px){
    //         font-size: 14px;
    //         line-height: 16px;
    //     }
    // }
}

.form-item{
    &.error, &.error__email{
        .form__input{
            border-color: $baseRed !important;
            color: $baseRed !important;
            &::placeholder{
                color: $baseRed !important;
            }
        }
        .form__field-require{
            opacity: 1;
            visibility: visible;
        }
        .form__checkbox-retangle{
            border-color: $baseRed !important;
        }
        .form__checkbox-text{
            color: $baseRed !important;
        }
        .form__field-input::after{
            background-color: $baseRed !important;
        }
        textarea{
            border-color: $baseRed !important;
        }
        .select__value{
            color: $baseRed !important;
        }
    }
}


// intl
.iti{
    width: 100% !important;
}
.iti__selected-flag{
    margin-bottom: px(10);
    height: calc(100% - px(10));
    @media(max-width:1024px){
        margin-bottom: 10px;
        height: calc(100% - 10px);
    }
}
.iti__country-list{
    padding: 0;
    max-width: px(450);
    @media(max-width:1024px){
        min-width: 260px;
        max-width: calc(100% - 27px);
        margin-left: -17px;
    }
}
.iti__divider{
    padding: 0;
    margin: 0;
}
// intl end