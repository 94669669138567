.footer {
  width: 100%;
  padding: px(20) px(114);
  background-color: $baseWhite;
  @media(max-width:1600px){
    padding: px(20) px(70);
  }
  @media(max-width:1300px){
    padding: px(20) px(50);
  }
  @media(max-width:1150px){
    padding: px(20) px(30);
  }
  @media(max-width:1024px){
    padding: 20px;
  }
  &__content{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media(max-width:1024px){
      flex-wrap: wrap;
      flex-direction: column;
    }
  }

  &__copyright{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: px(16);
    line-height: px(20);
    color: $secondaryGray;
    @media(max-width:1650px){
      font-size: px(14);
      line-height: px(18);
    }
    @media(max-width:1024px){
      font-size: 14px;
      line-height: 18px;
      width: 100%;
      margin: 0;
      text-align: center;
    }
  }

  &__content-column{
    width: 33.333333333333333333%;
    display: flex;
    flex-direction: column;
    padding: 0 px(10);
    align-items: center;
    &:nth-child(3n){
      padding-right: 0;
      align-items: flex-end;
    }
    &:nth-child(3n-2){
      padding-left: 0;
      align-items: flex-start;
    }
    @media(max-width:1024px){
      padding: 0 !important;
      align-items: center !important;
      margin-bottom: 20px;
      width: 100%;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  .footer__link {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: px(16);
    line-height: px(20);
    text-decoration-line: underline;
    color: $secondaryGray;
    transition: .3s;
    &.p-none{
      pointer-events: none !important;
    }
    &:hover{
      @media(min-width: 1025px){
        color: $basePurple;
      }
    }
    @media(max-width:1650px){
      font-size: px(14);
      line-height: px(18);
    }
    @media(max-width:1024px){
      font-size: 14px;
      line-height: 18px;
    }
  }
}
