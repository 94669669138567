.header {
  width: 100%;
  display: flex;
  justify-content:center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 12;
  left: 0;
  transition: .17s;
  background: transparent;
  padding: px(30) px(114);
  &.active{
    background: $baseWhite;
    padding: px(14) px(114);
    @media(max-width:1600px){
      padding: px(14) px(70);
    }
    @media(max-width:1300px){
      padding: px(14) px(50);
    }
    @media(max-width:1150px){
      padding: px(14) px(30);
    }
    @media(max-width:1024px){
      padding: 16px 15px;
    }
  }
  @media(max-width:1600px){
    padding: px(30) px(70);
  }
  @media(max-width:1300px){
    padding: px(30) px(50);
  }
  @media(max-width:1150px){
    padding: px(30);
  }
  @media(max-width:1024px){
    padding: 16px 15px;
  }
  &__container{
    width: 100%;
    max-width: px(1680);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media(max-width:1024px){
      max-width: 1680px;
    }
  }
  &__logo{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: px(20);
    min-width: px(73);
    @media(max-width:1024px){
      min-width: 50px;
      margin-right: 20px;
    }
    img{
      width: px(73);
      object-fit: contain;
      height: px(92);
      pointer-events: none;
      @media(max-width:1024px){
        width: 50px;
        height: 63px;
      }
    }
  }

  &__side{
    display: inline-flex;
    align-items: center;
    @media(max-width:1024px){
      width: 100%;
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  // header burger
  &__burger{
    display: none;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    margin-left: 20px;
    transition: .3s;
    background: transparent;
    border: none;
    outline: none;
    .icon{
      fill: $baseBlack;
      transition: .3s;
      max-width: 30px;
      max-height: 30px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
    .icon-close-burger{
      opacity: 0;
      visibility: hidden;
      // max-width: 20px;
      max-height: 20px;
    }
    &.active{
      .icon-close-burger{
        opacity: 1;
        visibility: visible;
      }
      .icon-burger{
        opacity: 0;
        visibility: hidden;
      }
    }
    @media(max-width:1024px){
      display: flex;
    }
  }
  // header menu list
  &__menu{
    display: inline-flex;
    align-items: center;
    @media(max-width:1024px){
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 20px;
      width: 100%;
    }
  }

  &-link__option{
    display: inline-flex;
    flex-direction: column;
    position: relative;
    margin: 0 px(25);
    @media(max-width:1900px){
      margin: 0 px(15);
    }
    @media(max-width:1450px){
      margin: 0 px(10);
    }
    @media(max-width:1200px){
      margin: 0 px(5);
    }
    @media(max-width:1024px){
      margin: 0;
      margin-bottom: 20px;
      width: 100%;
    }
    &:first-child{
      margin-left: 0;
    }
    &:last-child{
      margin-right: 0;
    }
  }

  &__link{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: px(16);
    line-height: px(20);
    color:rgba($baseBlack, 1);
    display: inline-flex;
    align-items: center;
    z-index: 1;
    position: relative;
    transition: .3s;
    background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
    background-clip: text;
    -webkit-background-clip:text;
    width: fit-content;
    @media(max-width:1200px){
      font-size: px(14);
      line-height: px(17);
    }
    @media(max-width:1024px){
      font-size: 18px;
      line-height: 22px;
    }
    .icon{
      width: px(8);
      height: px(5);
      fill: $baseBlack;
      transition: .3s;
      margin-left: px(5);
      min-width: px(6);
      object-fit: contain;
      @media(max-width:1024px){
        width: 7px;
        height: 4.5px;
        margin-left: 5px;
        min-width: 6px;
      }
    }

    &:hover{
      @media(min-width: 1025px){
        color:rgba($baseBlack, 0);
        .icon{
          fill: $basePurple;
        }
      }
    }
    &.active{
      color:rgba($baseBlack, 0) !important;
      .icon{
        fill: $basePurple !important;
        transform: rotate(180deg);
      }
    }
  }
  &-link__hidelist {
    display: none;
    position: absolute;
    bottom: 0;
    left: px(-30);
    transform: translateY(105%);
    z-index: 10;
    background-color: $baseWhite;
    box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.05);
    border-radius: px(5);
    padding: px(20) px(25);
    max-width: px(360);
    min-width: px(360);
    max-height: calc(var(--vh, 1vh) * 100 - px(210));
    overflow: hidden;
    overflow-y: auto;
    scrollbar-color: $baseBlack $baseGray;
    @media(max-width:1850px){
      max-width: px(300);
      min-width: px(300);
      padding: px(20);
      left: px(-20);
    }
    @media(max-width:1200px){
      padding: px(15);
      max-width: px(270);
      min-width: px(270);
    }
    @media(max-width:1024px){
      position: relative;
      transform: none;
      bottom: unset;
      left: unset;
      width: 100%;
      min-width: 100%;
      box-shadow: none;
      border-radius: 0;
      background: transparent;
      padding: 0;
      padding-top: 10px;
      max-height: fit-content;
    }
    &::-webkit-scrollbar{
      width: px(4);
      background-color: $baseGray;
    }
    &::-webkit-scrollbar-thumb{
      width: px(4);
      background-color: $baseBlack;
      border-radius: px(15);
    }
    li{
      padding-bottom: px(20);
      margin-bottom: px(20);
      border-bottom: 1px solid $baseGray;
      width: 100%;
      @media(max-width:1850px){
        padding-bottom: px(15);
        margin-bottom: px(15);
      }
      @media(max-width:1200px){
        padding-bottom: px(10);
        margin-bottom: px(10);
      }
      @media(max-width:1024px){
        padding: 0;
        margin-bottom: 10px;
        border: none;
      }
      &:last-child{
        border: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

  &__hidelink{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: px(15);
    line-height: px(17);
    transition: .3s;
    color:rgba($baseBlack, 1);
    background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
    background-clip: text;
    position: relative;
    -webkit-background-clip:text;
    &::after{
      width: 0%;
      background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
      height: 1px;
      content: '';
      position: absolute;
      left: 0;
      bottom: 1px;
      transition: .3s;
    }
    @media(max-width:1850px){
      font-size: px(14);
      line-height: px(16);
    }
    @media(max-width:1200px){
      font-size: px(13);
      line-height: px(14);
    }
    @media(max-width:1024px){
      font-size: 16px;
      line-height: 20px;
    }
    &:hover{
      @media(min-width: 1025px){
        color:rgba($baseBlack, 0);
        &::after{
          width: 100%;
        }
      }
    }
  }

  // header menu list end

  &__contacts{
    display: inline-flex;
    align-items: center;
    @media(max-width:1800px){
      flex-direction: column;
      align-items: flex-start;
      margin-right: px(30);
    }
    @media(max-width:1600px){
      margin-right: px(20);
    }
    @media(max-width:1100px){
      margin-right: px(15);
    }
    @media(max-width:1024px){
      margin-bottom: 40px;
      margin-right: 0;
    }
  }
  &-contacts__wrapp{
    display: inline-flex;
    align-items: center;
    margin-left: px(40);
    @media(max-width:1800px){
      margin-left: px(50);
    }
    @media(max-width:1400px){
      margin-left: px(20);
    }
    @media(max-width:1100px){
      margin-left: px(15);
    }
    @media(max-width:1024px){
      flex-direction: column;
      align-items: flex-start;
      margin: 0;
      width: 100%;
    }
  }

  &-contact__link{
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: px(16);
    line-height: px(20);
    color: $baseBlack;
    transition: .3s;
    margin-right: px(45);
    display: flex;
    @media(max-width:1900px){
      margin-right: px(30);
    }
    @media(max-width:1800px){
      margin-right: 0;
      margin-bottom: px(5);
      &:last-child{
        margin-bottom: 0;
      }
    }
    @media(max-width:1600px){
      font-size: px(14);
      line-height: px(18);
    }
    @media(max-width:1400px){
      font-size: px(13);
      line-height: px(17);
    }
    @media(max-width:1350px){
      margin-bottom: px(10);
      .link-value{
        display: none;
      }
    }
    @media(max-width:1024px){
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 18px;
      .link-value{
        display: inline-flex;
      }
    }
    &:last-child{
      margin-right:px(40);
      @media(max-width:1900px){
        margin-right: px(30);
      }
      @media(max-width:1800px){
        margin-right: 0;
      }
    }
    .link-icon {
      max-width: px(20);
      max-height: px(20);
      min-width: px(20);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: px(7);
      @media(max-width:1400px){
        max-width: px(17);
        max-height: px(17);
        min-width: px(17);
        margin-right: px(5);
      }
      @media(max-width:1200px){
        margin-right: 0;
      }
      @media(max-width:1024px){
        margin-right: 5px;
        max-width: 18px;
        max-height: 18px;
        min-width: 18px;
      }
    }
    .icon{
      max-width: px(20);
      max-height: px(20);
      object-fit: contain;
      transition: .3s;
      fill: $baseBlack;
      @media(max-width:1400px){
        max-width: px(17);
        max-height: px(17);
      }
      @media(max-width:1024px){
        max-width: 18px;
        max-height: 18px;
      }
    }
    &:hover{
      @media(min-width: 1025px){
        color: $baseBlue;
        .icon{
          fill: $baseBlue;
        }
      }
    }
  }

  &-hide__contact {
    display: none !important;
    @media(max-width:1024px){
      display: inline-flex !important;
      margin-bottom: 0;
      .icon{
        max-width: 16px;
        max-height: 16px;
      }
    }
  }

  &__btn{
    min-width: px(245);
    @media(max-width:1500px){
      min-width: px(220);
    }
    @media(max-width:1100px){
      min-width: px(200);
    }
    @media(max-width:1024px){
      min-width: 260px;
    }
    @media(max-width:450px){
      min-width: 100%;
    }
  }

  // header lang
  //select dropdown
  .select-info{
    margin-right: px(20);
    @media(max-width:1900px){
      margin-right: px(15);
    }
    @media(max-width:1400px){
      margin-right: px(10);
    }
    @media(max-width:1024px){
      margin-right: 0;
      margin-bottom: 40px;
    }
    &.active{
      .select-arrow .icon{
        transform: rotate(180deg);
      }
      .select__trigger{
        border-color: transparent;
        &::after{
          opacity: 1;
        }
        .select-arrow .icon{
          fill: $baseWhite;
        }
        .select__value{
          color: $baseWhite;
        }
      }
    }
    .select__dropdown {
      position: absolute;
      width: 100%;
      max-width: px(74);
      background: transparent;
      display: none;
      padding: px(10) px(5);
      z-index: 10;
      margin-top: px(10);
      background: transparent;
      border: 1px solid $baseBlack;
      border-radius: px(5);
      background-color: $baseWhite;
      @media(max-width:1600px){
        max-width: px(65);
        min-width: px(65);
      }
      @media(max-width:1200px){
        max-width: px(55);
        min-width: px(55);
      }
      @media(max-width:1024px){
        position: relative;
        margin-top: 5px;
        border-radius: 5px;
        max-width: 65px;
        min-width: 65px;
      }
    }

    .select__trigger {
      width: 100%;
      max-width: px(74);
      min-width: px(74);
      min-height: px(60);
      border: 1px solid $baseBlack;
      border-radius: px(5);
      box-sizing: border-box;
      display: flex;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;
      z-index: 1;
      transition: .3s;
      padding: px(10) px(5);
      @media(max-width:1600px){
        max-width: px(65);
        min-width: px(65);
      }
      @media(max-width:1200px){
        max-width: px(55);
        min-width: px(55);
      }
      @media(max-width:1024px){
        max-width: 65px;
        border-radius: 5px;
        min-width: 65px;
      }
      &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: .3s;
        background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
        opacity: 0;
      }
      
      &:hover{
        @media(min-width:1025px){
          &::after{
            opacity: 1;
          }
          border-color: transparent;
          .select-arrow .icon{
            fill: $baseWhite;
          }
          .select__value{
            color: $baseWhite;
          }
        }
      }
      @media(max-width:1024px){
        min-height: 50px;
      }
    }
    .select-arrow {
      width: px(8);
      height: px(5);
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: px(5);
      @media(max-width:1024px){
        margin-left: 5px;
        width: 7px;
        height: 4.5px;
      }
      .icon{
        width: px(8);
        height: px(5);
        fill: $baseBlack;
        transition: .3s;
        @media(max-width:1024px){
          width: 7px;
          height: 4.5px;
        }
      }
    }
    
    .select__value{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: px(16);
      z-index: 1;
      line-height: 150%;
      transition: .3s;
      color: $baseBlack;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      @media(max-width:1600px){
        font-size: px(14);
        line-height: px(18);
      }
      @media(max-width:1200px){
        font-size: px(13);
        line-height: px(16);
      }
      @media(max-width:1024px){
        font-size: 14px;
        line-height: 18px;
      }
    }

    .select__option{
      width: 100%;
      padding: 0 ;
      margin-bottom: px(10);
      @media(max-width:1024px){
        margin-bottom: 10px;
      }
      &:last-child{
        margin-bottom: 0;
      }
      cursor: pointer;
      &:last-child{
        border: none;
      }
      &.active{
        .header-lang__link{
          background: linear-gradient(89.91deg, #11BBF0 -7.12%, #D83EFF 112.36%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
      }
      &:hover{
        .select__option-info{
          color: $basePurple;
        }
      }
    }

    .header-lang__link{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: px(16);
      line-height: 150%;
      color: $baseBlack;
      text-align: center;
      width: 100%;
      display: block;
      transition: .3s;
      @media(max-width:1600px){
        font-size: px(14);
        line-height: px(18); 
      }
      @media(max-width:1200px){
        font-size: px(13);
        line-height: px(17);
      }
      @media(max-width:1024px){
        font-size: 14px;
        line-height: 18px;
      }
      &:hover{
        @media(min-width:1025px){
          color: $baseBlue;
        }
      }
    }
  }
  // header lang end

  // mobile menu

  &-mobile__menu{
    @media(max-width:1024px){
      position: fixed;
      top: 95px;
      z-index: 12;
      left: 0;
      background-color: $baseWhite;
      width: 100%;
      height: calc(var(--vh, 1vh) * 100 - 95px);
      padding: 25px 15px;
      border-top: 1px solid $baseGray;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      overflow: hidden;
      transform: translateX(110%);
      transition: .4s;
      transition-delay: .15s;
      &.active{
        transform: translateX(0);
        transition-delay: 0;
      }
    }
  } 
}
.overlay{
  position: fixed;
  z-index: 2;
  background-color: rgba($baseWhite , 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  filter: blur(2px);
  transition: .3s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  backdrop-filter: blur(2px);
  &.active{
    opacity: 1;
    visibility: visible;
  }
}

.index-english-page{
  .header__lang{
    display: none !important;
  }
}