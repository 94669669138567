.global-fixed-socials {
    position: fixed;
    height: 100vh;
    top: 0;
    right: px(40);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 11;
    // padding: px(30) 0;
    padding: px(100) 0;
    @media(max-width:1600px){
        right: px(20);
    }
    @media(max-width:1300px){
        right: px(10);
    }    
}
.fixed-socials__list {
    display: flex;
    flex-direction: column;
    // margin-bottom: px(100);
    @media(min-width: 1025px) and (max-height:750px){
        // margin-bottom: px(60);
    }
    @media(min-width: 1025px) and (max-height:700px){
        // margin-bottom: px(40);
    }
    @media(max-width:1150px){
        display: none;
    }
    li{
        margin-bottom: px(35);
        display: flex;
        align-items: center;
        justify-content: center;
        &:last-child{
            margin-bottom: 0;
        }
        @media(min-width: 1025px) and (max-height:750px){
            margin-bottom: px(30);
        }
        @media(min-width: 1025px) and (max-height:700px){
            margin-bottom: px(25);
        }
    }
}

.fixed-social__link{
    display: flex;
    align-items: center;
    justify-content: center;
    .icon{
        max-width: px(22);
        max-height: px(20);
        object-fit: contain;
        transition: .3s;
        fill: #383838;
    }
    &:hover{
        @media(min-width: 1025px){
            .icon{
                fill: $basePurple;
            }
            
        }
    }
}

.back-to-top{
    width: px(45);
    height: px(45);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;
    position: fixed;
    bottom: 30px;
    right: px(40);
    transform: translateX(25%);
    @media(max-width:1600px){
        width: px(40);
        height: px(40);
        right: px(20);
    }
    @media(max-width:1300px){
        right: px(10);
        transform: translateX(4px);
    }
    @media(max-width:1150px){
        transform: translateX(0);
        right: 25px;
        width: px(45);
        height: px(45);
        background-color: $baseWhite;
        border-radius: 50%;
    }
    @media(max-width:1024px){
        right: 15px;
        bottom: 15px;
    }
    &:hover{
        @media(min-width: 1025px){
            transform: translateY(px(-3)) translateX(0);
        }
        @media(min-width: 1151px){
            transform: translateY(px(-3)) translateX(4px);
        }
        @media(min-width: 1301px){
            transform: translateY(px(-3)) translateX(25%);
        }
    }
    img{
        width: 100%;
        object-fit: contain;
        pointer-events: none;
    }
}