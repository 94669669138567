$baseWhite: #fff;
$baseBlack: #000;
$baseBlue: #75AFF9;
$baseDarkBlue: #0E8EE6;
$secondaryBlue: #61C5FA;
$basePurple: #C187E9; 
$baseGray: #EBEBEB;
$secondaryGray: #B7B7B7;
$secondaryBlue: #98C5FF;
$baseBack: #FBFBFB;
$thirdGray: #A4A4A4;
$baseRed: #FC6E6E;
$secondaryBlack: #656565;
$baseSilver: #DDE7EF;
$secondarySilver: #FAFAFA;