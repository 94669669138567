.hero{
    &-section {
        background: linear-gradient(74.64deg, #C8D5E2 4.11%, #F0F7F9 98.07%);
        padding-top: 0;
        padding-bottom: 0;
        overflow: hidden;
        z-index: 1;
        position: relative;
        @media(max-width:1024px){
            padding-top: 10px;
        }
        @media(max-width:700px){
            padding-top: 20px;
        }
        @media(max-width:576px){
            padding-top: 120px;
            padding-bottom: 50px;
        }
        &::after{
            width: 100%;
            height: px(245);
            background-color: $baseWhite;
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: -1;
            pointer-events: none;
            @media(max-width:1650px){
                height: px(225);
            }
            @media(max-width:1024px){
                height: 215px;
            }
            @media(max-width:700px){
                height: 170px;
            }
            @media(max-width:576px){
                display: none;
            }
        }
    }
    &-box {
        width: 100%;
        max-width: px(940);
        margin: 0 auto;
        position: relative;
        @media(max-width:1650px){
            max-width: px(800);
        }
        @media(max-width:1024px){
            max-width: 660px;
        }
        @media(max-width:700px){
            max-width: 500px;
        }
        @media(max-width:576px){
            max-width: 100%;
        }
    }
    &-desctop__img{
        width: 100%;
        object-fit: contain;
        pointer-events: none;
        transform: translateY(5.7%);
        @media(max-width:1650px){
            transform: translateY(4%);
        }
        @media(max-width:1024px){
            transform: translate(0);
        }
        @media(max-width:576px){
           display: none;
        }
    }
    &-info {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        max-height: 55%;
        top: 20%;
        max-width: 79%;
        padding-top: 3.17%;
        padding-bottom: 2%;
        padding-left: 2.65%;
        padding-right: 2.65%;
        // background-color: red;
        left: 50%;
        transform: translateX(-50%);
        // opacity: 0.4;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        @media(max-width:1650px){
            top: 18.2%;
        }
        @media(max-width:1024px){
            top: 14.4%;
            padding-bottom: 1.6%;
        }
        @media(max-width:700px){
            justify-content: center;
            padding-bottom: 10.7%;
        }
        @media(max-width:576px){
            position: relative;
            top: unset;
            max-width: 100%;
            max-height: none;
            padding: 0;
        }
        &::after{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -60%);
            content: '';
            width: px(250);
            height: px(250);
            border: 1px solid #B5B5B5;
            z-index: -1;
            border-radius: 50%;
            opacity: 0.5;
            @media(max-width:1650px){
                transform: translate(-50%, -62%);
            }
            @media(max-width:1024px){
                width: 170px;
                height: 170px;
                transform: translate(-50%, -65%);
            }
            @media(max-width:700px){
                transform: translate(-50%, -107%);
                width: 100px;
                height: 100px;
            }
            @media(max-width:576px){
                display: none;
            }
        }
        .blur-circle__one{
            width: px(65);
            height: px(65);
            left: unset;
            right: 19%;
            top: 33.5%;
            animation-delay: 1s;
            @media(max-width:1650px){
                top:30%;
                right: 17%;
            }
            @media(max-width:1024px){
                width: 65px;
                height: 65px;
            }
        }
        .blur-circle__two{
            width: px(80);
            height: px(80);
            left: unset;
            right: -7%;
            top: unset;
            bottom: 7%;
            animation-delay: 1.5s;
            @media(max-width:1650px){
                width: px(70);
                height: px(70);
            }
            @media(max-width:1024px){
                width: 70px;
                height: 70px;
            }
        }
        .blur-circle__three{
            width: px(42);
            height: px(42);
            left: 19%;
            top: 32%;
            opacity: 0.3;
            animation-delay: -1.5s;
            @media(max-width:1024px){
                width: 42px;
                height: 42px;
            }
        }
        .blur-circle__four{
            width: px(130);
            height: px(130);
            top: 8%;
            left: -6%;
            @media(max-width:1650px){
                width: px(100);
                height: px(100);
            }
            @media(max-width:1024px){
                width: 100px;
                height: 100px;
            }
        }
    }
    &__btn {
        max-width: px(345);
        min-width: px(345);
        text-transform: uppercase;
        margin-top: 12.5%;
        z-index: 1;
        @media(max-width:1024px){
            margin-top: 15.5%;
            max-width: 345px;
            min-width: 345px;
        }
        @media(max-width:700px){
            margin-top: 5%;
            max-width: 280px;
            min-width: 280px;
        }
        @media(max-width:576px){
            margin-top: 35px;
        }
        @media(max-width:450px){
            min-width: 100%;
            max-width: 100%;
        }
    }
    &-pricelist__title{
        z-index: 1;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: px(54);
        line-height: px(60);
        text-align: center;
        text-transform: uppercase;
        color: $baseBlack;
        @media(max-width:1650px){
            font-size: px(44);
            line-height: px(52);
        }
        @media(max-width:1024px){
            font-size: 28px;
            line-height: 36px;
        }
        @media(max-width:700px){
            font-size: 24px;
            line-height: 30px;
        }
        @media(max-width:576px){
            font-size: 28px;
            line-height: 36px;
            margin-bottom: 17px;
        }
    }

    &-pricelist__year{
        z-index: 1;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: px(160);
        line-height: px(160);
        text-align: center;
        text-transform: uppercase;
        background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        @media(max-width:1650px){
            font-size: px(140);
            line-height: px(140);
        }
        @media(max-width:1024px){
            font-size: 100px;
            line-height: 100px;
        }
        @media(max-width:700px){
            font-size: 52px;
            line-height: 52px;
        }
        @media(max-width:576px){
            font-size: 92px;
            line-height: 92px;
        }
    }

    // circles
    &-one-circle{
        bottom: unset;
        left: unset;
        right: -10%;
        top: 15%;
        animation-duration: 2.5s;
        animation-delay: 1s;
        @media(max-width:1024px){
            top: 10%;
            right: -5%;
        }
        @media(max-width:576px){
            top: 0;
            right: -3%;
        }
    }
    &-two-circle{
        bottom: 29%;
        left: -3%;
        @media(max-width:1024px){
            left: 0;
        }
        @media(max-width:576px){
            bottom: 10%;
        }
    }
    &-three-circle{
        bottom: 24%;
        left: unset;
        right: 10%;
        width: px(145);
        height: px(145);
        z-index: 0;
        @media(max-width:1650px){
            width: px(110);
            height: px(110);
            right: 8%;
        }
        @media(max-width:1024px){
            width: 90px;
            height: 90px;
            bottom: 33%;
            right: 15%;
        }
        @media(max-width:576px){
            display: none;
        }
    }
    &-four-circle{
        width: px(95);
        height: px(95);
        bottom: unset;
        top: 17%;
        left: 29%;
        @media(max-width:1650px){
            width: px(82);
            height: px(82);
            left:30%;
        }
        @media(max-width:1024px){
            left: 34%;
            width: 60px;
            height: 60px;
            top: 20%;
        }
        @media(max-width:576px){
            display: none;
        }
    }
}

