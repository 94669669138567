.product-package{
    &__list{
        width: calc(100% + px(80));
        margin: 0 auto;
        margin-left: px(-40);
        margin-right: px(-40);
        display: flex;
        flex-wrap: wrap;
        position: relative;
        @media(max-width:1800px){
            width: calc(100% + px(60));
            margin-left: px(-30);
            margin-right: px(-30);
        }
        @media(max-width:1650px){
            width: calc(100% + px(40));
            margin-left: px(-20);
            margin-right: px(-20);
        }
        @media(max-width:1024px){
            width: calc(100% + 20px);
            margin-left: -10px;
            margin-right: -10px;
        }
        @media(max-width:800px){
            width: 100%;
           margin: 0;
        }
    }

    &__item{
        width: 50%;
        position: relative;
        padding: 0 px(40);
        margin-bottom: px(40);
        display: flex;
        flex-direction: column;
        z-index: 1;
        @media(max-width:1800px){
            padding: 0 px(30);
        }
        @media(max-width:1650px){
            padding: 0 px(20);
        }
        @media(max-width:1024px){
            padding: 0 10px;
            margin-bottom: 40px;
        }
        @media(max-width:800px){
            padding: 0;
            width: 100%;
        }
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: calc(100% - px(82));
            transform: translateX(-50%);
            height: calc(100% - px(45));
            border: 1px solid #D7D7D7;
            pointer-events: none;
            z-index: -1;
            border-radius: px(10);
            @media(max-width:1800px){
                width: calc(100% - px(62));
            }
            @media(max-width:1650px){
                width: calc(100% - px(42));
            }
            @media(max-width:1024px){
                width: calc(100% - 22px);
                border-radius: 10px;
                height: calc(100% - 45px);
            }
            @media(max-width:800px){
                width: calc(100% - 2px);
            }
        }
        &.special-package{
            .product-package__name{
                font-weight: 700;
            }
            &::after{
                box-shadow: 0px 9.52789px 47.6394px rgba(129, 116, 248, 0.4);
                border-color: transparent;
            }
            .product-package__special-flag{
                display: flex;
            }
        }
    }
    &__card {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        z-index: 1;
        overflow: hidden;
        border-radius: px(10);
        padding: px(35) px(30);
        padding-bottom: 0;
        @media(max-width:1300px){
            padding: px(25) px(20);
            padding-bottom: 0;
        }
        @media(max-width:1024px){
            padding: 20px 15px;
            padding-bottom: 0;
            border-radius: 10px;
        }
    }
    &__info{
        width: 100%;
        position: relative;
    }
    &__name{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: px(34);
        line-height: px(41);
        text-align: center;
        background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        @media(max-width:1800px){
            font-size: px(28);
            line-height: px(34);
        }
        @media(max-width:1650px){
            font-size: px(24);
            line-height: px(30);
        }
        @media(max-width:1024px){
            font-size: 20px;
            line-height: 24px;
        }
    }
    &__stats{
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    &__stat{
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: px(13);
        width: calc(100% / 2 - px(15));
        &:nth-child(2n){
            align-items: flex-start;
            @media(max-width:576px){
                .product-package__stat-text{
                    text-align: left;
                }
                
            }
        }
        @media(max-width:1650px){
            width: calc(100% / 2 - px(12));
        }
        @media(max-width:1300px){
            width: calc(100% / 2 - px(10));
        }
        @media(max-width:1024px){
            width: calc(100% / 2 - 7px); 
            margin-bottom: 8px;
        }
        &:last-child{
            margin-right: 0;
        }
        &.color-stat{
            .product-package__stat-number{
                background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }  
             
            
        }
    }
    &__stat-number{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: px(57);
        line-height: px(70);
        color: $baseBlack;
        @media(max-width:1800px){
            font-size: px(50);
            line-height: px(62);
        }
        @media(max-width:1650px){
            font-size: px(44);
            line-height: px(54);
        }
        @media(max-width:1300px){
            font-size: px(40);
            line-height: px(50);
        }
        @media(max-width:1024px){
            font-size: 30px;
            line-height: 40px;
        }
        @media(max-width:576px){
            font-size: 24px;
            line-height: 32px;
        }
        b{
            font-size: px(155);
            line-height: px(180);
            font-weight: 900;
            @media(max-width:1800px){
                font-size: px(140);
                line-height: px(162);
            }
            @media(max-width:1650px){
                font-size: px(120);
                line-height: px(140);
            }
            @media(max-width:1300px){
                font-size: px(100);
                line-height: px(120);
            }
            @media(max-width:1024px){
                font-size: 70px;
                line-height: 85px;
            }
            @media(max-width:576px){
                font-size: 50px;
                line-height: 65px;
            }
        }
    }
    &__stat-text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: px(16);
        line-height: px(20);
        text-align: center;
        color: $baseBlack;
        width: 100%;
        max-width: px(275);
        margin-top: px(-10);
        @media(max-width:1800px){
            max-width: px(250);
        }
        @media(max-width:1650px){
            font-size: px(14);
            line-height: px(18);
            max-width: px(215);
        }
        @media(max-width:1300px){
            font-size: px(12.5);
            line-height: px(16);
            max-width: px(195);
            margin-top: -10px;
        }
        @media(max-width:1024px){
            font-size: 10px;
            line-height: 13px;
            max-width: 140px;
        }
        @media(max-width:576px){
            text-align: right;
            max-width: 100%;
        }
    }
    &__priceinfo {
        font-family: 'Montserrat';
        font-style: italic;
        font-weight: 400;
        font-size: px(28);
        line-height: px(34);
        text-align: center;
        background: linear-gradient(89.91deg, #11BBF0 -7.12%, #D83EFF 112.36%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        margin-bottom: px(20);
        @media(max-width:1650px){
            font-size: px(24);
            line-height: px(30);
        }
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 12px;
        }
    }
    &__bottom {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: auto;
        align-items: center;
    }

    &__btn{
        min-width: auto;
        width: 100%;
        max-width: px(515);
        margin-bottom: px(30);
        @media(max-width:1024px){
            margin-bottom: 15px;
            max-width: 515px;
        }
    }
    &__description {
        width: 100%;
        max-width: px(605);
        padding: px(10) px(20);
        border: 0.952669px solid #9C9C9C;
        border-radius: px(10);
        position: relative;
        min-height: px(90);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: px(16);
        line-height: px(20);
        text-align: center;
        color: $baseBlack;
        background-color: $baseWhite;
        @media(max-width:1650px){
            font-size: px(15);
            line-height: px(19);
        }
        @media(max-width:1024px){
            font-size: 14px;
            line-height: 18px;
            padding: 10px;
            max-width: 100%;
            border-radius: 10px;
            min-height: 90px;
        }
        b{
            background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            font-weight: 700;
        }
    }

    &__special-flag{
        width: px(220);
        max-width: px(220);
        position: absolute;
        top: px(-20);
        right: px(-79);
        z-index: 2;
        background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
        transform: rotate(45deg);
        display: none;
        flex-direction: column;
        align-items: center;
        padding: px(30);
        padding-bottom: px(10);
        .icon{
            max-width: px(20);
            max-height: px(20);
            object-fit: contain;
            fill: $baseWhite;
            margin-bottom: px(6);
            @media(max-width:1300px){
                max-width: px(17);
                max-height: px(17);
                margin-bottom: px(5);
            }
            @media(max-width:1024px){
                max-width: 15px;
                max-height: 15px;
                margin-bottom: 3px;
            }
        }
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: px(16);
        line-height: px(20);
        text-align: center;
        color: #FFFFFF;
        @media(max-width:1300px){
            font-size: px(14);
            line-height: px(18);
            padding: px(20);
            padding-bottom: px(5);
            top: px(-17);
            right: px(-85);
        }
        @media(max-width:1024px){
            font-size: 10px;
            line-height: 12px;
            padding: 10px;
            padding-bottom: 3px;
            top: -9px;
            right: -91px;
            width: 220px;
            max-width: 220px;
        }
        @media(max-width:576px){
            p{
                display: none;
            }
            transform: none;
            top: 0;
            right: 0;
            background: transparent;
            width: auto;
            .icon{
                fill: #B35BED;
            }
            padding: 10px;
        }
    }
}