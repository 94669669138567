.global-title__wrapp{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    margin-bottom: px(55);
    .background-circle{
        left: 50%;
        width: px(135);
        transform: translateX(-50%) translateY(50%);
        animation: none;
        bottom: 50%;
        @media(max-width:1024px){
            width: 90px;
        }
    }
    &::after{
        content: '';
        width: px(62);
        height: px(62);
        position: absolute;
        z-index: -1;
        border-radius: 50%;
        opacity: 0.5;
        background-color: #F8F8F8;
        transition: .3s;
        top: px(-10);
        left: px(-40);
        backdrop-filter: blur(170px);
        filter: blur(5px);
        border: 1px solid rgba(206, 206, 206, 1);
        animation: blik 2s ease infinite;
        animation-delay: 1.5s;
        pointer-events: none;
        @media(max-width:1024px){
            display: none;
        }
    }

    &::before{
        content: '';
        width: px(62);
        height: px(62);
        position: absolute;
        z-index: -1;
        border-radius: 50%;
        opacity: 0.5;
        background-color: #F8F8F8;
        transition: .3s;
        top: px(42);
        right: px(-45);
        backdrop-filter: blur(170px);
        filter: blur(5px);
        border: 1px solid rgba(206, 206, 206, 1);
        animation: blik 2s ease infinite;
        animation-delay: 2.5s;
        pointer-events: none;
        @media(max-width:1024px){
            display: none;
        }
    }
    @media(max-width:1650px){
        margin-bottom: px(45);
    }
    @media(max-width:1024px){
        margin-bottom: 37px;
    }
}
  
.global-title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    width: 100%;
    font-size: px(96);
    line-height: px(117);
    text-align: center;
    background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    position: relative;
    z-index: 1;
    width: fit-content;
    margin: 0 auto;
    @media(max-width:1650px){
        font-size: px(72);
        line-height: px(90);
    }
    @media(max-width:1024px){
        font-size: 48px;
        line-height: 58px;
    }
    @media(max-width:576px){
        font-size: 38px;
        line-height: 46px;
    }
}

.global-subtitle{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: px(48);
    width: 100%;
    line-height: px(59);
    text-align: center;
    background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    position: relative;
    z-index: 1;
    width: fit-content;
    margin: 0 auto;
    @media(max-width:1650px){
        font-size: px(40);
        line-height: px(50);
    }
    @media(max-width:1024px){
        font-size: 30px;
        line-height: 40px;
    }
    @media(max-width:576px){
        font-size: 26px;
        line-height: 34px;
    }
}

.global-category_title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: px(36);
    line-height: px(44);
    text-align: center;
    color: $baseDarkBlue;
    @media(max-width:1650px){
        font-size: px(30);
        line-height: px(38);
    }
    @media(max-width:1024px){
        font-size: 24px;
        line-height: 30px;
    }
    @media(max-width:576px){
        font-size: 22px;
        line-height: 28px;
    }
}

.global-title + .global-subtitle{
    margin-top: px(28);
    @media(max-width:1650px){
        margin-top: px(17);
    }
    @media(max-width:1024px){
        margin-top: 10px;
    }
}

.global-text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: px(16);
    line-height: px(20);
    color: $baseBlack;
    @media(max-width:1024px){
        font-size: 16px;
        line-height: 20px;
    }
}


.global-category__subtext {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: px(18);
    line-height: px(23);
    text-align: center;
    color: $baseBlack;
    @media(max-width:1024px){
        font-size: 16px;
        line-height: 20px;
    }
    b{
        background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }
}


.global-category_title+ .global-category__subtext{
    margin-top: px(37);
    @media(max-width:1650px){
        margin-top: px(30);
    }
    @media(max-width:1024px){
        margin-top: 20px;
    }
}

.global-category__notification{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: px(20);
    line-height: px(25);
    text-align: center;
    color: $baseBlack;
    @media(max-width:1650px){
        font-size: px(18);
        line-height: px(22);
    }
    @media(max-width:1024px){
        font-size: 16px;
        line-height: 20px;
    }
}

.global-category__subtext+ .global-category__notification{
    margin-top: px(40);
    @media(max-width:1650px){
        margin-top: px(30);
    }
    @media(max-width:1024px){
        margin-top: 20px;
    }
}