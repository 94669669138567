.contact{
    &-section{
        padding-top: px(50);
        padding-bottom: px(30);
        @media(max-width:1024px){
            padding-top: 35px;
            padding-bottom: 30px;
        }
        .global-container{
            max-width: px(1400);
            @media(max-width:1024px){
                max-width: 1400px;
            }
        }
        a.contact-form__detail{
            &:hover{
                @media(min-width: 1025px){
                    &::after{
                        width: 100%;
                    }
                }
            }
        }
    }
    &-form {
        width: 100%;
        position: relative;
        display: flex;
        @media(max-width:800px){
            flex-direction: column-reverse;
        }
    }

    // info
    &-form__info {
        width: 100%;
        max-width: calc(100% - px(830));
        min-width: px(420);
        background: linear-gradient(224.78deg, #538CFB 3.56%, #EE9DFB 96.77%);
        border-top-left-radius: px(5);
        border-bottom-left-radius: px(5);
        padding: px(50);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        @media(max-width:1650px){
            padding: px(40);
        }
        @media(max-width:1024px){
            padding: 20px;
            min-width: 320px;
            max-width: calc(100% - 830px);
        }
        @media(max-width:800px){
            min-width: 100%;
            max-width: none;
            border-radius: 5px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            padding: 20px 15px;
        }
    }
    &-form__infobox{
        margin-bottom: px(30);
        &:last-child{
            margin-bottom: 0;
        }
        @media(max-width:1650px){
            margin-bottom: px(25);
        }
        @media(max-width:1024px){
            margin-bottom: 20px;
        }
    }
    &-form-infobox__title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: px(18);
        line-height: px(22);
        color: $baseWhite;
        margin-bottom: px(10);
        @media(max-width:1650px){
            margin-bottom: px(8);
        }
        @media(max-width:1024px){
            margin-bottom: 7px;
            font-size: 16px;
            line-height: 20px;
        }
    }

    &-form-infobox__content {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    &-form__detail{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: px(24);
        line-height: px(29);
        color: $baseWhite;
        transition: .3s;
        margin-bottom: px(10);
        position: relative;
        width: fit-content;
        @media(max-width:1650px){
            font-size: px(20);
            line-height: px(24);
        }
        @media(max-width:1024px){
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 10px;
        }
        &::after{
            width: 0;
            height: 1px;
            position: absolute;
            bottom: 1px;
            transition: .3s;
            left: 0;
            content: '';
            background-color: $baseWhite;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }

    // form
    &-form__box{
        width: 100%;
        max-width: 100%;
        background-color: $baseWhite;
        border-top-right-radius: px(5);
        border-bottom-right-radius: px(5);
        border: 1px solid #D7D7D7;
        border-left: none;
        padding: px(55);
        @media(max-width:1650px){
            padding: px(40);
        }
        @media(max-width:1024px){
            padding: 20px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
        @media(max-width:800px){
            padding: 20px 15px;
            border: 1px solid #D7D7D7;
            border-bottom: 0;
            border-radius: 5px;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    &-form__btn{
        min-width:px(360);
        @media(max-width:1024px){
            min-width: 100%;
        }
    }
    
}