// animation
@keyframes blik-scale {
  0%{
    opacity: 1;
    transform: scale(1);
  }
  50%{
    opacity: 0;
    transform: scale(0.92);
  }
  100%{
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes blik {
  0%{
    opacity: 0.5;
  }
  50%{
    opacity: 0;
  }
  100%{
    opacity: 0.5;
  }
}

@keyframes blik-white {
  0%{
    opacity: 1;
    transform:translateX(-50%) scale(1);
  }
  50%{
    opacity: 0;
    transform: translateX(-50%) scale(0.92);
  }
  100%{
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
}

.background-circle{
  width: px(180);
  height: auto;
  position: absolute;
  z-index: -1;
  pointer-events: none;
  object-fit: contain;
  bottom: 0;
  left: 0;
  transition: .3s;
  animation: blik-scale 2s ease infinite;
  @media(max-width:1650px){
    width: px(150);
  }
  @media(max-width:1024px){
    width: 120px;
  }
  @media(max-width:576px){
    width: 65px;
  }
  &.big-circle{
    width: px(335);
    @media(max-width:1650px){
      width: px(270);
    }
    @media(max-width:1024px){
      width: 200px;
    }
    @media(max-width:576px){
      width: 150px;
    }
  }
}

.blur-circle{
  width: px(62);
  height: px(62);
  position: absolute;
  // z-index: -1;
  border-radius: 50%;
  opacity: 0.5;
  background-color: #F8F8F8;
  transition: .3s;
  top: 0;
  left: 0;
  backdrop-filter: blur(170px);
  filter: blur(5px);
  border: 1px solid rgba(206, 206, 206, 1);
  animation: blik 4s ease infinite;
  pointer-events: none;
  @media(max-width:1024px){
    display: none;
  }
}