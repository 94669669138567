.ppc-planning{
  &-section{
    padding-top: px(60);
    padding-bottom: px(5);
    @media(max-width:1024px){
      padding-top: 25px;
      padding-bottom: 10px;
    }
    .product-infocard__list{
      position: relative;
      &::after{
          position: absolute;
          top: 0;
          left: px(40);
          content: "PPC";
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 900;
          font-size: px(200);
          line-height: px(200);
          text-align: center;
          color: #F9F9F9;
          transform: translateY(-77%);
          @media(max-width:1650px){
              font-size: px(150);
              line-height: px(150);
          }
          @media(max-width:1024px){
              display: none;
          }
      }
    }
  }
}

.ppc-packet{
  &-section{
    padding-top: px(45);
    padding-bottom: px(45);
    @media(max-width:1300px){
      padding-bottom: px(70);
    }
    @media(max-width:1024px){
      padding-top: 25px;
      padding-bottom: 40px;
    }
  }

  &__list {
    width: calc(100% + px(40));
    margin-right: px(-20);
    margin-left: px(-20);
    display: flex;
    flex-wrap: wrap;
    @media(max-width:1300px){
      width: 100%;
      margin: 0;
    }
  }

  &__item {
    width: 33.333333333333%;
    display: flex;
    flex-direction: column;
    height: fit-content;
    align-items: center;
    padding: 0 px(20);
    margin-bottom: px(40);
    &:nth-child(3n-2){
      @media(max-width:1301px){
        align-items: flex-start;
      }
    }
    &:nth-child(3n){
      @media(min-width: 1301px){
        align-items: flex-end;
      }
    }
    @media(max-width:1300px){
      width: 100%;
      padding: 0;
    }
    @media(max-width:1300px){
      &:last-child{
        margin-bottom: 0;
      }
    }
    @media(max-width:1024px){
      margin-bottom: 40px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  &__card{
    width: 100%;
    max-width: px(425);
    position: relative;
    overflow: hidden;
    @media(max-width:1300px){
      max-width: 100%;
    }
  }

  &__name{
    width: 100%;
    position: relative;
    min-height: px(80);
    padding: px(15) px(30);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: px(24);
    line-height: px(29);
    text-align: center;
    color: $baseWhite;
    background-color: $baseBlue;
    overflow: hidden;
    border-top-left-radius: px(10);
    border-top-right-radius: px(10);
    @media(max-width:1650px){
      padding: px(15) px(25);
    }
    @media(max-width:1300px){
      font-size: px(20);
      line-height: px(24);
      min-height: px(70);
    }
    @media(max-width:1024px){
      min-height: 60px;
      padding: 10px 15px;
      font-size: 18px;
      line-height: 22px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
  }

  &__content {
    width: 100%;
    position: relative;
    background-color: $baseWhite;
    padding: px(25) px(30);
    padding-bottom: px(40);
    border-bottom-left-radius: px(10);
    border-bottom-right-radius: px(10);
    border: 1px solid #D7D7D7;
    border-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    overflow: hidden;
    @media(max-width:1650px){
      padding: px(25);
      padding-bottom: px(30);
    }
    @media(max-width:1024px){
      padding: 20px 15px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  &__text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: px(16);
    line-height: 150%;
    text-align: center;
    color: $baseBlack;
    margin-bottom: px(25);
    @media(max-width:1024px){
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 15px;
    }
  }

  &__options {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    li{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: px(16);
      line-height: 150%;
      color: $baseBlack;
      margin-bottom: px(25);
      width: fit-content;
      text-align: center;
      position: relative;
      padding-left: px(18);
      text-decoration-line: underline;
      text-decoration-style: dashed;
      text-decoration-thickness: from-font;
      &::before{
        background: url(../svg/done-purple.svg) no-repeat;
        background-size: px(13) px(11);
        width: px(13);
        height: px(11);
        display: flex;
        content: "";
        position: absolute;
        left: 0;
        top: px(6);
        @media(max-width:1024px){
          background-size: 13px 11px;
          width: 13px;
          height: 11px;
          top: 6px;
        }
      }
      @media(max-width:1024px){
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 15px;
        padding-left: 18px;
      }
      @media(max-width:576px){
        text-align: left;
        width: 100%;
      }
    }
  }

  &__pricebox{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__price{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: px(20);
    line-height: 150%;
    background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: px(10);
    @media(max-width:1024px){
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }

  &__discountprice{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: px(16);
    line-height: 150%;
    text-align: center;
    text-decoration-line: line-through;
    color: #ADADAD;
    margin-bottom: px(25);
    @media(max-width:1024px){
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 15px;
    }
  }
  &__btn{
    min-width: auto;
    width: 100%;
    max-width: px(325);
    @media(max-width:1024px){
      max-width: 325px;
    }
    @media(max-width:450px){
      max-width: 100%;
    }
  }

  &__discount-symbol{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: px(160);
    line-height: 150%;
    text-align: center;
    color: $secondarySilver;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media(max-width:1650px){
      font-size: px(120);
    }
    @media(max-width:1024px){
      font-size: 90px;
    }
  }
}