.product-option{
    &__container{
        width: 100%;
        position: relative;
    }
    &__box{
        width: 100%;
        position: relative;
        margin-bottom: px(35);
        @media(max-width:1650px){
            margin-bottom: px(25);
        }
        @media(max-width:1024px){
            margin-bottom: 10px;
        }
        &:last-child{
            margin-bottom: 0;
        }
        .global-title__wrapp{
            &::after{
                display: none;
            }
            &::before{
                display: none;
            }
        }
    }
    &__list {
        width: calc(100% + px(100));
        margin-left: px(-50);
        margin-right: px(-50);
        position: relative;
        display: flex;
        flex-wrap: wrap;
        @media(max-width:1800px){
            width: calc(100% + px(60));
            margin-left: px(-30);
            margin-right: px(-30);
        }
        @media(max-width:1650px){
            width: calc(100% + px(40));
            margin-left: px(-20);
            margin-right: px(-20);
        }
        @media(max-width:1024px){
            width: calc(100% + 20px);
            margin-left: -10px;
            margin-right: -10px;
        }
        @media(max-width:700px){
            width: 100%;
            margin: 0;
        }
    }
    &__item {
        width: 50%;
        position: relative;
        margin-bottom: px(80);
        padding: 0 px(50);
        display: flex;
        flex-direction: column;
        @media(max-width:1800px){
            padding: 0 px(30);
            margin-bottom: px(60);
        }
        @media(max-width:1650px){
            padding: 0 px(20);
            margin-bottom: px(40);
        }
        @media(max-width:1024px){
            padding: 0 10px;
            margin-bottom: 40px;
        }
        @media(max-width:700px){
            width: 100%;
            padding: 0;
        }
    }
    &__photo{
        width: 100%;
        position: relative;
        margin-bottom: px(30);
        z-index: 1;
        @media(max-width:1650px){
            margin-bottom: px(20);
        }
        @media(max-width:1024px){
            margin-bottom: 15px;
        }
        img{
            width: 100%;
            position: relative;
            object-fit: contain;
            pointer-events: none;
        }
    }

    &__info {
        width: 100%;
        position: relative;
        margin-bottom: px(35);
        z-index: 1;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: px(17);
        line-height: px(21);
        color: $baseBlack;
        h5{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 900;
            font-size: px(38);
            line-height: px(47);
            background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            margin-bottom: px(15);
            @media(max-width:1800px){
                font-size: px(32);
                line-height: px(42);
            }
            @media(max-width:1650px){
                font-size: px(28);
                line-height: px(36);
            }
            @media(max-width:1024px){
                font-size: 20px;
                line-height: 26px;
                margin-bottom: 12px;
            }
        }
        p{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: px(17);
            line-height: px(21);
            color: $baseBlack;
            @media(max-width:1024px){
                font-size: 15px;
                line-height: 18px;
            }
        }
        b{
            background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            font-weight: 700;
        }
        ul{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: px(12);
            @media(max-width:1024px){
                margin-top: 12px;
            }
            li{
                width: 50%;
                margin-bottom: px(10);
                padding-right: px(5);
                font-family: 'Montserrat';
                position: relative;
                padding-left: px(27);
                @media(max-width:1300px){
                    width: 100%;
                    padding: 0;
                    padding-left: px(27);
                }
                
                &::before{
                    width: px(19);
                    height: px(15);
                    content: '';
                    background: url("../svg/done.svg") no-repeat;
                    position: absolute;
                    left: 0;
                    top: 1px;
                    background-size: contain;
                    @media(max-width:1024px){
                        width: 19px;
                        height: 15px;
                    }
                }
                font-style: normal;
                font-weight: 700;
                font-size: px(16);
                line-height: px(20);
                background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                &:nth-child(2n){
                    padding-right: 0;
                    padding-left: 32px;
                    @media(max-width:1300px){
                        padding-left: 27px;
                    }
                    &::before{
                        left: 5px;
                        @media(max-width:1300px){
                            left: 0;
                        }
                    }
                }
                &:last-child{
                    @media(max-width:1300px){
                        margin-bottom: 0;
                    }
                }
                @media(max-width:1024px){
                    padding-left: 27px;
                    margin-bottom: 10px;
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
        @media(max-width:1800px){
            font-size: px(16);
            line-height: px(20);
        }
        @media(max-width:1650px){
            margin-bottom: px(25);
        }
        @media(max-width:1024px){
            margin-bottom: 15px;
            font-size: 16px;
            line-height: 20px;
        }
    }

    &__bottom {
        margin-top: auto;
        width: 100%;
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media(max-width:1300px){
            flex-direction: column;
        }
    }

    &__bottombox  {
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        .product-option__side {
            @media(min-width: 1301px){
                width: 100%;
            }
            
        }
        .product-option__btn {
            @media(min-width: 1301px){
                max-width: 100%;
                height: fit-content;
            }
            
        }
        .product-option__duration{
            font-size: px(16);
            line-height: px(20);
            b{
                font-size: px(28);
                line-height: px(34);
                @media(max-width:1800px){
                    font-size: px(24);
                    line-height: px(28);
                }
                @media(max-width:1450px){
                    font-size: px(22);
                    line-height: px(26);
                }
                @media(max-width:1350px){
                    font-size: px(20);
                    line-height: px(24);
                }
                @media(max-width:1300px){
                    font-size: px(26);
                    line-height: px(34);
                }
                @media(max-width:1024px){
                    font-size: 20px;
                    line-height: 26px;
                }
            }
            @media(max-width:1800px){
                font-size: px(14);
                line-height: px(18);
            }
            @media(max-width:1450px){
                font-size: px(13);
                line-height: px(16);
            }
            @media(max-width:1300px){
                font-size: px(16);
                line-height: px(20);
                padding-right: px(10);
                margin-right: px(10);
            }
            @media(max-width:1024px){
                padding-right: 8px;
                margin-right: 8px;
                font-size: 16px;
                line-height: 20px;
            }
        }
        .product-option__price{
            font-size: px(16);
            line-height: px(20);
            b{
                font-size: px(28);
                line-height: px(34);
                @media(max-width:1800px){
                    font-size: px(24);
                    line-height: px(28);
                }
                @media(max-width:1450px){
                    font-size: px(22);
                    line-height: px(26);
                }
                @media(max-width:1350px){
                    font-size: px(20);
                    line-height: px(24);
                }
                @media(max-width:1300px){
                    font-size: px(26);
                    line-height: px(34);
                }
                @media(max-width:1024px){
                    font-size: 20px;
                    line-height: 26px;
                }
            }
            @media(max-width:1800px){
                font-size: px(14);
                line-height: px(18);
            }
            @media(max-width:1450px){
                font-size: px(13);
                line-height: px(16);
            }
            @media(max-width:1300px){
                font-size: px(16);
                line-height: px(20);
                padding-right: px(10);
                margin-right: px(10);
            }
            @media(max-width:1024px){
                padding-right: 8px;
                margin-right: 8px;
                font-size: 16px;
                line-height: 20px;
            }
        }
        .product-option__data::after{
            height: px(50);
            @media(max-width:1800px){
                height: px(40);
            }
            @media(max-width:1024px){
                height: 30px;
            }
        }
    }

    &__bottom-col{
        width: calc(100% / 2 - px(10));
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: px(15);
        &:nth-child(2), &:nth-child(1){
            @media(min-width:1301px){
                margin-top: 0;
            }
            
        }
        &:nth-child(2n){
            @media(min-width: 1301px){
                align-items: flex-end;
                .product-option__side {
                    justify-content: flex-end;
                }
                .product-option__data{
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
        @media(max-width:1800px){
            margin-top: px(10);
        }
        @media(max-width:1300px){
            width: 100%;
            margin-top: 0;
            // margin-bottom: 10px;
            // &:last-child{
            //     margin-bottom: 0;
            // }
        }
    }

    &__side {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @media(max-width:1300px){
            width: 100%;
            margin-bottom: px(10);
        }
        @media(max-width:1024px){
            margin-bottom: 5px;
        }
    }

    &__data{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: px(19);
        line-height: px(23);
        background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        padding-right: px(15);
        margin-right: px(15);
        position: relative;
        margin-bottom: px(10);
        @media(max-width:1800px){
            font-size: px(17);
            line-height: px(21);
        }
        @media(max-width:1650px){
            font-size: px(16);
            line-height: px(20);
            padding-right: px(10);
            margin-right: px(10);
        }
        @media(max-width:1024px){
            padding-right: 8px;
            margin-right: 8px;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 10px;
        }
        b{
            font-size: px(36);
            line-height: px(44);
            font-weight: 500;
            @media(max-width:1800px){
                font-size: px(32);
                line-height: px(38);
            }
            @media(max-width:1650px){
                font-size: px(26);
                line-height: px(34);
            }
            @media(max-width:1024px){
                font-size: 20px;
                line-height: 26px;
            }
        }
        span{
            max-width: px(157);
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            display: block;
            font-size: px(15);
            line-height: px(19);
            background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            @media(max-width:1900px){
                font-size: px(14);
                line-height: px(18);
                max-width: px(147);
            }
            @media(max-width:1700px){
                font-size: px(13);
                line-height: px(17);
                max-width: px(137);
            }
            @media(max-width:1450px){
                font-size: px(12);
                line-height: px(14);
                max-width: px(115);
            }
            @media(max-width:1400px){
                max-width: px(87);
            }
            @media(max-width:1350px){
                font-size: px(11);
                line-height: px(13);
                max-width: px(80);
            }
            @media(max-width:1300px){
                font-size: px(12);
                line-height: px(14);
                max-width: px(125);
            }
            @media(max-width:1024px){
                font-size: 12px;
                line-height: 14px;
                max-width: 125px;
            }
        }
        
        &:last-child{
            padding-right: 0;
            &::after{
                display: none;
            }
            @media(max-width:1300px){
                margin-right: 0;
            }
        }
        &::after{
            width: 1px;
            height: px(60);
            background: #9F9F9F;
            position: absolute;
            right: 0;
            bottom: 50%;
            transform: translateY(50%);
            content: '';
            @media(max-width:1800px){
                height: px(49);
            }
            @media(max-width:1650px){
                height: px(42);
            }
            @media(max-width:1024px){
                height: 30px;
            }
        }
    }

    &__price {
        b{
            font-weight: 900;
        }
    }

    &__btn {
        margin-bottom: px(10);
        @media(max-width:1700px){
            max-width: px(280);
            min-width: px(280);
        }
        @media(max-width:1500px){
            max-width: px(260);
            min-width: px(260);
        }
        @media(max-width:1350px){
            max-width: px(250);
            min-width: px(250);
        }
        @media(max-width:1300px){
            max-width: none;
            min-width: 100%;
            margin-bottom: 0;
        }
    }
}