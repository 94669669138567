.global-btn{
  cursor: pointer;
  outline: none;
  background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
  min-height: px(60);
  min-width: px(325);
  color: $baseWhite;
  border-radius: px(5);
  padding: px(10) px(20);
  transition: .3s;
  position: relative;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: px(16);
  line-height: 150%;
  text-align: center;
  z-index: 1;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &::after{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    right: 50%;
    background: linear-gradient(270deg, #88BCF9 0%, #B458EC 100%);
    transform: translate(50%, -50%);
    transition: .3s;
    z-index: -1;
    content: '';
    opacity: 0;
  }
  .icon{
    fill: $baseBlue;
    transition: .3s;
    margin-left: px(14);
    max-width: px(36);
    max-height: px(36);
    object-fit: contain;
    @media(max-width:1024px){
      margin-left: 14px;
      max-width: 36px;
      max-height: 36px;
    }
  }
  .icon-long-arrow{
    width: px(36);
    height: px(8);
    @media(max-width:1024px){
      width: 36px;
      height: 8px;
    }
  }
  &:hover{
    @media(min-width: 1025px){
      color: $baseWhite;
      .icon{
        fill: $baseWhite;
      }
    }
  }
  &:hover::after{
    @media(min-width: 1025px){
      transform: translate(50%, -50%);
      opacity: 1;
    }
  }
  &.rounded-btn{
    border-radius: px(77);
    @media(max-width:1024px){
      border-radius: 77px;
    }
  }

  @media(max-width:1024px){
    min-height: 50px;
    font-size: 14px;
    border-radius: 5px;
    line-height: 18px;
    padding: 7px 15px;

  }
  @media(max-width:450px){
    min-width: 100%;
  }
}


// slider buttons
.slider-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: px(40);
  @media(max-width:1024px){
    margin-top: 20px;
  }
}

.slider-button{
  width: px(40);
  height: px(40);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: px(20);
  padding: px(3);
  border-radius: px(5);
  cursor: pointer;
  transition: .3s;
  background-color: $baseBlue;
  @media(max-width:1024px){
    width: 35px;
    height: 35px;
    margin-right: 12px;
    border-radius: 4px;
    padding: 3px;
  }
  &:last-child{
    margin-right: 0;
  }
  .icon{
    width: px(7);
    height: px(12);
    fill: $baseWhite;
    transition: .3s;
    object-fit: contain;
    @media(max-width:1024px){
      width: 6px;
      height: 10px;
    }
  }

  &.slider-prev{
    .icon{
      transform: scale(-1,1);
    }
  }
  &:hover{
    @media(min-width: 1025px){
      background-color: $secondaryBlue;
    }
  }
  &.swiper-button-disabled{
    opacity: 0.6;
    pointer-events: none;
  }
}

// slider buttons end