.marketing-planning{
    &-section{
        padding-top: px(60);
        padding-bottom: px(10);
        @media(max-width:1024px){
            padding-top: 40px;
            padding-bottom: 10px;
        }
        .product-infocard__list{
            position: relative;
            &::after{
                position: absolute;
                top: 0;
                left: px(40);
                content: "SEO";
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 900;
                font-size: px(200);
                line-height: px(200);
                text-align: center;
                color: #F9F9F9;
                transform: translateY(-77%);
                @media(max-width:1650px){
                    font-size: px(150);
                    line-height: px(150);
                }
                @media(max-width:1024px){
                    display: none;
                }
            }
        }
    }
}