.product-compare{
    &__list{
        width: calc(100% + px(30));
        margin-left: px(-15);
        margin-right: px(-15);
        display: flex;
        flex-wrap: wrap;
        @media(max-width:1300px){
            flex-direction: column;
            margin: 0;
            width: 100%;
        }
    }

    &__column{
        width: 50%;
        padding: 0 px(15);
        margin-bottom: px(30);
        position: relative;
        @media(max-width:1300px){
            width: 100%;
            padding: 0;
            margin-bottom: 30px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    &__table{
        width: 100%;
        position: relative;
        border-radius: px(10);
        border: 1px solid #9C9C9C;
        @media(max-width:1024px){
            border-radius: 10px;
        }
    }
    &__header{
        width: calc(100% + 2px);
        margin-left: -1px;
        margin-right: -1px;
        padding: 0 1px;
        margin-top: -1px;
        display: flex;
        align-items: center;
        border-top-left-radius: px(10);
        border-top-right-radius: px(10);
        background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
        @media(max-width:1024px){
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
    }
    &__th{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: px(17);
        line-height: px(21);
        text-align: center;
        min-height: px(110);
        border-right: 1px solid #88ADEE;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: $baseWhite;
        padding: px(5) px(7);
        @media(max-width:1650px){
            font-size: px(16);
            line-height: px(20);
            min-height: px(100);
        }
        @media(max-width:1450px){
            font-size: px(14);
            line-height: px(18);
            min-height: px(85);
        }
        @media(max-width:1024px){
            min-height: 70px;
            font-size: 14px;
            line-height: 18px;
            padding: 5px 7px;
        }
        @media(max-width:576px){
            font-size: 12px;
            line-height: 14px;
        }
        &:last-child{
            border-right: 0;
        }

        &:nth-child(1){
           width: 44%;
        }
        &:nth-child(2){
            width: 28%;
        }
        &:nth-child(3){
            width: 28%;
        }
    }
    &__body {
        width: 100%;
        position: relative;
        border-top: 0;
        border-bottom-left-radius: px(9);
        border-bottom-right-radius: px(9);
        overflow: hidden;
        width: calc(100% + 0.5px);
        @media(max-width:1300px){
            width: 100%;
        }
        @media(max-width:1024px){
            border-bottom-left-radius: 9px;
            border-bottom-right-radius: 9px;
        }
    }
    &__tr{
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #DADADA;
        overflow: hidden;
        &:last-child{
            border-bottom: 0;
        }
    }
    &__td {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: px(5) px(7);
        align-items: center;
        min-height: px(75);
        background-color: $secondaryBlue;
        color: $baseWhite;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: px(17);
        line-height: px(21);
        text-align: center;
        position: relative;
        z-index: 1;
        &::after{
            content: '';
            position: absolute;
            pointer-events: none;
            top: 50%;
            left: 0;
            width: 100%;
            height: px(300);
            transform: translateY(-50%);
            z-index: -1;
            background-color: $secondaryBlue;
            border-right: 1px solid #DADADA;
            @media(max-width:1024px){
                height: 300px;
            }
        }
        @media(max-width:1650px){
            font-size: px(16);
            line-height: px(20);
        }
        @media(max-width:1450px){
            font-size: px(14);
            line-height: px(18);
        } 
        @media(max-width:1024px){
            font-size: 14px;
            line-height: 18px;
            min-height: 70px;
            padding: 5px 7px;
        }
        @media(max-width:576px){
            font-size: 12px;
            line-height: 14px;
        }
        &:last-child{
            &::after{
                border:none;
            }
        }
        &:nth-child(1){
            width: 44%; 
        }
        &:nth-child(2){
            width: 28%;
        }
        &:nth-child(3){
            width: 28%;
        }
        &.second-level-td{
            background-color:#618CFA;
            &::after{
                background-color:#618CFA;
            }
        }
        &.third-level-td{
            background-color:#6167FA;
            &::after{
                background-color:#6167FA;
            }
        }
        &.fourth-level-td{
            background-color:#A461FA;
            &::after{
                background-color:#A461FA;
            }
        }
    }
    &__main-td{
        background-color: $baseWhite;
        flex-direction: row;
        justify-content: flex-start;
        height: 100%;
        &::after{
            background-color:$baseWhite;
        }
    }
    &__td-number{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: px(23);
        line-height: px(28);
        background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        margin-right: px(10) ;
        min-width: px(32);
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        @media(max-width:1650px){
            font-size: px(20);
            line-height: px(26);
            min-width: px(26);
            margin-right: px(7);
        }
        @media(max-width:1024px){
            font-size: 18px;
            line-height: 20px;
            margin-right: 5px;
            min-width: 24px;
        }
        @media(max-width:576px){
            font-size: 16px;
            line-height: 18px;
            margin-right: 5px;
            min-width: 22px;
        }
    }
    &__td-name{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: px(14);
        line-height: px(16);
        text-align: left;
        color: $baseBlack;
        @media(max-width:1650px){
            font-size: px(13);
            line-height: px(15);
        }
        @media(max-width:1024px){
            font-size: 11px;
            line-height: 13px;
        }
        @media(max-width:576px){
            font-size: 10px;
            line-height: 12px;
        }
    }
}