$ViewPort: false;
// LIBS
@import "libs";

// Custom mixins
@import 'vars/index';

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

.lock{
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch;
  touch-action: none;
}
.disable-display{
  display: none !important;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  line-height: 1.3;
  font-size: px(16);
  scrollbar-gutter: stable;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  color: $baseBlack;
  background-color: $baseWhite;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

ul, ol, li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

textarea {
  resize: none;
}

section {
  position: relative;
}

input,
select,
button,
textarea {
  outline: none !important;
  border-radius: 0 ;
  appearance: none;
}

button{
  cursor: pointer;
}

*, *::before, *::after {
  box-sizing: border-box;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.global-wrapp{
  width: 100%;
  position: relative;
  padding: px(45) px(114);
  background-color: $baseWhite;
  &.bg-silver{
    background-color: $baseSilver;
  }
  @media(max-width:1600px){
    padding: px(45) px(70);
  }
  @media(max-width:1300px){
    padding: px(45) px(50);
  }
  @media(max-width:1150px){
    padding: px(45) px(30);
  }
  @media(max-width:1024px){
    padding: 20px 15px;
  }
}

.global-container{
  width: 100%;
  position: relative;
  max-width: px(1680);
  margin: 0 auto;
  @media(max-width:1024px){
    max-width: 1680px;
  }
}

.global-category__box{
  width: 100%;
  position: relative;
  margin-bottom: px(40);
  @media(max-width:1024px){
    margin-bottom: 20px;
  }
  &:last-child{
    margin-bottom: 0;
  }
}

button,textarea, input{
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
}

.content {
  flex: 1 0 auto;
}

.social__list{
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  li{
    margin: px(10);
    @media(max-width:1300px){
      margin: px(7);
    }
    @media(max-width:1024px){
      margin: 7px;
    }
  }
}
.social__item{
  display: flex;
  align-items: center;
  justify-content: center;
  .icon{
      max-width: px(22);
      max-height: px(20);
      object-fit: contain;
      transition: .3s;
      fill: $secondaryGray;
      @media(max-width:1150px){
        max-width: px(20);
        max-height: px(17);
      }
      @media(max-width:1024px){
        max-width: 20px;
        max-height: 18px;
      }
  }
  &:hover{
      @media(min-width: 1025px){
          .icon{
              fill: $basePurple;
          }
          
      }
  }
}


// Modules & Components styles
@import "modules";
@import "components";
