.marketing-compare{
    &-section{
        padding-top: px(40);
        padding-bottom: px(10);
        @media(max-width:1300px){
            padding-bottom: px(40);
        }
        @media(max-width:1024px){
            padding-top: 30px;
            padding-bottom: 30px;
        }
        .global-title__wrapp{
            @media(max-width:576px){
                margin-bottom: 30px;
            }
        }
    }

    
}