.product-infocard{
    &__list{
        width: calc(100% + px(40));
        margin-left: px(-20);
        margin-right: px(-20);
        display: flex;
        flex-wrap: wrap;
        position: relative;
        @media(max-width:1024px){
            width: calc(100% + 20px);
            margin-left: -10px;
            margin-right: -10px;
        }
        @media(max-width:700px){
            width: 100%;
            margin: 0;
        }
    }

    &__item {
        width: 50%;
        padding: 0 px(20);
        position: relative;
        margin-bottom: px(45);
        display: flex;
        flex-direction: column;
        @media(max-width:1650px){
            margin-bottom: px(40);
        }
        @media(max-width:1024px){
            padding: 0 10px;
            margin-bottom: 20px;
        }
        @media(max-width:700px){
            width: 100%;
            padding: 0;
        }
    }
    &__item-inner {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        background-color: $baseWhite;
        border-radius: px(5);
        z-index: 1;
        padding: px(38) px(30);
        padding-bottom: px(20);
        @media(max-width:1650px){
            padding: px(30) px(25);
            padding-bottom: px(15);
        }
        @media(max-width:1300px){
            padding: px(20);
        }
        @media(max-width:1024px){
            padding: 20px;
            border-radius: 5px;
        }
        &::before{
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -2;
            pointer-events: none;
            border-radius: px(5);
            background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
            @media(max-width:1024px){
                border-radius: 5px;
            }
        }
        &::after{
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            border-radius: px(4);
            background-color: $baseWhite;
            position: absolute;
            z-index: -1;
            top: 1px;
            left: 1px;
            content: '';
            pointer-events: none;
            @media(max-width:1024px){
                border-radius: 4px;
            }
        }
    }

    &__info {
        width: 100%;
        position: relative;
        margin-bottom: px(30);
        z-index: 1;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: px(16);
        line-height: px(20);
        color: $baseBlack;
        h5{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: px(24);
            line-height: px(30);
            background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            margin-bottom: px(21);
            @media(max-width:1650px){
                font-size: px(22);
                line-height: px(28);
                margin-bottom: px(17);
            }
            @media(max-width:1024px){
                font-size: 20px;
                line-height: 26px;
                margin-bottom: 13px;
            }
        }
        p{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: px(16);
            line-height: px(21);
            color: $baseBlack;
            @media(max-width:1024px){
                font-size: 15px;
                line-height: 18px;
            }
        }
        b{
            background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            font-weight: 700;
        }
        ul{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: px(12);
            li{
                width: 50%;
                margin-bottom: px(10);
                padding-right: px(5);
                font-family: 'Montserrat';
                position: relative;
                padding-left: px(27);
                @media(max-width:1300px){
                    width: 100%;
                    padding: 0;
                    padding-left: px(27);
                }
                
                &::before{
                    width: 19px;
                    height: 15px;
                    content: '';
                    background: url("../svg/done.svg") no-repeat;
                    position: absolute;
                    left: 0;
                    top: 1px;
                    background-size: contain;
                }
                font-style: normal;
                font-weight: 700;
                font-size: px(16);
                line-height: px(20);
                background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                &:nth-child(2n){
                    padding-right: 0;
                    padding-left: 32px;
                    @media(max-width:1300px){
                        padding-left: 27px;
                    }
                    &::before{
                        left: 5px;
                        @media(max-width:1300px){
                            left: 0;
                        }
                    }
                }
                &:last-child{
                    @media(max-width:1300px){
                        margin-bottom: 0;
                    }
                }
                @media(max-width:1024px){
                    margin-bottom: 10px;
                    padding-left: 27px;
                    font-size: 16px;
                    line-height: 20px;
                }
            }
            @media(max-width:1024px){
                margin-top: 12px;
            }
        }
        ul.price-ul {
            li{
                width: 100%;
                padding-left: px(27);
                padding-right: 0 ;
                font-size: px(16);
                margin-bottom: px(16);
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                -webkit-text-fill-color: $baseBlue;
                background: $baseWhite;
                z-index: 1;
                &:last-child{
                    margin-bottom: 0;
                }
                span{
                    padding-right: px(5);
                    @media(max-width:1024px){
                        padding-right: 0;
                    }
                }
                .value{
                    font-weight: 500;
                    min-width: fit-content;
                    margin-left: px(55);
                    padding-right: 0;
                    padding-left: px(3);
                    -webkit-text-fill-color: #9F68DB;
                    @media(max-width:1650px){
                        margin-left: px(45);
                    }
                    @media(max-width:1450px){
                        margin-left: px(35);
                    }
                    @media(max-width:1024px){
                        margin-left: 0;
                        margin-top: 5px;
                        min-width: auto;
                        padding-left: 0;
                    }
                }
                @media(max-width:1300px){
                    margin-bottom: px(10);
                }
                @media(max-width:1024px){
                    flex-direction: column;
                    align-items: flex-start;
                    font-size: 15px;
                    padding-left: 27px;
                    margin-bottom: 10px;
                }
                span{
                    position: relative;
                    z-index: 1;
                    &::before{
                        background-color: $baseWhite;
                        width: 100%;
                        height: 100%;
                        z-index: -1;
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        @media(max-width:1024px){
                            display: none;
                        }
                    }
                    @media(max-width:1024px){
                        position: static;
                        z-index: 0;
                    }
                }
                &::before{
                    left: 0;
                }
                &::after{
                    width: calc(100% - px(27));
                    position: absolute;
                    content: '';
                    background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
                    height: 1px;
                    bottom: 5px;
                    left: px(27);
                    z-index: -1;
                    pointer-events: none;
                    @media(max-width:1024px){
                        display: none;
                    }
                }
            }     
        }

        ul.full-ul {
            li{
                width: 100%;
                padding-left: px(27);
                padding-right: 0 ;
                font-size: px(15.5);
                margin-bottom: px(16);
                &:last-child{
                    margin-bottom: 0;
                }
                @media(max-width:1300px){
                    margin-bottom: px(10);
                }
                @media(max-width:1024px){
                    font-size: 15px;
                    margin-bottom: 10px;
                    padding-left: 27px;
                }
                &::before{
                    left: 0;
                }
            }     
        }
        h6{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: px(18);
            line-height: px(23);
            color: $baseBlack;
            margin-bottom: px(15); 
            margin-top: px(22);
            @media(max-width:1024px){
                margin-top: 15px;
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 15px;
            }
        }

        i{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: px(15.5);
            line-height: px(19);
            color: #434343;
            width: 100%;
            margin-top: px(16);
            display: block;
            @media(max-width:1024px){
                font-size: 15px;
                line-height: 19px;
                margin-top: 10px;
            }
            &+ ul{
                margin-top: px(16);
                @media(max-width:1024px){
                    margin-top: 10px;
                }
            }
        }
        @media(max-width:1650px){
            margin-bottom: px(25);
        }
        @media(max-width:1024px){
            margin-bottom: 15px;
            font-size: 16px;
            line-height: 20px;
        }
    }

    &__bottom {
        margin-top: auto;
        width: 100%;
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media(max-width:1300px){
            flex-direction: column;
        }
    }

    &__bottombox{
        position: relative;
        .product-infocard__price{
            font-size: px(13);
            line-height: px(15);
            @media(max-width:1800px){
                font-size: px(12);
                line-height: px(14);
            }
            @media(max-width:1024px){
                font-size: 12px;
                line-height: 14px;
            }
            b{
                font-size: px(24);
                line-height: px(30);
                @media(max-width:1800px){
                    font-size: px(20);
                    line-height: px(24);
                }
                @media(max-width:1300px){
                    font-size: px(26);
                    line-height: px(32);
                }
                @media(max-width:1024px){
                    font-size: 20px;
                    line-height: 24px;
                }
            }
        }
        .product-infocard__data::after{
            height: px(20);
            @media(max-width:1024px){
                height: 20px;
            }
        }
        @media(max-width:1300px){
            width: 100%;
        }
    }

    &__bottombox-row{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media(max-width:1500px){
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__bottom-technology{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: px(24);
        line-height: px(30);
        background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        margin-bottom: px(12);
        margin-right: px(10);
        @media(max-width:1800px){
            font-size: px(20);
            line-height: px(24);
        }
        @media(max-width:1500px){
            font-size: px(18);
            line-height: px(22);
            margin-bottom: px(5);
            margin-right: 0;
        }
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 5px;
        }
    }

    &__side {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @media(max-width:1300px){
            width: 100%;
            margin-bottom: px(10);
        }
        @media(max-width:1024px){
            margin-bottom: 5px;
        }
    }

    &__data{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: px(19);
        line-height: px(23);
        background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        padding-right: px(15);
        margin-right: px(15);
        position: relative;
        margin-bottom: px(10);
        @media(max-width:1800px){
            font-size: px(17);
            line-height: px(21);
        }
        @media(max-width:1650px){
            font-size: px(16);
            line-height: px(20);
            padding-right: px(10);
            margin-right: px(10);
        }
        @media(max-width:1024px){
            padding-right: 8px;
            margin-right: 8px;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 10px;
        }
        b{
            font-size: px(36);
            line-height: px(44);
            font-weight: 500;
            @media(max-width:1800px){
                font-size: px(32);
                line-height: px(38);
            }
            @media(max-width:1650px){
                font-size: px(26);
                line-height: px(34);
            }
            @media(max-width:1024px){
                font-size: 20px;
                line-height: 26px;
            }
        }
        span{
            max-width: px(157);
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            display: block;
            font-size: px(15);
            line-height: px(19);
            background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            @media(max-width:1900px){
                font-size: px(14);
                line-height: px(18);
                max-width: px(147);
            }
            @media(max-width:1700px){
                font-size: px(13);
                line-height: px(17);
                max-width: px(137);
            }
            @media(max-width:1450px){
                font-size: px(12);
                line-height: px(14);
                max-width: px(115);
            }
            @media(max-width:1400px){
                max-width: px(87);
            }
            @media(max-width:1350px){
                font-size: px(11);
                line-height: px(13);
                max-width: px(80);
            }
            @media(max-width:1300px){
                font-size: px(12);
                line-height: px(14);
                max-width: px(125);
            }
            @media(max-width:1024px){
                font-size: 12px;
                line-height: 14px;
                max-width: 125px;
            }
        }
        
        &:last-child{
            padding-right: 0;
            &::after{
                display: none;
            }
            @media(max-width:1300px){
                margin-right: 0;
            }
        }
        &::after{
            width: 1px;
            height: px(60);
            background: #9F9F9F;
            position: absolute;
            right: 0;
            bottom: 50%;
            transform: translateY(50%);
            content: '';
            @media(max-width:1800px){
                height: px(49);
            }
            @media(max-width:1650px){
                height: px(42);
            }
            @media(max-width:1024px){
                height: 30px;
            }
        }
    }

    &__price {
        font-weight: 900;
        b{
            font-weight: 900;
        }
    }

    &__btn {
        margin-bottom: px(10);
        @media(max-width:1850px){
            max-width: px(290);
            min-width: px(290);
        }
        @media(max-width:1700px){
            max-width: px(270);
            min-width: px(270);
        }
        @media(max-width:1500px){
            max-width: px(250);
            min-width: px(250);
        }
        @media(max-width:1300px){
            max-width: none;
            min-width: 100%;
            margin-bottom: 0;
        }
    }
}