.graphic-design{
    &-section{
        padding-bottom: px(35);
        padding-top: px(80);
        @media(max-width:1650px){
            padding-top: px(65);
        }
        @media(max-width: 1024px){
            padding-top: 40px;
            padding-bottom: 5px;
        }
    }
}