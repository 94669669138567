.design-planning{
    &-section{
        padding-top: px(70);
        padding-bottom: px(60);
        @media(max-width:1650px){
            padding-bottom: px(50);
            padding-top: px(63);
        }
        @media(max-width:1024px){
            padding-top: 40px;
            padding-bottom: 20px;
        }
        .global-title__wrapp{
            margin-bottom: px(75);
            @media(max-width:1650px){
                margin-bottom: px(65);
            }
            @media(max-width:1024px){
                margin-bottom: 40px;
            }
        }
    }
    &__title{
        font-weight: 900;
    }
}