.marketing-package{
    &-section{
        padding-top: px(50);
        padding-bottom: px(40);
        @media(max-width:1024px){
            padding-top: 30px;
            padding-bottom: 30px;
        }
        .global-container{
            max-width: px(1530);
            @media(max-width:1024px){
                max-width: 1530px;
            }
        }
        .global-title__wrapp{
            @media(max-width:1024px){
                margin-bottom: 15px;
            }
        }
    }
    &__text{
        margin-bottom: px(50);
        text-align: center;
        @media(max-width:1650px){
            margin-bottom: px(40);
        }
        @media(max-width:1024px){
            margin-bottom: 25px;
        }
    }
    &__btn {
        min-width: px(410);
        margin-left: 50%;
        transform: translateX(-50%);
        @media(max-width:576px){
            min-width: 100%;
        }
        
    }
}