.modal{
  &-wraper{
    width: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    height: 100vh;
    max-height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    overflow-y: auto;
    background-color: rgba($baseWhite,0.6);
    backdrop-filter: blur(6px);
    display: flex;
    flex-direction: column;
    padding: px(50) px(30);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: .3s;
    @media(max-width:1024px){
      padding: 40px 15px;
    }
    &.active{
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
    &.thanks-popup, &.error-popup{
      .modal__header{
        font-size: px(32);
        @media(max-width:1350px){
          font-size: px(28);
        }
        @media(max-width:1024px){
          font-size: 24px;
        }
      }
      .modal__text{
        font-size: px(18);
        @media(max-width:1024px){
          font-size: 14px;
        }
      }
    }
  }
  
  &-form-close__btn{
    min-width: 100%;
  }

  &__box{
    margin: auto;
    width: 100%;
    max-width: px(496);
    background: $baseWhite;
    border: 1px solid #D6D6D6;
    border-radius: px(10);
    position: relative;
    @media(max-width:1024px){
      max-width: 400px;
      border-radius: 10px;
    }
    @media(max-width:576px){
      max-width: 100%;
    }
    .iti__selected-flag{
      margin: 0 ;
      height: 100%;
    }

    .form-field{
      padding: 0;
      width: 100%;
      margin-bottom: px(20);
      @media(max-width:1024px){
        margin-bottom: 20px;
      }
    }
    .form__field-name{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: px(14);
      line-height: 100%;
      color: #A4A4A4;
      margin-bottom: px(10);
      @media(max-width:1024px){
        font-size: 12px;
        margin-bottom: 7px;
      }
    }
    .form__field-require{
      max-width: 50%;
      padding-left: px(5);
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: px(14);
      line-height: 100%;
      text-align: right;
      text-decoration-line: underline;
      margin-bottom: px(10);
      text-align: right;
      color: $baseRed;
      transition: .3s;
      opacity: 0;
      visibility: hidden;
      @media(max-width:1024px){
        font-size: 12px;
        margin-bottom: 7px;
        padding-left: 5px;
      }
    }
    .form__field-row{
      width: 100%;
      position: relative;
      display: flex;
      justify-content: space-between;
    }
    .form__field-input{
      width: 100%;
      position: relative;
      &::after{
        display: none;
      }
    }
    .form__input{
      min-height: px(60);
      border: 1px solid #E0E0E0;
      border-radius: px(5);
      padding: px(15) px(25);
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: px(16);
      line-height: 100%;
      color: $baseBlack;
      &::placeholder{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: px(16);
        line-height: 100%;
        color: #A4A4A4;
        transition: .3s;
        @media(max-width:1024px){
          font-size: 14px;
        }
      }
      &:focus{
        border-color: $basePurple;
        &::placeholder{
          color: $basePurple;
        }
      }
      @media(max-width:1024px){
        font-size: 14px;
        padding: 10px 15px;
        min-height: 50px;
        border-radius: 5px;
      }
    }
  }

  &__header{
    width: 100%;
    position: relative;
    min-height: px(90);
    background: $baseBlue;
    color: $baseWhite;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: px(24);
    line-height: 100%;
    padding: px(15);
    border-top-left-radius: px(10);
    border-top-right-radius: px(10);
    width: calc(100% +  2px);
    margin-left: -1px;
    margin-right: -1px;
    margin-top: -1px;
    @media(max-width:1350px){
      font-size: px(20);
    }
    @media(max-width:1024px){
      font-size: 18px;
      min-height: 70px;
      padding: 10px 20px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  &__close{
    width: px(30);
    height: px(30);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    transition: .3s;
    transform: translateX(px(45)) translateY(px(-35));
    @media(max-width:1024px){
      width: 25px;
      height: 25px;
      transform: translateX(25px) translateY(-25px);
    }
    @media(max-width:576px){
      transform: translateX(0) translateY(-30px);
    }
    .icon{
      max-width: px(30);
      max-height: px(30);
      fill: $baseBlue;
      transition: .3s;
      @media(max-width:1024px){
        max-width: 25px;
        max-height: 25px;
      }
    }
    &:hover{
      @media(min-width:1025px){
        .icon{
          fill: $basePurple;
        }
      }
      
    }
  }

  &__content {
    width: 100%;
    position: relative;
    background-color: $baseWhite;
    padding: px(25) px(55);
    padding-bottom: px(38);
    border-bottom-left-radius: px(9);
    border-bottom-right-radius: px(9);
    @media(max-width:1024px){
      padding: 20px;
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
    }
  }
  &__text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: px(16);
    line-height: 100%;
    text-align: center;
    color: $baseBlack;
    margin-bottom: px(30);
    @media(max-width:1024px){
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
  &-form{
    width: 100%;
    position: relative;
  }
  &-form__btn{
    min-width: 100%;
  }
}