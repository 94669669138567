@font-face
{
    font-family: 'Montserrat';
    font-weight: 500;
    font-style: normal;

    src: url('../fonts/Montserrat-Medium.eot');
    src: url('../fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat-Medium.woff2') format('woff2'), url('../fonts/Montserrat-Medium.woff') format('woff'), url('../fonts/Montserrat-Medium.ttf') format('truetype'), url('../fonts/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
    font-display: swap;
}
@font-face
{
    font-family: 'Montserrat';
    font-weight: 300;
    font-style: normal;

    src: url('../fonts/Montserrat-Light.eot');
    src: url('../fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat-Light.woff2') format('woff2'), url('../fonts/Montserrat-Light.woff') format('woff'), url('../fonts/Montserrat-Light.ttf') format('truetype'), url('../fonts/Montserrat-Light.svg#Montserrat-Light') format('svg');
    font-display: swap;
}
@font-face
{
    font-family: 'Montserrat';
    font-weight: 200;
    font-style: normal;

    src: url('../fonts/Montserrat-ExtraLight.eot');
    src: url('../fonts/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat-ExtraLight.woff2') format('woff2'), url('../fonts/Montserrat-ExtraLight.woff') format('woff'), url('../fonts/Montserrat-ExtraLight.ttf') format('truetype'), url('../fonts/Montserrat-ExtraLight.svg#Montserrat-ExtraLight') format('svg');
    font-display: swap;
}
@font-face
{
    font-family: 'Montserrat';
    font-weight: bold;
    font-style: normal;

    src: url('../fonts/Montserrat-ExtraBold.eot');
    src: url('../fonts/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat-ExtraBold.woff2') format('woff2'), url('../fonts/Montserrat-ExtraBold.woff') format('woff'), url('../fonts/Montserrat-ExtraBold.ttf') format('truetype'), url('../fonts/Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
    font-display: swap;
}
@font-face
{
    font-family: 'Montserrat';
    font-weight: 900;
    font-style: italic;

    src: url('../fonts/Montserrat-BlackItalic.eot');
    src: url('../fonts/Montserrat-BlackItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat-BlackItalic.woff2') format('woff2'), url('../fonts/Montserrat-BlackItalic.woff') format('woff'), url('../fonts/Montserrat-BlackItalic.ttf') format('truetype'), url('../fonts/Montserrat-BlackItalic.svg#Montserrat-BlackItalic') format('svg');
    font-display: swap;
}
@font-face
{
    font-family: 'Montserrat';
    font-weight: bold;
    font-style: italic;

    src: url('../fonts/Montserrat-ExtraBoldItalic.eot');
    src: url('../fonts/Montserrat-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat-ExtraBoldItalic.woff2') format('woff2'), url('../fonts/Montserrat-ExtraBoldItalic.woff') format('woff'), url('../fonts/Montserrat-ExtraBoldItalic.ttf') format('truetype'), url('../fonts/Montserrat-ExtraBoldItalic.svg#Montserrat-ExtraBoldItalic') format('svg');
    font-display: swap;
}
@font-face
{
    font-family: 'Montserrat';
    font-weight: normal;
    font-style: italic;

    src: url('../fonts/Montserrat-Italic.eot');
    src: url('../fonts/Montserrat-Italic.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat-Italic.woff2') format('woff2'), url('../fonts/Montserrat-Italic.woff') format('woff'), url('../fonts/Montserrat-Italic.ttf') format('truetype'), url('../fonts/Montserrat-Italic.svg#Montserrat-Italic') format('svg');
    font-display: swap;
}
@font-face
{
    font-family: 'Montserrat';
    font-weight: 900;
    font-style: normal;

    src: url('../fonts/Montserrat-Black.eot');
    src: url('../fonts/Montserrat-Black.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat-Black.woff2') format('woff2'), url('../fonts/Montserrat-Black.woff') format('woff'), url('../fonts/Montserrat-Black.ttf') format('truetype'), url('../fonts/Montserrat-Black.svg#Montserrat-Black') format('svg');
    font-display: swap;
}
@font-face
{
    font-family: 'Montserrat';
    font-weight: 300;
    font-style: italic;

    src: url('../fonts/Montserrat-LightItalic.eot');
    src: url('../fonts/Montserrat-LightItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat-LightItalic.woff2') format('woff2'), url('../fonts/Montserrat-LightItalic.woff') format('woff'), url('../fonts/Montserrat-LightItalic.ttf') format('truetype'), url('../fonts/Montserrat-LightItalic.svg#Montserrat-LightItalic') format('svg');
    font-display: swap;
}
@font-face
{
    font-family: 'Montserrat';
    font-weight: bold;
    font-style: italic;

    src: url('../fonts/Montserrat-BoldItalic.eot');
    src: url('../fonts/Montserrat-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat-BoldItalic.woff2') format('woff2'), url('../fonts/Montserrat-BoldItalic.woff') format('woff'), url('../fonts/Montserrat-BoldItalic.ttf') format('truetype'), url('../fonts/Montserrat-BoldItalic.svg#Montserrat-BoldItalic') format('svg');
    font-display: swap;
}
@font-face
{
    font-family: 'Montserrat';
    font-weight: 200;
    font-style: italic;

    src: url('../fonts/Montserrat-ExtraLightItalic.eot');
    src: url('../fonts/Montserrat-ExtraLightItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat-ExtraLightItalic.woff2') format('woff2'), url('../fonts/Montserrat-ExtraLightItalic.woff') format('woff'), url('../fonts/Montserrat-ExtraLightItalic.ttf') format('truetype'), url('../fonts/Montserrat-ExtraLightItalic.svg#Montserrat-ExtraLightItalic') format('svg');
    font-display: swap;
}
@font-face
{
    font-family: 'Montserrat';
    font-weight: bold;
    font-style: normal;

    src: url('../fonts/Montserrat-Bold.eot');
    src: url('../fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat-Bold.woff2') format('woff2'), url('../fonts/Montserrat-Bold.woff') format('woff'), url('../fonts/Montserrat-Bold.ttf') format('truetype'), url('../fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-display: swap;
}
@font-face
{
    font-family: 'Montserrat';
    font-weight: 100;
    font-style: italic;

    src: url('../fonts/Montserrat-ThinItalic.eot');
    src: url('../fonts/Montserrat-ThinItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat-ThinItalic.woff2') format('woff2'), url('../fonts/Montserrat-ThinItalic.woff') format('woff'), url('../fonts/Montserrat-ThinItalic.ttf') format('truetype'), url('../fonts/Montserrat-ThinItalic.svg#Montserrat-ThinItalic') format('svg');
    font-display: swap;
}
@font-face
{
    font-family: 'Montserrat';
    font-weight: 600;
    font-style: normal;

    src: url('../fonts/Montserrat-SemiBold.eot');
    src: url('../fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat-SemiBold.woff2') format('woff2'), url('../fonts/Montserrat-SemiBold.woff') format('woff'), url('../fonts/Montserrat-SemiBold.ttf') format('truetype'), url('../fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
    font-display: swap;
}
@font-face
{
    font-family: 'Montserrat';
    font-weight: 500;
    font-style: italic;

    src: url('../fonts/Montserrat-MediumItalic.eot');
    src: url('../fonts/Montserrat-MediumItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat-MediumItalic.woff2') format('woff2'), url('../fonts/Montserrat-MediumItalic.woff') format('woff'), url('../fonts/Montserrat-MediumItalic.ttf') format('truetype'), url('../fonts/Montserrat-MediumItalic.svg#Montserrat-MediumItalic') format('svg');
    font-display: swap;
}
@font-face
{
    font-family: 'Montserrat';
    font-weight: normal;
    font-style: normal;

    src: url('../fonts/Montserrat-Regular.eot');
    src: url('../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat-Regular.woff2') format('woff2'), url('../fonts/Montserrat-Regular.woff') format('woff'), url('../fonts/Montserrat-Regular.ttf') format('truetype'), url('../fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-display: swap;
}
@font-face
{
    font-family: 'Montserrat';
    font-weight: 100;
    font-style: normal;

    src: url('../fonts/Montserrat-Thin.eot');
    src: url('../fonts/Montserrat-Thin.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat-Thin.woff2') format('woff2'), url('../fonts/Montserrat-Thin.woff') format('woff'), url('../fonts/Montserrat-Thin.ttf') format('truetype'), url('../fonts/Montserrat-Thin.svg#Montserrat-Thin') format('svg');
    font-display: swap;
}
@font-face
{
    font-family: 'Montserrat';
    font-weight: 600;
    font-style: italic;

    src: url('../fonts/Montserrat-SemiBoldItalic.eot');
    src: url('../fonts/Montserrat-SemiBoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/Montserrat-SemiBoldItalic.woff2') format('woff2'), url('../fonts/Montserrat-SemiBoldItalic.woff') format('woff'), url('../fonts/Montserrat-SemiBoldItalic.ttf') format('truetype'), url('../fonts/Montserrat-SemiBoldItalic.svg#Montserrat-SemiBoldItalic') format('svg');
    font-display: swap;
}
/**
 * Functions for ViewPort Calculating
============
  SCSS
  .selector {
    font-size: px(18);
    @include md
      font-size: pxm(14);
    @include xs
      font-size: pxs(12);
  }

  CSS RESULT
  .selector {
    font-size: 18px;
  }
  @media (max-width: 768px) {
    .selector {
      font-size: 14px;
    }
  }
  @media (max-width: 320px) {
    .selector {
      font-size: 12px;
    }
  }
============
*/
/**
  function VA => use for calculate letter-spacing from photoshop
============
  SCSS
  .selector {
    letter-spacing: VA(120, 14);
  }

  CSS RESULT
  .selector {
    letter-spacing: 1.68px;
  }
============
  $unit => VA value in photoshop
  $font_size => how px font-size in photoshop ||| default 16
  $return px | em ||| default px
 */
*
{
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;

    outline: none;
}

.lock
{
    overflow: hidden !important;

    -webkit-overflow-scrolling: touch;
    -ms-touch-action: none;
        touch-action: none;
}

.disable-display
{
    display: none !important;
}

body
{
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.3;

    color: #000;
    background-color: #fff;

    scrollbar-gutter: stable;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

h1,
h2,
h3,
h4,
h5,
h6
{
    margin: 0;
}

ul,
ol,
li
{
    margin: 0;
    padding: 0;

    list-style-type: none;
}

p
{
    margin: 0;
}

a
{
    text-decoration: none;

    color: black;
}

a:hover
{
    text-decoration: none;
}

audio,
canvas,
iframe,
img,
svg,
video
{
    vertical-align: middle;
}

textarea
{
    resize: none;
}

section
{
    position: relative;
}

input,
select,
button,
textarea
{
    border-radius: 0;
    outline: none !important;

    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}

button
{
    cursor: pointer;
}

*,
*::before,
*::after
{
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

.wrapper
{
    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
        flex-direction: column;

    min-height: 100vh;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}

.global-wrapp
{
    position: relative;

    width: 100%;
    padding: 38px 97px;

    background-color: #fff;
}
.global-wrapp.bg-silver
{
    background-color: #dde7ef;
}
@media (max-width: 1600px)
{
    .global-wrapp
    {
        padding: 38px 59px;
    }
}
@media (max-width: 1300px)
{
    .global-wrapp
    {
        padding: 38px 42px;
    }
}
@media (max-width: 1150px)
{
    .global-wrapp
    {
        padding: 38px 25px;
    }
}
@media (max-width: 1024px)
{
    .global-wrapp
    {
        padding: 20px 15px;
    }
}

.global-container
{
    position: relative;

    width: 100%;
    max-width: 1435px;
    margin: 0 auto;
}
@media (max-width: 1024px)
{
    .global-container
    {
        max-width: 1680px;
    }
}

.global-category__box
{
    position: relative;

    width: 100%;
    margin-bottom: 34px;
}
@media (max-width: 1024px)
{
    .global-category__box
    {
        margin-bottom: 20px;
    }
}
.global-category__box:last-child
{
    margin-bottom: 0;
}

button,
textarea,
input
{
    border: none;
    outline: none;
    background: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
}

.content
{
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
        flex: 1 0 auto;
}

.social__list
{
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;

    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.social__list li
{
    margin: 8px;
}
@media (max-width: 1300px)
{
    .social__list li
    {
        margin: 5px;
    }
}
@media (max-width: 1024px)
{
    .social__list li
    {
        margin: 7px;
    }
}

.social__item
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.social__item .icon
{
    max-width: 18px;
    max-height: 17px;

    -webkit-transition: .3s;
            transition: .3s;

    -o-object-fit: contain;
       object-fit: contain;
    fill: #b7b7b7;
}
@media (max-width: 1150px)
{
    .social__item .icon
    {
        max-width: 17px;
        max-height: 14px;
    }
}
@media (max-width: 1024px)
{
    .social__item .icon
    {
        max-width: 20px;
        max-height: 18px;
    }
}
@media (min-width: 1025px)
{
    .social__item:hover .icon
    {
        fill: #c187e9;
    }
}

.global-title__wrapp
{
    position: relative;
    z-index: 1;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: -webkit-fit-content;
    width:    -moz-fit-content;
    width:         fit-content;
    margin: 0 auto;
    margin-bottom: 46px;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.global-title__wrapp .background-circle
{
    bottom: 50%;
    left: 50%;

    width: 115px;

    -webkit-transform: translateX(-50%) translateY(50%);
            transform: translateX(-50%) translateY(50%);
    -webkit-animation: none;
            animation: none;
}
@media (max-width: 1024px)
{
    .global-title__wrapp .background-circle
    {
        width: 90px;
    }
}
.global-title__wrapp::after
{
    position: absolute;
    z-index: -1;
    top: -9px;
    left: -35px;

    width: 52px;
    height: 52px;

    content: '';
    -webkit-transition: .3s;
            transition: .3s;
    -webkit-animation: blik 2s ease infinite;
            animation: blik 2s ease infinite;
    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
    pointer-events: none;

    opacity: .5;
    border: 1px solid rgb(206, 206, 206);
    border-radius: 50%;
    background-color: #f8f8f8;

    -webkit-backdrop-filter: blur(170px);
            backdrop-filter: blur(170px);
    -webkit-filter: blur(5px);
            filter: blur(5px);
}
@media (max-width: 1024px)
{
    .global-title__wrapp::after
    {
        display: none;
    }
}
.global-title__wrapp::before
{
    position: absolute;
    z-index: -1;
    top: 35px;
    right: -39px;

    width: 52px;
    height: 52px;

    content: '';
    -webkit-transition: .3s;
            transition: .3s;
    -webkit-animation: blik 2s ease infinite;
            animation: blik 2s ease infinite;
    -webkit-animation-delay: 2.5s;
            animation-delay: 2.5s;
    pointer-events: none;

    opacity: .5;
    border: 1px solid rgb(206, 206, 206);
    border-radius: 50%;
    background-color: #f8f8f8;

    -webkit-backdrop-filter: blur(170px);
            backdrop-filter: blur(170px);
    -webkit-filter: blur(5px);
            filter: blur(5px);
}
@media (max-width: 1024px)
{
    .global-title__wrapp::before
    {
        display: none;
    }
}
@media (max-width: 1650px)
{
    .global-title__wrapp
    {
        margin-bottom: 38px;
    }
}
@media (max-width: 1024px)
{
    .global-title__wrapp
    {
        margin-bottom: 37px;
    }
}

.global-title
{
    font-family: 'Montserrat';
    font-size: 82px;
    font-weight: 900;
    font-style: normal;
    line-height: 99px;

    position: relative;
    z-index: 1;

    width: 100%;
    width: -webkit-fit-content;
    width:    -moz-fit-content;
    width:         fit-content;
    margin: 0 auto;

    text-align: center;

    background: -webkit-gradient(linear, left top, right top, color-stop(1.2%, #88bcf9), to(#b458ec));
    background: linear-gradient(90deg, #88bcf9 1.2%, #b458ec 100%);
    -webkit-background-clip: text;
            background-clip: text;

    -webkit-text-fill-color: transparent;
}
@media (max-width: 1650px)
{
    .global-title
    {
        font-size: 61px;
        line-height: 76px;
    }
}
@media (max-width: 1024px)
{
    .global-title
    {
        font-size: 48px;
        line-height: 58px;
    }
}
@media (max-width: 576px)
{
    .global-title
    {
        font-size: 38px;
        line-height: 46px;
    }
}

.global-subtitle
{
    font-family: 'Montserrat';
    font-size: 41px;
    font-weight: 600;
    font-style: normal;
    line-height: 50px;

    position: relative;
    z-index: 1;

    width: 100%;
    width: -webkit-fit-content;
    width:    -moz-fit-content;
    width:         fit-content;
    margin: 0 auto;

    text-align: center;

    background: -webkit-gradient(linear, left top, right top, color-stop(1.2%, #88bcf9), to(#b458ec));
    background: linear-gradient(90deg, #88bcf9 1.2%, #b458ec 100%);
    -webkit-background-clip: text;
            background-clip: text;

    -webkit-text-fill-color: transparent;
}
@media (max-width: 1650px)
{
    .global-subtitle
    {
        font-size: 34px;
        line-height: 42px;
    }
}
@media (max-width: 1024px)
{
    .global-subtitle
    {
        font-size: 30px;
        line-height: 40px;
    }
}
@media (max-width: 576px)
{
    .global-subtitle
    {
        font-size: 26px;
        line-height: 34px;
    }
}

.global-category_title
{
    font-family: 'Montserrat';
    font-size: 30px;
    font-weight: 300;
    font-style: normal;
    line-height: 37px;

    text-align: center;

    color: #0e8ee6;
}
@media (max-width: 1650px)
{
    .global-category_title
    {
        font-size: 25px;
        line-height: 32px;
    }
}
@media (max-width: 1024px)
{
    .global-category_title
    {
        font-size: 24px;
        line-height: 30px;
    }
}
@media (max-width: 576px)
{
    .global-category_title
    {
        font-size: 22px;
        line-height: 28px;
    }
}

.global-title + .global-subtitle
{
    margin-top: 23px;
}
@media (max-width: 1650px)
{
    .global-title + .global-subtitle
    {
        margin-top: 14px;
    }
}
@media (max-width: 1024px)
{
    .global-title + .global-subtitle
    {
        margin-top: 10px;
    }
}

.global-text
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    line-height: 17px;

    color: #000;
}
@media (max-width: 1024px)
{
    .global-text
    {
        font-size: 16px;
        line-height: 20px;
    }
}

.global-category__subtext
{
    font-family: 'Montserrat';
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    line-height: 19px;

    text-align: center;

    color: #000;
}
@media (max-width: 1024px)
{
    .global-category__subtext
    {
        font-size: 16px;
        line-height: 20px;
    }
}
.global-category__subtext b
{
    background: -webkit-gradient(linear, left top, right top, color-stop(1.2%, #88bcf9), to(#b458ec));
    background: linear-gradient(90deg, #88bcf9 1.2%, #b458ec 100%);
    -webkit-background-clip: text;
            background-clip: text;

    -webkit-text-fill-color: transparent;
}

.global-category_title + .global-category__subtext
{
    margin-top: 31px;
}
@media (max-width: 1650px)
{
    .global-category_title + .global-category__subtext
    {
        margin-top: 25px;
    }
}
@media (max-width: 1024px)
{
    .global-category_title + .global-category__subtext
    {
        margin-top: 20px;
    }
}

.global-category__notification
{
    font-family: 'Montserrat';
    font-size: 17px;
    font-weight: 700;
    font-style: normal;
    line-height: 21px;

    text-align: center;

    color: #000;
}
@media (max-width: 1650px)
{
    .global-category__notification
    {
        font-size: 15px;
        line-height: 18px;
    }
}
@media (max-width: 1024px)
{
    .global-category__notification
    {
        font-size: 16px;
        line-height: 20px;
    }
}

.global-category__subtext + .global-category__notification
{
    margin-top: 34px;
}
@media (max-width: 1650px)
{
    .global-category__subtext + .global-category__notification
    {
        margin-top: 25px;
    }
}
@media (max-width: 1024px)
{
    .global-category__subtext + .global-category__notification
    {
        margin-top: 20px;
    }
}

.global-btn
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    line-height: 150%;

    position: relative;
    z-index: 1;

    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    overflow: hidden;

    min-width: 277px;
    min-height: 51px;
    padding: 8px 17px;

    cursor: pointer;
    -webkit-transition: .3s;
            transition: .3s;
    text-align: center;

    color: #fff;
    border-radius: 4px;
    outline: none;
    background: -webkit-gradient(linear, left top, right top, color-stop(1.2%, #88bcf9), to(#b458ec));
    background: linear-gradient(90deg, #88bcf9 1.2%, #b458ec 100%);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.global-btn::after
{
    position: absolute;
    z-index: -1;
    top: 50%;
    right: 50%;

    width: 100%;
    height: 100%;

    content: '';
    -webkit-transition: .3s;
            transition: .3s;
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);

    opacity: 0;
    background: -webkit-gradient(linear, right top, left top, from(#88bcf9), to(#b458ec));
    background: linear-gradient(270deg, #88bcf9 0%, #b458ec 100%);
}
.global-btn .icon
{
    max-width: 30px;
    max-height: 30px;
    margin-left: 11px;

    -webkit-transition: .3s;
            transition: .3s;

    fill: #75aff9;
    -o-object-fit: contain;
       object-fit: contain;
}
@media (max-width: 1024px)
{
    .global-btn .icon
    {
        max-width: 36px;
        max-height: 36px;
        margin-left: 14px;
    }
}
.global-btn .icon-long-arrow
{
    width: 30px;
    height: 6px;
}
@media (max-width: 1024px)
{
    .global-btn .icon-long-arrow
    {
        width: 36px;
        height: 8px;
    }
}
@media (min-width: 1025px)
{
    .global-btn:hover
    {
        color: #fff;
    }
    .global-btn:hover .icon
    {
        fill: #fff;
    }
}
@media (min-width: 1025px)
{
    .global-btn:hover::after
    {
        -webkit-transform: translate(50%, -50%);
                transform: translate(50%, -50%);

        opacity: 1;
    }
}
.global-btn.rounded-btn
{
    border-radius: 65px;
}
@media (max-width: 1024px)
{
    .global-btn.rounded-btn
    {
        border-radius: 77px;
    }
}
@media (max-width: 1024px)
{
    .global-btn
    {
        font-size: 14px;
        line-height: 18px;

        min-height: 50px;
        padding: 7px 15px;

        border-radius: 5px;
    }
}
@media (max-width: 450px)
{
    .global-btn
    {
        min-width: 100%;
    }
}

.slider-buttons
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    margin-top: 34px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 1024px)
{
    .slider-buttons
    {
        margin-top: 20px;
    }
}

.slider-button
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 34px;
    height: 34px;
    margin-right: 17px;
    padding: 2px;

    cursor: pointer;
    -webkit-transition: .3s;
            transition: .3s;

    border-radius: 4px;
    background-color: #75aff9;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 1024px)
{
    .slider-button
    {
        width: 35px;
        height: 35px;
        margin-right: 12px;
        padding: 3px;

        border-radius: 4px;
    }
}
.slider-button:last-child
{
    margin-right: 0;
}
.slider-button .icon
{
    width: 5px;
    height: 10px;

    -webkit-transition: .3s;
            transition: .3s;

    fill: #fff;
    -o-object-fit: contain;
       object-fit: contain;
}
@media (max-width: 1024px)
{
    .slider-button .icon
    {
        width: 6px;
        height: 10px;
    }
}
.slider-button.slider-prev .icon
{
    -webkit-transform: scale(-1, 1);
            transform: scale(-1, 1);
}
@media (min-width: 1025px)
{
    .slider-button:hover
    {
        background-color: #98c5ff;
    }
}
.slider-button.swiper-button-disabled
{
    pointer-events: none;

    opacity: .6;
}

.header
{
    position: fixed;
    z-index: 12;
    top: 0;
    left: 0;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    padding: 25px 97px;

    -webkit-transition: .17s;
            transition: .17s;

    background: transparent;

    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.header.active
{
    padding: 11px 97px;

    background: #fff;
}
@media (max-width: 1600px)
{
    .header.active
    {
        padding: 11px 59px;
    }
}
@media (max-width: 1300px)
{
    .header.active
    {
        padding: 11px 42px;
    }
}
@media (max-width: 1150px)
{
    .header.active
    {
        padding: 11px 25px;
    }
}
@media (max-width: 1024px)
{
    .header.active
    {
        padding: 16px 15px;
    }
}
@media (max-width: 1600px)
{
    .header
    {
        padding: 25px 59px;
    }
}
@media (max-width: 1300px)
{
    .header
    {
        padding: 25px 42px;
    }
}
@media (max-width: 1150px)
{
    .header
    {
        padding: 25px;
    }
}
@media (max-width: 1024px)
{
    .header
    {
        padding: 16px 15px;
    }
}
.header__container
{
    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    max-width: 1435px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
@media (max-width: 1024px)
{
    .header__container
    {
        max-width: 1680px;
    }
}
.header__logo
{
    position: relative;

    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;

    min-width: 62px;
    margin-right: 17px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 1024px)
{
    .header__logo
    {
        min-width: 50px;
        margin-right: 20px;
    }
}
.header__logo img
{
    width: 62px;
    height: 78px;

    pointer-events: none;

    -o-object-fit: contain;
       object-fit: contain;
}
@media (max-width: 1024px)
{
    .header__logo img
    {
        width: 50px;
        height: 63px;
    }
}
.header__side
{
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@media (max-width: 1024px)
{
    .header__side
    {
        overflow: hidden;
        overflow-y: auto;
            flex-direction: column;

        width: 100%;
        height: 100%;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}
.header__burger
{
    position: relative;

    display: none;

    width: 30px;
    height: 30px;
    margin-left: 20px;

    cursor: pointer;
    -webkit-transition: .3s;
            transition: .3s;

    border: none;
    outline: none;
    background: transparent;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.header__burger .icon
{
    position: absolute;
    top: 50%;
    left: 50%;

    max-width: 30px;
    max-height: 30px;

    -webkit-transition: .3s;
            transition: .3s;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);

    fill: #000;
}
.header__burger .icon-close-burger
{
    visibility: hidden;

    max-height: 20px;

    opacity: 0;
}
.header__burger.active .icon-close-burger
{
    visibility: visible;

    opacity: 1;
}
.header__burger.active .icon-burger
{
    visibility: hidden;

    opacity: 0;
}
@media (max-width: 1024px)
{
    .header__burger
    {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}
.header__menu
{
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@media (max-width: 1024px)
{
    .header__menu
    {
            flex-direction: column;

        width: 100%;
        margin-bottom: 20px;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}
.header-link__option
{
    position: relative;

    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
        flex-direction: column;

    margin: 0 21px;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}
@media (max-width: 1900px)
{
    .header-link__option
    {
        margin: 0 12px;
    }
}
@media (max-width: 1450px)
{
    .header-link__option
    {
        margin: 0 8px;
    }
}
@media (max-width: 1200px)
{
    .header-link__option
    {
        margin: 0 4px;
    }
}
@media (max-width: 1024px)
{
    .header-link__option
    {
        width: 100%;
        margin: 0;
        margin-bottom: 20px;
    }
}
.header-link__option:first-child
{
    margin-left: 0;
}
.header-link__option:last-child
{
    margin-right: 0;
}
.header__link
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    line-height: 17px;

    position: relative;
    z-index: 1;

    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;

    width: -webkit-fit-content;
    width:    -moz-fit-content;
    width:         fit-content;

    -webkit-transition: .3s;
            transition: .3s;

    color: black;
    background: -webkit-gradient(linear, left top, right top, color-stop(1.2%, #88bcf9), to(#b458ec));
    background: linear-gradient(90deg, #88bcf9 1.2%, #b458ec 100%);
    -webkit-background-clip: text;
            background-clip: text;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@media (max-width: 1200px)
{
    .header__link
    {
        font-size: 11px;
        line-height: 14px;
    }
}
@media (max-width: 1024px)
{
    .header__link
    {
        font-size: 18px;
        line-height: 22px;
    }
}
.header__link .icon
{
    width: 6px;
    min-width: 5px;
    height: 4px;
    margin-left: 4px;

    -webkit-transition: .3s;
            transition: .3s;

    fill: #000;
    -o-object-fit: contain;
       object-fit: contain;
}
@media (max-width: 1024px)
{
    .header__link .icon
    {
        width: 7px;
        min-width: 6px;
        height: 4.5px;
        margin-left: 5px;
    }
}
@media (min-width: 1025px)
{
    .header__link:hover
    {
        color: rgba(0, 0, 0, 0);
    }
    .header__link:hover .icon
    {
        fill: #c187e9;
    }
}
.header__link.active
{
    color: rgba(0, 0, 0, 0) !important;
}
.header__link.active .icon
{
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);

    fill: #c187e9 !important;
}
.header-link__hidelist
{
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: -26px;

    display: none;
    overflow: hidden;
    overflow-y: auto;

    min-width: 307px;
    max-width: 307px;
    max-height: calc(var(--vh, 1vh) * 100 - 179px);
    padding: 17px 21px;

    -webkit-transform: translateY(105%);
            transform: translateY(105%);

    border-radius: 4px;
    background-color: #fff;
    -webkit-box-shadow: 0 8px 28px rgba(0, 0, 0, .05);
            box-shadow: 0 8px 28px rgba(0, 0, 0, .05);

    scrollbar-color: #000 #ebebeb;
}
@media (max-width: 1850px)
{
    .header-link__hidelist
    {
        left: -18px;

        min-width: 256px;
        max-width: 256px;
        padding: 17px;
    }
}
@media (max-width: 1200px)
{
    .header-link__hidelist
    {
        min-width: 230px;
        max-width: 230px;
        padding: 12px;
    }
}
@media (max-width: 1024px)
{
    .header-link__hidelist
    {
        position: relative;
        bottom: unset;
        left: unset;

        width: 100%;
        min-width: 100%;
        max-height: -webkit-fit-content;
        max-height:    -moz-fit-content;
        max-height:         fit-content;
        padding: 0;
        padding-top: 10px;

        -webkit-transform: none;
                transform: none;

        border-radius: 0;
        background: transparent;
        -webkit-box-shadow: none;
                box-shadow: none;
    }
}
.header-link__hidelist::-webkit-scrollbar
{
    width: 3px;

    background-color: #ebebeb;
}
.header-link__hidelist::-webkit-scrollbar-thumb
{
    width: 3px;

    border-radius: 12px;
    background-color: #000;
}
.header-link__hidelist li
{
    width: 100%;
    margin-bottom: 17px;
    padding-bottom: 17px;

    border-bottom: 1px solid #ebebeb;
}
@media (max-width: 1850px)
{
    .header-link__hidelist li
    {
        margin-bottom: 12px;
        padding-bottom: 12px;
    }
}
@media (max-width: 1200px)
{
    .header-link__hidelist li
    {
        margin-bottom: 8px;
        padding-bottom: 8px;
    }
}
@media (max-width: 1024px)
{
    .header-link__hidelist li
    {
        margin-bottom: 10px;
        padding: 0;

        border: none;
    }
}
.header-link__hidelist li:last-child
{
    margin-bottom: 0;
    padding-bottom: 0;

    border: none;
}
.header__hidelink
{
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    line-height: 14px;

    position: relative;

    -webkit-transition: .3s;
            transition: .3s;

    color: black;
    background: -webkit-gradient(linear, left top, right top, color-stop(1.2%, #88bcf9), to(#b458ec));
    background: linear-gradient(90deg, #88bcf9 1.2%, #b458ec 100%);
    -webkit-background-clip: text;
            background-clip: text;
}
.header__hidelink::after
{
    position: absolute;
    bottom: 1px;
    left: 0;

    width: 0;
    height: 1px;

    content: '';
    -webkit-transition: .3s;
            transition: .3s;

    background: -webkit-gradient(linear, left top, right top, color-stop(1.2%, #88bcf9), to(#b458ec));
    background: linear-gradient(90deg, #88bcf9 1.2%, #b458ec 100%);
}
@media (max-width: 1850px)
{
    .header__hidelink
    {
        font-size: 11px;
        line-height: 13px;
    }
}
@media (max-width: 1200px)
{
    .header__hidelink
    {
        font-size: 11px;
        line-height: 11px;
    }
}
@media (max-width: 1024px)
{
    .header__hidelink
    {
        font-size: 16px;
        line-height: 20px;
    }
}
@media (min-width: 1025px)
{
    .header__hidelink:hover
    {
        color: rgba(0, 0, 0, 0);
    }
    .header__hidelink:hover::after
    {
        width: 100%;
    }
}
.header__contacts
{
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@media (max-width: 1800px)
{
    .header__contacts
    {
            flex-direction: column;

        margin-right: 25px;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}
@media (max-width: 1600px)
{
    .header__contacts
    {
        margin-right: 17px;
    }
}
@media (max-width: 1100px)
{
    .header__contacts
    {
        margin-right: 12px;
    }
}
@media (max-width: 1024px)
{
    .header__contacts
    {
        margin-right: 0;
        margin-bottom: 40px;
    }
}
.header-contacts__wrapp
{
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;

    margin-left: 34px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@media (max-width: 1800px)
{
    .header-contacts__wrapp
    {
        margin-left: 42px;
    }
}
@media (max-width: 1400px)
{
    .header-contacts__wrapp
    {
        margin-left: 17px;
    }
}
@media (max-width: 1100px)
{
    .header-contacts__wrapp
    {
        margin-left: 12px;
    }
}
@media (max-width: 1024px)
{
    .header-contacts__wrapp
    {
            flex-direction: column;

        width: 100%;
        margin: 0;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}
.header-contact__link
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    line-height: 17px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-right: 38px;

    -webkit-transition: .3s;
            transition: .3s;

    color: #000;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@media (max-width: 1900px)
{
    .header-contact__link
    {
        margin-right: 25px;
    }
}
@media (max-width: 1800px)
{
    .header-contact__link
    {
        margin-right: 0;
        margin-bottom: 4px;
    }
    .header-contact__link:last-child
    {
        margin-bottom: 0;
    }
}
@media (max-width: 1600px)
{
    .header-contact__link
    {
        font-size: 11px;
        line-height: 15px;
    }
}
@media (max-width: 1400px)
{
    .header-contact__link
    {
        font-size: 11px;
        line-height: 14px;
    }
}
@media (max-width: 1350px)
{
    .header-contact__link
    {
        margin-bottom: 8px;
    }
    .header-contact__link .link-value
    {
        display: none;
    }
}
@media (max-width: 1024px)
{
    .header-contact__link
    {
        font-size: 14px;
        line-height: 18px;

        margin-bottom: 15px;
    }
    .header-contact__link .link-value
    {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
    }
}
.header-contact__link:last-child
{
    margin-right: 34px;
}
@media (max-width: 1900px)
{
    .header-contact__link:last-child
    {
        margin-right: 25px;
    }
}
@media (max-width: 1800px)
{
    .header-contact__link:last-child
    {
        margin-right: 0;
    }
}
.header-contact__link .link-icon
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    min-width: 17px;
    max-width: 17px;
    max-height: 17px;
    margin-right: 5px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 1400px)
{
    .header-contact__link .link-icon
    {
        min-width: 14px;
        max-width: 14px;
        max-height: 14px;
        margin-right: 4px;
    }
}
@media (max-width: 1200px)
{
    .header-contact__link .link-icon
    {
        margin-right: 0;
    }
}
@media (max-width: 1024px)
{
    .header-contact__link .link-icon
    {
        min-width: 18px;
        max-width: 18px;
        max-height: 18px;
        margin-right: 5px;
    }
}
.header-contact__link .icon
{
    max-width: 17px;
    max-height: 17px;

    -webkit-transition: .3s;
            transition: .3s;

    -o-object-fit: contain;
       object-fit: contain;
    fill: #000;
}
@media (max-width: 1400px)
{
    .header-contact__link .icon
    {
        max-width: 14px;
        max-height: 14px;
    }
}
@media (max-width: 1024px)
{
    .header-contact__link .icon
    {
        max-width: 18px;
        max-height: 18px;
    }
}
@media (min-width: 1025px)
{
    .header-contact__link:hover
    {
        color: #75aff9;
    }
    .header-contact__link:hover .icon
    {
        fill: #75aff9;
    }
}
.header-hide__contact
{
    display: none !important;
}
@media (max-width: 1024px)
{
    .header-hide__contact
    {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;

        margin-bottom: 0;
    }
    .header-hide__contact .icon
    {
        max-width: 16px;
        max-height: 16px;
    }
}
.header__btn
{
    min-width: 209px;
}
@media (max-width: 1500px)
{
    .header__btn
    {
        min-width: 187px;
    }
}
@media (max-width: 1100px)
{
    .header__btn
    {
        min-width: 170px;
    }
}
@media (max-width: 1024px)
{
    .header__btn
    {
        min-width: 260px;
    }
}
@media (max-width: 450px)
{
    .header__btn
    {
        min-width: 100%;
    }
}
.header .select-info
{
    margin-right: 17px;
}
@media (max-width: 1900px)
{
    .header .select-info
    {
        margin-right: 12px;
    }
}
@media (max-width: 1400px)
{
    .header .select-info
    {
        margin-right: 8px;
    }
}
@media (max-width: 1024px)
{
    .header .select-info
    {
        margin-right: 0;
        margin-bottom: 40px;
    }
}
.header .select-info.active .select-arrow .icon
{
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
.header .select-info.active .select__trigger
{
    border-color: transparent;
}
.header .select-info.active .select__trigger::after
{
    opacity: 1;
}
.header .select-info.active .select__trigger .select-arrow .icon
{
    fill: #fff;
}
.header .select-info.active .select__trigger .select__value
{
    color: #fff;
}
.header .select-info .select__dropdown
{
    position: absolute;
    z-index: 10;

    display: none;

    width: 100%;
    max-width: 63px;
    margin-top: 8px;
    padding: 8px 4px;

    border: 1px solid #000;
    border-radius: 4px;
    background: transparent;
    background: transparent;
    background-color: #fff;
}
@media (max-width: 1600px)
{
    .header .select-info .select__dropdown
    {
        min-width: 55px;
        max-width: 55px;
    }
}
@media (max-width: 1200px)
{
    .header .select-info .select__dropdown
    {
        min-width: 46px;
        max-width: 46px;
    }
}
@media (max-width: 1024px)
{
    .header .select-info .select__dropdown
    {
        position: relative;

        min-width: 65px;
        max-width: 65px;
        margin-top: 5px;

        border-radius: 5px;
    }
}
.header .select-info .select__trigger
{
    position: relative;
    z-index: 1;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;

    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    min-width: 63px;
    max-width: 63px;
    min-height: 51px;
    padding: 8px 4px;

    cursor: pointer;
    -webkit-transition: .3s;
            transition: .3s;

    border: 1px solid #000;
    border-radius: 4px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 1600px)
{
    .header .select-info .select__trigger
    {
        min-width: 55px;
        max-width: 55px;
    }
}
@media (max-width: 1200px)
{
    .header .select-info .select__trigger
    {
        min-width: 46px;
        max-width: 46px;
    }
}
@media (max-width: 1024px)
{
    .header .select-info .select__trigger
    {
        min-width: 65px;
        max-width: 65px;

        border-radius: 5px;
    }
}
.header .select-info .select__trigger::after
{
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    content: '';
    -webkit-transition: .3s;
            transition: .3s;

    opacity: 0;
    background: -webkit-gradient(linear, left top, right top, color-stop(1.2%, #88bcf9), to(#b458ec));
    background: linear-gradient(90deg, #88bcf9 1.2%, #b458ec 100%);
}
@media (min-width: 1025px)
{
    .header .select-info .select__trigger:hover
    {
        border-color: transparent;
    }
    .header .select-info .select__trigger:hover::after
    {
        opacity: 1;
    }
    .header .select-info .select__trigger:hover .select-arrow .icon
    {
        fill: #fff;
    }
    .header .select-info .select__trigger:hover .select__value
    {
        color: #fff;
    }
}
@media (max-width: 1024px)
{
    .header .select-info .select__trigger
    {
        min-height: 50px;
    }
}
.header .select-info .select-arrow
{
    z-index: 1;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 6px;
    height: 4px;
    margin-left: 4px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 1024px)
{
    .header .select-info .select-arrow
    {
        width: 7px;
        height: 4.5px;
        margin-left: 5px;
    }
}
.header .select-info .select-arrow .icon
{
    width: 6px;
    height: 4px;

    -webkit-transition: .3s;
            transition: .3s;

    fill: #000;
}
@media (max-width: 1024px)
{
    .header .select-info .select-arrow .icon
    {
        width: 7px;
        height: 4.5px;
    }
}
.header .select-info .select__value
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    line-height: 150%;

    z-index: 1;

    display: -webkit-box;
    overflow: hidden;

    -webkit-transition: .3s;
            transition: .3s;

    color: #000;

    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
@media (max-width: 1600px)
{
    .header .select-info .select__value
    {
        font-size: 11px;
        line-height: 15px;
    }
}
@media (max-width: 1200px)
{
    .header .select-info .select__value
    {
        font-size: 11px;
        line-height: 13px;
    }
}
@media (max-width: 1024px)
{
    .header .select-info .select__value
    {
        font-size: 14px;
        line-height: 18px;
    }
}
.header .select-info .select__option
{
    width: 100%;
    margin-bottom: 8px;
    padding: 0;

    cursor: pointer;
}
@media (max-width: 1024px)
{
    .header .select-info .select__option
    {
        margin-bottom: 10px;
    }
}
.header .select-info .select__option:last-child
{
    margin-bottom: 0;
}
.header .select-info .select__option:last-child
{
    border: none;
}
.header .select-info .select__option.active .header-lang__link
{
    background: linear-gradient(89.91deg, #11bbf0 -7.12%, #d83eff 112.36%);
    -webkit-background-clip: text;
            background-clip: text;

    -webkit-text-fill-color: transparent;
}
.header .select-info .select__option:hover .select__option-info
{
    color: #c187e9;
}
.header .select-info .header-lang__link
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    line-height: 150%;

    display: block;

    width: 100%;

    -webkit-transition: .3s;
            transition: .3s;
    text-align: center;

    color: #000;
}
@media (max-width: 1600px)
{
    .header .select-info .header-lang__link
    {
        font-size: 11px;
        line-height: 15px;
    }
}
@media (max-width: 1200px)
{
    .header .select-info .header-lang__link
    {
        font-size: 11px;
        line-height: 14px;
    }
}
@media (max-width: 1024px)
{
    .header .select-info .header-lang__link
    {
        font-size: 14px;
        line-height: 18px;
    }
}
@media (min-width: 1025px)
{
    .header .select-info .header-lang__link:hover
    {
        color: #75aff9;
    }
}
@media (max-width: 1024px)
{
    .header-mobile__menu
    {
        position: fixed;
        z-index: 12;
        top: 95px;
        left: 0;

        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        overflow: hidden;
            flex-direction: column;

        width: 100%;
        height: calc(var(--vh, 1vh) * 100 - 95px);
        padding: 25px 15px;

        -webkit-transition: .4s;
                transition: .4s;
        -webkit-transition-delay: .15s;
                transition-delay: .15s;
        -webkit-transform: translateX(110%);
                transform: translateX(110%);

        border-top: 1px solid #ebebeb;
        background-color: #fff;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
    .header-mobile__menu.active
    {
        -webkit-transition-delay: 0;
                transition-delay: 0;
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

.overlay
{
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;

    visibility: hidden;

    width: 100%;
    height: 100vh;

    -webkit-transition: .3s;
            transition: .3s;
    pointer-events: none;

    opacity: 0;
    background-color: rgba(255, 255, 255, .6);

    -webkit-filter: blur(2px);
            filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
}
.overlay.active
{
    visibility: visible;

    opacity: 1;
}

.index-english-page .header__lang
{
    display: none !important;
}

.footer
{
    width: 100%;
    padding: 17px 97px;

    background-color: #fff;
}
@media (max-width: 1600px)
{
    .footer
    {
        padding: 17px 59px;
    }
}
@media (max-width: 1300px)
{
    .footer
    {
        padding: 17px 42px;
    }
}
@media (max-width: 1150px)
{
    .footer
    {
        padding: 17px 25px;
    }
}
@media (max-width: 1024px)
{
    .footer
    {
        padding: 20px;
    }
}
.footer__content
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
@media (max-width: 1024px)
{
    .footer__content
    {
            flex-direction: column;

        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
}
.footer__copyright
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    line-height: 17px;

    color: #b7b7b7;
}
@media (max-width: 1650px)
{
    .footer__copyright
    {
        font-size: 11px;
        line-height: 15px;
    }
}
@media (max-width: 1024px)
{
    .footer__copyright
    {
        font-size: 14px;
        line-height: 18px;

        width: 100%;
        margin: 0;

        text-align: center;
    }
}
.footer__content-column
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: 33.3333333333%;
    padding: 0 8px;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.footer__content-column:nth-child(3n)
{
    padding-right: 0;

    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}
.footer__content-column:nth-child(3n-2)
{
    padding-left: 0;

    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}
@media (max-width: 1024px)
{
    .footer__content-column
    {
        width: 100%;
        margin-bottom: 20px;
        padding: 0 !important;

        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }
    .footer__content-column:last-child
    {
        margin-bottom: 0;
    }
}
.footer .footer__link
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    line-height: 17px;

    -webkit-transition: .3s;
            transition: .3s;

    color: #b7b7b7;

    text-decoration-line: underline;
}
.footer .footer__link.p-none
{
    pointer-events: none !important;
}
@media (min-width: 1025px)
{
    .footer .footer__link:hover
    {
        color: #c187e9;
    }
}
@media (max-width: 1650px)
{
    .footer .footer__link
    {
        font-size: 11px;
        line-height: 15px;
    }
}
@media (max-width: 1024px)
{
    .footer .footer__link
    {
        font-size: 14px;
        line-height: 18px;
    }
}

@-webkit-keyframes blik-scale
{
    0%
    {
        -webkit-transform: scale(1);
                transform: scale(1);

        opacity: 1;
    }
    50%
    {
        -webkit-transform: scale(.92);
                transform: scale(.92);

        opacity: 0;
    }
    100%
    {
        -webkit-transform: scale(1);
                transform: scale(1);

        opacity: 1;
    }
}

@keyframes blik-scale
{
    0%
    {
        -webkit-transform: scale(1);
                transform: scale(1);

        opacity: 1;
    }
    50%
    {
        -webkit-transform: scale(.92);
                transform: scale(.92);

        opacity: 0;
    }
    100%
    {
        -webkit-transform: scale(1);
                transform: scale(1);

        opacity: 1;
    }
}
@-webkit-keyframes blik
{
    0%
    {
        opacity: .5;
    }
    50%
    {
        opacity: 0;
    }
    100%
    {
        opacity: .5;
    }
}
@keyframes blik
{
    0%
    {
        opacity: .5;
    }
    50%
    {
        opacity: 0;
    }
    100%
    {
        opacity: .5;
    }
}
@-webkit-keyframes blik-white
{
    0%
    {
        -webkit-transform: translateX(-50%) scale(1);
                transform: translateX(-50%) scale(1);

        opacity: 1;
    }
    50%
    {
        -webkit-transform: translateX(-50%) scale(.92);
                transform: translateX(-50%) scale(.92);

        opacity: 0;
    }
    100%
    {
        -webkit-transform: translateX(-50%) scale(1);
                transform: translateX(-50%) scale(1);

        opacity: 1;
    }
}
@keyframes blik-white
{
    0%
    {
        -webkit-transform: translateX(-50%) scale(1);
                transform: translateX(-50%) scale(1);

        opacity: 1;
    }
    50%
    {
        -webkit-transform: translateX(-50%) scale(.92);
                transform: translateX(-50%) scale(.92);

        opacity: 0;
    }
    100%
    {
        -webkit-transform: translateX(-50%) scale(1);
                transform: translateX(-50%) scale(1);

        opacity: 1;
    }
}
.background-circle
{
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;

    width: 153px;
    height: auto;

    -webkit-transition: .3s;
            transition: .3s;
    -webkit-animation: blik-scale 2s ease infinite;
            animation: blik-scale 2s ease infinite;
    pointer-events: none;

    -o-object-fit: contain;
       object-fit: contain;
}
@media (max-width: 1650px)
{
    .background-circle
    {
        width: 128px;
    }
}
@media (max-width: 1024px)
{
    .background-circle
    {
        width: 120px;
    }
}
@media (max-width: 576px)
{
    .background-circle
    {
        width: 65px;
    }
}
.background-circle.big-circle
{
    width: 286px;
}
@media (max-width: 1650px)
{
    .background-circle.big-circle
    {
        width: 230px;
    }
}
@media (max-width: 1024px)
{
    .background-circle.big-circle
    {
        width: 200px;
    }
}
@media (max-width: 576px)
{
    .background-circle.big-circle
    {
        width: 150px;
    }
}

.blur-circle
{
    position: absolute;
    top: 0;
    left: 0;

    width: 52px;
    height: 52px;

    -webkit-transition: .3s;
            transition: .3s;
    -webkit-animation: blik 4s ease infinite;
            animation: blik 4s ease infinite;
    pointer-events: none;

    opacity: .5;
    border: 1px solid rgb(206, 206, 206);
    border-radius: 50%;
    background-color: #f8f8f8;

    -webkit-backdrop-filter: blur(170px);
            backdrop-filter: blur(170px);
    -webkit-filter: blur(5px);
            filter: blur(5px);
}
@media (max-width: 1024px)
{
    .blur-circle
    {
        display: none;
    }
}

.form-items__list
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: calc(100% + 25px);
    margin-right: -13px;
    margin-left: -13px;

    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
@media (max-width: 1024px)
{
    .form-items__list
    {
        width: calc(100% + 20px);
        margin-right: -10px;
        margin-left: -10px;
    }
}
.form-field
{
    position: relative;

    width: 50%;
    margin-bottom: 34px;
    padding: 0 12px;
}
@media (max-width: 1650px)
{
    .form-field
    {
        margin-bottom: 25px;
    }
}
@media (max-width: 1024px)
{
    .form-field
    {
        margin-bottom: 20px;
        padding: 0 10px;
    }
}
@media (max-width: 576px)
{
    .form-field
    {
        width: 100% !important;
    }
}
.form-field.full-field
{
    width: 100%;
}
.form-field.textarea-field .form__field-input::after
{
    display: none;
}
.form-field .select-info.active .icon-trigger
{
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
.form-field .select-info.active + .form__field__line::after
{
    width: 100%;
}
.form-field .select-info .select-info
{
    position: relative;

    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;

    -webkit-transition: .3s;
            transition: .3s;

    background: transparent;
}
.form-field .select-info .select__list
{
    overflow: hidden;
    overflow-y: auto;

    width: 100%;
    max-height: 69px;
    padding-right: 12px;

    scrollbar-color: #000 #fff;
    scrollbar-width: thin;
}
@media (max-width: 1024px)
{
    .form-field .select-info .select__list
    {
        max-height: 72px;
        padding-right: 7px;
    }
}
.form-field .select-info .select__list::-webkit-scrollbar
{
    width: 2px;

    background-color: #fff;
}
.form-field .select-info .select__list::-webkit-scrollbar-thumb
{
    width: 2px;

    background-color: #000;
}
.form-field .select-info .select__dropdown
{
    position: absolute;
    z-index: 10;

    display: none;

    width: 100%;
    margin-top: 0;
    padding: 8px;

    border-radius: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background: transparent;
    background: linear-gradient(224.78deg, #538cfb 3.56%, #ee9dfb 96.77%);
}
@media (max-width: 1024px)
{
    .form-field .select-info .select__dropdown
    {
        padding: 10px;

        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }
}
.form-field .select-info .select__trigger
{
    position: relative;
    z-index: 1;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    padding-right: 14px;
    padding-bottom: 8px;

    cursor: pointer;
    -webkit-transition: .3s;
            transition: .3s;

    border-radius: 85px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
@media (max-width: 1024px)
{
    .form-field .select-info .select__trigger
    {
        padding-right: 17px;
        padding-bottom: 10px;

        border-radius: 100px;
    }
}
.form-field .select-info .select-arrow
{
    position: absolute;
    top: 50%;
    right: 0;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 10px;
    height: 6px;

    -webkit-transform: translateY(-70%);
            transform: translateY(-70%);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 1024px)
{
    .form-field .select-info .select-arrow
    {
        width: 10px;
        height: 6px;
    }
}
.form-field .select-info .select-arrow .icon
{
    width: 10px;
    height: 6px;

    -webkit-transition: .3s;
            transition: .3s;

    fill: #707070;
}
@media (max-width: 1024px)
{
    .form-field .select-info .select-arrow .icon
    {
        width: 10px;
        height: 6px;
    }
}
.form-field .select-info .select__value
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    line-height: 17px;

    display: -webkit-box;
    overflow: hidden;

    -webkit-transition: .3s;
            transition: .3s;

    color: #707070;

    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
@media (max-width: 1024px)
{
    .form-field .select-info .select__value
    {
        font-size: 14px;
        line-height: 18px;
    }
}
.form-field .select-info .select__option
{
    width: -webkit-fit-content;
    width:    -moz-fit-content;
    width:         fit-content;
    margin-bottom: 8px;

    cursor: pointer;
}
@media (max-width: 1024px)
{
    .form-field .select-info .select__option
    {
        margin-bottom: 10px;
    }
}
.form-field .select-info .select__option:last-child
{
    margin-bottom: 0;
}
.form-field .select-info .select__option.active .select__option-info
{
    color: #000;
}
@media (min-width: 1025px)
{
    .form-field .select-info .select__option:hover .select__option-info
    {
        color: #000;
    }
}
.form-field .select-info .select__option-info
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    line-height: 17px;

    width: -webkit-fit-content;
    width:    -moz-fit-content;
    width:         fit-content;

    -webkit-transition: .3s;
            transition: .3s;

    color: #fff;
}
@media (max-width: 1024px)
{
    .form-field .select-info .select__option-info
    {
        font-size: 14px;
        line-height: 18px;
    }
}
.form-field .select-info .select__option.active
{
    max-width: 100%;

    -webkit-box-shadow: none;
            box-shadow: none;
}
.form__field-name
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    line-height: 17px;

    margin-bottom: 8px;

    color: #707070;
}
@media (max-width: 1024px)
{
    .form__field-name
    {
        font-size: 14px;
        line-height: 18px;

        margin-bottom: 8px;
    }
}
.form__field-input
{
    position: relative;

    width: 100%;
}
.form__field-input::after
{
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 1px;

    content: '';
    -webkit-transition: .3s;
            transition: .3s;

    border-radius: 13px;
    background-color: #ededed;
}
@media (max-width: 1024px)
{
    .form__field-input::after
    {
        height: 2px;

        border-radius: 16px;
    }
}
.form__field__line
{
    position: relative;

    width: 100%;
}
.form__field__line::after
{
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;

    width: 0;
    height: 2px;

    content: '';
    -webkit-transition: .3s;
            transition: .3s;

    border-radius: 16px;
    background: linear-gradient(224.78deg, #538cfb 3.56%, #ee9dfb 96.77%);
}
.form__input
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    line-height: 17px;

    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    padding-bottom: 8px;

    -webkit-transition: .3s;
            transition: .3s;

    color: #707070;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@media (max-width: 1024px)
{
    .form__input
    {
        font-size: 14px;
        line-height: 18px;

        padding-bottom: 10px;
    }
}
.form__input::-webkit-input-placeholder
{
    font-family: 'Montserrat';
    font-weight: 400;
    font-style: normal;

    -webkit-transition: .3s;
            transition: .3s;

    color: #707070;
}
.form__input::-moz-placeholder
{
    font-family: 'Montserrat';
    font-weight: 400;
    font-style: normal;

    -moz-transition: .3s;
         transition: .3s;

    color: #707070;
}
.form__input:-ms-input-placeholder
{
    font-family: 'Montserrat';
    font-weight: 400;
    font-style: normal;

    -ms-transition: .3s;
        transition: .3s;

    color: #707070;
}
.form__input::-ms-input-placeholder
{
    font-family: 'Montserrat';
    font-weight: 400;
    font-style: normal;

    -ms-transition: .3s;
        transition: .3s;

    color: #707070;
}
.form__input::placeholder
{
    font-family: 'Montserrat';
    font-weight: 400;
    font-style: normal;

    -webkit-transition: .3s;
            transition: .3s;

    color: #707070;
}
.form__input:focus + .form__field__line::after
{
    width: 100%;
}
.form__input:focus::-webkit-input-placeholder
{
    color: #c187e9;
}
.form__input:focus::-moz-placeholder
{
    color: #c187e9;
}
.form__input:focus:-ms-input-placeholder
{
    color: #c187e9;
}
.form__input:focus::-ms-input-placeholder
{
    color: #c187e9;
}
.form__input:focus::placeholder
{
    color: #c187e9;
}
.form__textarea
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    line-height: 17px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    min-height: 100px;
    padding: 8px;

    -webkit-transition: .3s;
            transition: .3s;

    color: #707070;
    border: 2px solid #ededed;
    border-radius: 3px;
}
@media (max-width: 1024px)
{
    .form__textarea
    {
        font-size: 14px;
        line-height: 18px;

        min-height: 60px;
        padding: 10px;
    }
}
.form__textarea:focus
{
    border-image-source: linear-gradient(224.78deg, #538cfb 3.56%, #ee9dfb 96.77%);
    border-image-slice: 1;
}
.form__checkbox
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    margin-bottom: 17px;

    cursor: pointer;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
@media (max-width: 1024px)
{
    .form__checkbox
    {
        margin-bottom: 20px;
    }
}
.form__checkbox input
{
    display: none;
}
.form__checkbox input:checked + .form__checkbox-retangle
{
    border-color: #75aff9;
}
.form__checkbox input:checked + .form__checkbox-retangle .icon
{
    opacity: 1;
}
.form__checkbox:hover .form__checkbox-retangle
{
    border-color: #75aff9;
}
.form__checkbox:hover .form__checkbox-text
{
    color: #75aff9;
}
.form__checkbox-retangle
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    min-width: 17px;
    max-width: 17px;
    min-height: 17px;
    max-height: 17px;
    margin-right: 10px;

    -webkit-transition: .3s;
            transition: .3s;

    border: 1px solid #e0e0e0;
    border-radius: 4px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 1024px)
{
    .form__checkbox-retangle
    {
        min-width: 20px;
        max-width: 20px;
        min-height: 20px;
        max-height: 20px;
        margin-right: 12px;

        border-radius: 5px;
    }
}
.form__checkbox-retangle .icon
{
    width: 9px;
    height: 7px;

    -webkit-transition: .3s;
            transition: .3s;

    opacity: 0;

    -o-object-fit: contain;
       object-fit: contain;
    fill: #75aff9;
}
@media (max-width: 1024px)
{
    .form__checkbox-retangle .icon
    {
        width: 11px;
        height: 9px;
    }
}
.form__checkbox-text
{
    font-family: 'Montserrat';
    font-size: 10px;
    font-weight: 400;
    font-style: normal;
    line-height: 100%;

    -webkit-transition: .3s;
            transition: .3s;

    color: #656565;
}
@media (max-width: 1024px)
{
    .form__checkbox-text
    {
        font-size: 12px;
    }
}

.form-item.error .form__input,
.form-item.error__email .form__input
{
    color: #fc6e6e !important;
    border-color: #fc6e6e !important;
}
.form-item.error .form__input::-webkit-input-placeholder,
.form-item.error__email .form__input::-webkit-input-placeholder
{
    color: #fc6e6e !important;
}
.form-item.error .form__input::-moz-placeholder,
.form-item.error__email .form__input::-moz-placeholder
{
    color: #fc6e6e !important;
}
.form-item.error .form__input:-ms-input-placeholder,
.form-item.error__email .form__input:-ms-input-placeholder
{
    color: #fc6e6e !important;
}
.form-item.error .form__input::-ms-input-placeholder,
.form-item.error__email .form__input::-ms-input-placeholder
{
    color: #fc6e6e !important;
}
.form-item.error .form__input::placeholder,
.form-item.error__email .form__input::placeholder
{
    color: #fc6e6e !important;
}
.form-item.error .form__field-require,
.form-item.error__email .form__field-require
{
    visibility: visible;

    opacity: 1;
}
.form-item.error .form__checkbox-retangle,
.form-item.error__email .form__checkbox-retangle
{
    border-color: #fc6e6e !important;
}
.form-item.error .form__checkbox-text,
.form-item.error__email .form__checkbox-text
{
    color: #fc6e6e !important;
}
.form-item.error .form__field-input::after,
.form-item.error__email .form__field-input::after
{
    background-color: #fc6e6e !important;
}
.form-item.error textarea,
.form-item.error__email textarea
{
    border-color: #fc6e6e !important;
}
.form-item.error .select__value,
.form-item.error__email .select__value
{
    color: #fc6e6e !important;
}

.iti
{
    width: 100% !important;
}

.iti__selected-flag
{
    height: calc(100% - 8px);
    margin-bottom: 8px;
}
@media (max-width: 1024px)
{
    .iti__selected-flag
    {
        height: calc(100% - 10px);
        margin-bottom: 10px;
    }
}

.iti__country-list
{
    max-width: 384px;
    padding: 0;
}
@media (max-width: 1024px)
{
    .iti__country-list
    {
        min-width: 260px;
        max-width: calc(100% - 27px);
        margin-left: -17px;
    }
}

.iti__divider
{
    margin: 0;
    padding: 0;
}

.global-fixed-socials
{
    position: fixed;
    z-index: 11;
    top: 0;
    right: 34px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    height: 100vh;
    padding: 85px 0;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 1600px)
{
    .global-fixed-socials
    {
        right: 17px;
    }
}
@media (max-width: 1300px)
{
    .global-fixed-socials
    {
        right: 8px;
    }
}

.fixed-socials__list
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}
@media (max-width: 1150px)
{
    .fixed-socials__list
    {
        display: none;
    }
}
.fixed-socials__list li
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-bottom: 29px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.fixed-socials__list li:last-child
{
    margin-bottom: 0;
}
@media (min-width: 1025px) and (max-height: 750px)
{
    .fixed-socials__list li
    {
        margin-bottom: 25px;
    }
}
@media (min-width: 1025px) and (max-height: 700px)
{
    .fixed-socials__list li
    {
        margin-bottom: 21px;
    }
}

.fixed-social__link
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.fixed-social__link .icon
{
    max-width: 18px;
    max-height: 17px;

    -webkit-transition: .3s;
            transition: .3s;

    -o-object-fit: contain;
       object-fit: contain;
    fill: #383838;
}
@media (min-width: 1025px)
{
    .fixed-social__link:hover .icon
    {
        fill: #c187e9;
    }
}

.back-to-top
{
    position: fixed;
    right: 34px;
    bottom: 30px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 38px;
    height: 38px;

    -webkit-transition: .3s;
            transition: .3s;
    -webkit-transform: translateX(25%);
            transform: translateX(25%);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 1600px)
{
    .back-to-top
    {
        right: 17px;

        width: 34px;
        height: 34px;
    }
}
@media (max-width: 1300px)
{
    .back-to-top
    {
        right: 8px;

        -webkit-transform: translateX(4px);
                transform: translateX(4px);
    }
}
@media (max-width: 1150px)
{
    .back-to-top
    {
        right: 25px;

        width: 38px;
        height: 38px;

        -webkit-transform: translateX(0);
                transform: translateX(0);

        border-radius: 50%;
        background-color: #fff;
    }
}
@media (max-width: 1024px)
{
    .back-to-top
    {
        right: 15px;
        bottom: 15px;
    }
}
@media (min-width: 1025px)
{
    .back-to-top:hover
    {
        -webkit-transform: translateY(-3px) translateX(0);
                transform: translateY(-3px) translateX(0);
    }
}
@media (min-width: 1151px)
{
    .back-to-top:hover
    {
        -webkit-transform: translateY(-3px) translateX(4px);
                transform: translateY(-3px) translateX(4px);
    }
}
@media (min-width: 1301px)
{
    .back-to-top:hover
    {
        -webkit-transform: translateY(-3px) translateX(25%);
                transform: translateY(-3px) translateX(25%);
    }
}
.back-to-top img
{
    width: 100%;

    pointer-events: none;

    -o-object-fit: contain;
       object-fit: contain;
}

.product-option__container
{
    position: relative;

    width: 100%;
}
.product-option__box
{
    position: relative;

    width: 100%;
    margin-bottom: 29px;
}
@media (max-width: 1650px)
{
    .product-option__box
    {
        margin-bottom: 21px;
    }
}
@media (max-width: 1024px)
{
    .product-option__box
    {
        margin-bottom: 10px;
    }
}
.product-option__box:last-child
{
    margin-bottom: 0;
}
.product-option__box .global-title__wrapp::after
{
    display: none;
}
.product-option__box .global-title__wrapp::before
{
    display: none;
}
.product-option__list
{
    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: calc(100% + 85px);
    margin-right: -43px;
    margin-left: -43px;

    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
@media (max-width: 1800px)
{
    .product-option__list
    {
        width: calc(100% + 51px);
        margin-right: -26px;
        margin-left: -26px;
    }
}
@media (max-width: 1650px)
{
    .product-option__list
    {
        width: calc(100% + 34px);
        margin-right: -18px;
        margin-left: -18px;
    }
}
@media (max-width: 1024px)
{
    .product-option__list
    {
        width: calc(100% + 20px);
        margin-right: -10px;
        margin-left: -10px;
    }
}
@media (max-width: 700px)
{
    .product-option__list
    {
        width: 100%;
        margin: 0;
    }
}
.product-option__item
{
    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: 50%;
    margin-bottom: 68px;
    padding: 0 42px;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}
@media (max-width: 1800px)
{
    .product-option__item
    {
        margin-bottom: 51px;
        padding: 0 25px;
    }
}
@media (max-width: 1650px)
{
    .product-option__item
    {
        margin-bottom: 34px;
        padding: 0 17px;
    }
}
@media (max-width: 1024px)
{
    .product-option__item
    {
        margin-bottom: 40px;
        padding: 0 10px;
    }
}
@media (max-width: 700px)
{
    .product-option__item
    {
        width: 100%;
        padding: 0;
    }
}
.product-option__photo
{
    position: relative;
    z-index: 1;

    width: 100%;
    margin-bottom: 25px;
}
@media (max-width: 1650px)
{
    .product-option__photo
    {
        margin-bottom: 17px;
    }
}
@media (max-width: 1024px)
{
    .product-option__photo
    {
        margin-bottom: 15px;
    }
}
.product-option__photo img
{
    position: relative;

    width: 100%;

    pointer-events: none;

    -o-object-fit: contain;
       object-fit: contain;
}
.product-option__info
{
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 17px;

    position: relative;
    z-index: 1;

    width: 100%;
    margin-bottom: 29px;

    color: #000;
}
.product-option__info h5
{
    font-family: 'Montserrat';
    font-size: 32px;
    font-weight: 900;
    font-style: normal;
    line-height: 40px;

    margin-bottom: 12px;

    background: linear-gradient(244.41deg, #c944d0 -20.74%, #2fcbf9 112.1%);
    -webkit-background-clip: text;
            background-clip: text;

    -webkit-text-fill-color: transparent;
}
@media (max-width: 1800px)
{
    .product-option__info h5
    {
        font-size: 27px;
        line-height: 35px;
    }
}
@media (max-width: 1650px)
{
    .product-option__info h5
    {
        font-size: 23px;
        line-height: 30px;
    }
}
@media (max-width: 1024px)
{
    .product-option__info h5
    {
        font-size: 20px;
        line-height: 26px;

        margin-bottom: 12px;
    }
}
.product-option__info p
{
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 17px;

    color: #000;
}
@media (max-width: 1024px)
{
    .product-option__info p
    {
        font-size: 15px;
        line-height: 18px;
    }
}
.product-option__info b
{
    font-weight: 700;

    background: linear-gradient(244.41deg, #c944d0 -20.74%, #2fcbf9 112.1%);
    -webkit-background-clip: text;
            background-clip: text;

    -webkit-text-fill-color: transparent;
}
.product-option__info ul
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-top: 10px;

    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
@media (max-width: 1024px)
{
    .product-option__info ul
    {
        margin-top: 12px;
    }
}
.product-option__info ul li
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 700;
    font-style: normal;
    line-height: 17px;

    position: relative;

    width: 50%;
    margin-bottom: 8px;
    padding-right: 4px;
    padding-left: 23px;

    background: linear-gradient(244.41deg, #c944d0 -20.74%, #2fcbf9 112.1%);
    -webkit-background-clip: text;
            background-clip: text;

    -webkit-text-fill-color: transparent;
}
@media (max-width: 1300px)
{
    .product-option__info ul li
    {
        width: 100%;
        padding: 0;
        padding-left: 23px;
    }
}
.product-option__info ul li::before
{
    position: absolute;
    top: 1px;
    left: 0;

    width: 16px;
    height: 12px;

    content: '';

    background: url('../svg/done.svg') no-repeat;
    background-size: contain;
}
@media (max-width: 1024px)
{
    .product-option__info ul li::before
    {
        width: 19px;
        height: 15px;
    }
}
.product-option__info ul li:nth-child(2n)
{
    padding-right: 0;
    padding-left: 32px;
}
@media (max-width: 1300px)
{
    .product-option__info ul li:nth-child(2n)
    {
        padding-left: 27px;
    }
}
.product-option__info ul li:nth-child(2n)::before
{
    left: 5px;
}
@media (max-width: 1300px)
{
    .product-option__info ul li:nth-child(2n)::before
    {
        left: 0;
    }
}
@media (max-width: 1300px)
{
    .product-option__info ul li:last-child
    {
        margin-bottom: 0;
    }
}
@media (max-width: 1024px)
{
    .product-option__info ul li
    {
        font-size: 16px;
        line-height: 20px;

        margin-bottom: 10px;
        padding-left: 27px;
    }
}
@media (max-width: 1800px)
{
    .product-option__info
    {
        font-size: 13px;
        line-height: 17px;
    }
}
@media (max-width: 1650px)
{
    .product-option__info
    {
        margin-bottom: 21px;
    }
}
@media (max-width: 1024px)
{
    .product-option__info
    {
        font-size: 16px;
        line-height: 20px;

        margin-bottom: 15px;
    }
}
.product-option__bottom
{
    position: relative;
    z-index: 1;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    margin-top: auto;

    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@media (max-width: 1300px)
{
    .product-option__bottom
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
}
.product-option__bottombox
{
    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;

    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
@media (min-width: 1301px)
{
    .product-option__bottombox .product-option__side
    {
        width: 100%;
    }
}
@media (min-width: 1301px)
{
    .product-option__bottombox .product-option__btn
    {
        max-width: 100%;
        height: -webkit-fit-content;
        height:    -moz-fit-content;
        height:         fit-content;
    }
}
.product-option__bottombox .product-option__duration
{
    font-size: 13px;
    line-height: 17px;
}
.product-option__bottombox .product-option__duration b
{
    font-size: 23px;
    line-height: 29px;
}
@media (max-width: 1800px)
{
    .product-option__bottombox .product-option__duration b
    {
        font-size: 20px;
        line-height: 23px;
    }
}
@media (max-width: 1450px)
{
    .product-option__bottombox .product-option__duration b
    {
        font-size: 18px;
        line-height: 22px;
    }
}
@media (max-width: 1350px)
{
    .product-option__bottombox .product-option__duration b
    {
        font-size: 17px;
        line-height: 20px;
    }
}
@media (max-width: 1300px)
{
    .product-option__bottombox .product-option__duration b
    {
        font-size: 22px;
        line-height: 29px;
    }
}
@media (max-width: 1024px)
{
    .product-option__bottombox .product-option__duration b
    {
        font-size: 20px;
        line-height: 26px;
    }
}
@media (max-width: 1800px)
{
    .product-option__bottombox .product-option__duration
    {
        font-size: 11px;
        line-height: 15px;
    }
}
@media (max-width: 1450px)
{
    .product-option__bottombox .product-option__duration
    {
        font-size: 11px;
        line-height: 13px;
    }
}
@media (max-width: 1300px)
{
    .product-option__bottombox .product-option__duration
    {
        font-size: 13px;
        line-height: 17px;

        margin-right: 8px;
        padding-right: 8px;
    }
}
@media (max-width: 1024px)
{
    .product-option__bottombox .product-option__duration
    {
        font-size: 16px;
        line-height: 20px;

        margin-right: 8px;
        padding-right: 8px;
    }
}
.product-option__bottombox .product-option__price
{
    font-size: 13px;
    line-height: 17px;
}
.product-option__bottombox .product-option__price b
{
    font-size: 23px;
    line-height: 29px;
}
@media (max-width: 1800px)
{
    .product-option__bottombox .product-option__price b
    {
        font-size: 20px;
        line-height: 23px;
    }
}
@media (max-width: 1450px)
{
    .product-option__bottombox .product-option__price b
    {
        font-size: 18px;
        line-height: 22px;
    }
}
@media (max-width: 1350px)
{
    .product-option__bottombox .product-option__price b
    {
        font-size: 17px;
        line-height: 20px;
    }
}
@media (max-width: 1300px)
{
    .product-option__bottombox .product-option__price b
    {
        font-size: 22px;
        line-height: 29px;
    }
}
@media (max-width: 1024px)
{
    .product-option__bottombox .product-option__price b
    {
        font-size: 20px;
        line-height: 26px;
    }
}
@media (max-width: 1800px)
{
    .product-option__bottombox .product-option__price
    {
        font-size: 11px;
        line-height: 15px;
    }
}
@media (max-width: 1450px)
{
    .product-option__bottombox .product-option__price
    {
        font-size: 11px;
        line-height: 13px;
    }
}
@media (max-width: 1300px)
{
    .product-option__bottombox .product-option__price
    {
        font-size: 13px;
        line-height: 17px;

        margin-right: 8px;
        padding-right: 8px;
    }
}
@media (max-width: 1024px)
{
    .product-option__bottombox .product-option__price
    {
        font-size: 16px;
        line-height: 20px;

        margin-right: 8px;
        padding-right: 8px;
    }
}
.product-option__bottombox .product-option__data::after
{
    height: 42px;
}
@media (max-width: 1800px)
{
    .product-option__bottombox .product-option__data::after
    {
        height: 34px;
    }
}
@media (max-width: 1024px)
{
    .product-option__bottombox .product-option__data::after
    {
        height: 30px;
    }
}
.product-option__bottom-col
{
    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: calc(50% - 8px);
    margin-top: 12px;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}
@media (min-width: 1301px)
{
    .product-option__bottom-col:nth-child(2),
    .product-option__bottom-col:nth-child(1)
    {
        margin-top: 0;
    }
}
@media (min-width: 1301px)
{
    .product-option__bottom-col:nth-child(2n)
    {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
    }
    .product-option__bottom-col:nth-child(2n) .product-option__side
    {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }
    .product-option__bottom-col:nth-child(2n) .product-option__data:last-child
    {
        margin-right: 0;
    }
}
@media (max-width: 1800px)
{
    .product-option__bottom-col
    {
        margin-top: 8px;
    }
}
@media (max-width: 1300px)
{
    .product-option__bottom-col
    {
        width: 100%;
        margin-top: 0;
    }
}
.product-option__side
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
@media (max-width: 1300px)
{
    .product-option__side
    {
        width: 100%;
        margin-bottom: 8px;
    }
}
@media (max-width: 1024px)
{
    .product-option__side
    {
        margin-bottom: 5px;
    }
}
.product-option__data
{
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: 19px;

    position: relative;

    margin-right: 12px;
    margin-bottom: 8px;
    padding-right: 12px;

    background: linear-gradient(244.41deg, #c944d0 -20.74%, #2fcbf9 112.1%);
    -webkit-background-clip: text;
            background-clip: text;

    -webkit-text-fill-color: transparent;
}
@media (max-width: 1800px)
{
    .product-option__data
    {
        font-size: 14px;
        line-height: 17px;
    }
}
@media (max-width: 1650px)
{
    .product-option__data
    {
        font-size: 13px;
        line-height: 17px;

        margin-right: 8px;
        padding-right: 8px;
    }
}
@media (max-width: 1024px)
{
    .product-option__data
    {
        font-size: 16px;
        line-height: 20px;

        margin-right: 8px;
        margin-bottom: 10px;
        padding-right: 8px;
    }
}
.product-option__data b
{
    font-size: 30px;
    font-weight: 500;
    line-height: 37px;
}
@media (max-width: 1800px)
{
    .product-option__data b
    {
        font-size: 27px;
        line-height: 32px;
    }
}
@media (max-width: 1650px)
{
    .product-option__data b
    {
        font-size: 22px;
        line-height: 29px;
    }
}
@media (max-width: 1024px)
{
    .product-option__data b
    {
        font-size: 20px;
        line-height: 26px;
    }
}
.product-option__data span
{
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    line-height: 16px;

    display: block;

    max-width: 134px;

    background: linear-gradient(244.41deg, #c944d0 -20.74%, #2fcbf9 112.1%);
    -webkit-background-clip: text;
            background-clip: text;

    -webkit-text-fill-color: transparent;
}
@media (max-width: 1900px)
{
    .product-option__data span
    {
        font-size: 11px;
        line-height: 15px;

        max-width: 125px;
    }
}
@media (max-width: 1700px)
{
    .product-option__data span
    {
        font-size: 11px;
        line-height: 14px;

        max-width: 117px;
    }
}
@media (max-width: 1450px)
{
    .product-option__data span
    {
        font-size: 10px;
        line-height: 11px;

        max-width: 98px;
    }
}
@media (max-width: 1400px)
{
    .product-option__data span
    {
        max-width: 74px;
    }
}
@media (max-width: 1350px)
{
    .product-option__data span
    {
        font-size: 9px;
        line-height: 11px;

        max-width: 68px;
    }
}
@media (max-width: 1300px)
{
    .product-option__data span
    {
        font-size: 10px;
        line-height: 11px;

        max-width: 106px;
    }
}
@media (max-width: 1024px)
{
    .product-option__data span
    {
        font-size: 12px;
        line-height: 14px;

        max-width: 125px;
    }
}
.product-option__data:last-child
{
    padding-right: 0;
}
.product-option__data:last-child::after
{
    display: none;
}
@media (max-width: 1300px)
{
    .product-option__data:last-child
    {
        margin-right: 0;
    }
}
.product-option__data::after
{
    position: absolute;
    right: 0;
    bottom: 50%;

    width: 1px;
    height: 51px;

    content: '';
    -webkit-transform: translateY(50%);
            transform: translateY(50%);

    background: #9f9f9f;
}
@media (max-width: 1800px)
{
    .product-option__data::after
    {
        height: 41px;
    }
}
@media (max-width: 1650px)
{
    .product-option__data::after
    {
        height: 35px;
    }
}
@media (max-width: 1024px)
{
    .product-option__data::after
    {
        height: 30px;
    }
}
.product-option__price b
{
    font-weight: 900;
}
.product-option__btn
{
    margin-bottom: 8px;
}
@media (max-width: 1700px)
{
    .product-option__btn
    {
        min-width: 239px;
        max-width: 239px;
    }
}
@media (max-width: 1500px)
{
    .product-option__btn
    {
        min-width: 222px;
        max-width: 222px;
    }
}
@media (max-width: 1350px)
{
    .product-option__btn
    {
        min-width: 213px;
        max-width: 213px;
    }
}
@media (max-width: 1300px)
{
    .product-option__btn
    {
        min-width: 100%;
        max-width: none;
        margin-bottom: 0;
    }
}

.product-infocard__list
{
    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: calc(100% + 34px);
    margin-right: -18px;
    margin-left: -18px;

    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
@media (max-width: 1024px)
{
    .product-infocard__list
    {
        width: calc(100% + 20px);
        margin-right: -10px;
        margin-left: -10px;
    }
}
@media (max-width: 700px)
{
    .product-infocard__list
    {
        width: 100%;
        margin: 0;
    }
}
.product-infocard__item
{
    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: 50%;
    margin-bottom: 38px;
    padding: 0 17px;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}
@media (max-width: 1650px)
{
    .product-infocard__item
    {
        margin-bottom: 34px;
    }
}
@media (max-width: 1024px)
{
    .product-infocard__item
    {
        margin-bottom: 20px;
        padding: 0 10px;
    }
}
@media (max-width: 700px)
{
    .product-infocard__item
    {
        width: 100%;
        padding: 0;
    }
}
.product-infocard__item-inner
{
    position: relative;
    z-index: 1;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: 100%;
    height: 100%;
    padding: 32px 25px;
    padding-bottom: 17px;

    border-radius: 4px;
    background-color: #fff;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}
@media (max-width: 1650px)
{
    .product-infocard__item-inner
    {
        padding: 25px 21px;
        padding-bottom: 12px;
    }
}
@media (max-width: 1300px)
{
    .product-infocard__item-inner
    {
        padding: 17px;
    }
}
@media (max-width: 1024px)
{
    .product-infocard__item-inner
    {
        padding: 20px;

        border-radius: 5px;
    }
}
.product-infocard__item-inner::before
{
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    content: '';
    pointer-events: none;

    border-radius: 4px;
    background: linear-gradient(244.41deg, #c944d0 -20.74%, #2fcbf9 112.1%);
}
@media (max-width: 1024px)
{
    .product-infocard__item-inner::before
    {
        border-radius: 5px;
    }
}
.product-infocard__item-inner::after
{
    position: absolute;
    z-index: -1;
    top: 1px;
    left: 1px;

    width: calc(100% - 2px);
    height: calc(100% - 2px);

    content: '';
    pointer-events: none;

    border-radius: 3px;
    background-color: #fff;
}
@media (max-width: 1024px)
{
    .product-infocard__item-inner::after
    {
        border-radius: 4px;
    }
}
.product-infocard__info
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    line-height: 17px;

    position: relative;
    z-index: 1;

    width: 100%;
    margin-bottom: 25px;

    color: #000;
}
.product-infocard__info h5
{
    font-family: 'Montserrat';
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
    line-height: 25px;

    margin-bottom: 17px;

    background: linear-gradient(244.41deg, #c944d0 -20.74%, #2fcbf9 112.1%);
    -webkit-background-clip: text;
            background-clip: text;

    -webkit-text-fill-color: transparent;
}
@media (max-width: 1650px)
{
    .product-infocard__info h5
    {
        font-size: 18px;
        line-height: 23px;

        margin-bottom: 14px;
    }
}
@media (max-width: 1024px)
{
    .product-infocard__info h5
    {
        font-size: 20px;
        line-height: 26px;

        margin-bottom: 13px;
    }
}
.product-infocard__info p
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    line-height: 17px;

    color: #000;
}
@media (max-width: 1024px)
{
    .product-infocard__info p
    {
        font-size: 15px;
        line-height: 18px;
    }
}
.product-infocard__info b
{
    font-weight: 700;

    background: linear-gradient(244.41deg, #c944d0 -20.74%, #2fcbf9 112.1%);
    -webkit-background-clip: text;
            background-clip: text;

    -webkit-text-fill-color: transparent;
}
.product-infocard__info ul
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-top: 10px;

    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.product-infocard__info ul li
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 700;
    font-style: normal;
    line-height: 17px;

    position: relative;

    width: 50%;
    margin-bottom: 8px;
    padding-right: 4px;
    padding-left: 23px;

    background: linear-gradient(244.41deg, #c944d0 -20.74%, #2fcbf9 112.1%);
    -webkit-background-clip: text;
            background-clip: text;

    -webkit-text-fill-color: transparent;
}
@media (max-width: 1300px)
{
    .product-infocard__info ul li
    {
        width: 100%;
        padding: 0;
        padding-left: 23px;
    }
}
.product-infocard__info ul li::before
{
    position: absolute;
    top: 1px;
    left: 0;

    width: 19px;
    height: 15px;

    content: '';

    background: url('../svg/done.svg') no-repeat;
    background-size: contain;
}
.product-infocard__info ul li:nth-child(2n)
{
    padding-right: 0;
    padding-left: 32px;
}
@media (max-width: 1300px)
{
    .product-infocard__info ul li:nth-child(2n)
    {
        padding-left: 27px;
    }
}
.product-infocard__info ul li:nth-child(2n)::before
{
    left: 5px;
}
@media (max-width: 1300px)
{
    .product-infocard__info ul li:nth-child(2n)::before
    {
        left: 0;
    }
}
@media (max-width: 1300px)
{
    .product-infocard__info ul li:last-child
    {
        margin-bottom: 0;
    }
}
@media (max-width: 1024px)
{
    .product-infocard__info ul li
    {
        font-size: 16px;
        line-height: 20px;

        margin-bottom: 10px;
        padding-left: 27px;
    }
}
@media (max-width: 1024px)
{
    .product-infocard__info ul
    {
        margin-top: 12px;
    }
}
.product-infocard__info ul.price-ul li
{
    font-size: 13px;

    z-index: 1;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    margin-bottom: 13px;
    padding-right: 0;
    padding-left: 23px;

    background: #fff;

    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-text-fill-color: #75aff9;
}
.product-infocard__info ul.price-ul li:last-child
{
    margin-bottom: 0;
}
.product-infocard__info ul.price-ul li span
{
    padding-right: 4px;
}
@media (max-width: 1024px)
{
    .product-infocard__info ul.price-ul li span
    {
        padding-right: 0;
    }
}
.product-infocard__info ul.price-ul li .value
{
    font-weight: 500;

    min-width: -webkit-fit-content;
    min-width:    -moz-fit-content;
    min-width:         fit-content;
    margin-left: 46px;
    padding-right: 0;
    padding-left: 2px;

    -webkit-text-fill-color: #9f68db;
}
@media (max-width: 1650px)
{
    .product-infocard__info ul.price-ul li .value
    {
        margin-left: 38px;
    }
}
@media (max-width: 1450px)
{
    .product-infocard__info ul.price-ul li .value
    {
        margin-left: 29px;
    }
}
@media (max-width: 1024px)
{
    .product-infocard__info ul.price-ul li .value
    {
        min-width: auto;
        margin-top: 5px;
        margin-left: 0;
        padding-left: 0;
    }
}
@media (max-width: 1300px)
{
    .product-infocard__info ul.price-ul li
    {
        margin-bottom: 8px;
    }
}
@media (max-width: 1024px)
{
    .product-infocard__info ul.price-ul li
    {
        font-size: 15px;

            flex-direction: column;

        margin-bottom: 10px;
        padding-left: 27px;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}
.product-infocard__info ul.price-ul li span
{
    position: relative;
    z-index: 1;
}
.product-infocard__info ul.price-ul li span::before
{
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    content: '';

    background-color: #fff;
}
@media (max-width: 1024px)
{
    .product-infocard__info ul.price-ul li span::before
    {
        display: none;
    }
}
@media (max-width: 1024px)
{
    .product-infocard__info ul.price-ul li span
    {
        position: static;
        z-index: 0;
    }
}
.product-infocard__info ul.price-ul li::before
{
    left: 0;
}
.product-infocard__info ul.price-ul li::after
{
    position: absolute;
    z-index: -1;
    bottom: 5px;
    left: 23px;

    width: calc(100% - 23px);
    height: 1px;

    content: '';
    pointer-events: none;

    background: linear-gradient(244.41deg, #c944d0 -20.74%, #2fcbf9 112.1%);
}
@media (max-width: 1024px)
{
    .product-infocard__info ul.price-ul li::after
    {
        display: none;
    }
}
.product-infocard__info ul.full-ul li
{
    font-size: 13px;

    width: 100%;
    margin-bottom: 13px;
    padding-right: 0;
    padding-left: 23px;
}
.product-infocard__info ul.full-ul li:last-child
{
    margin-bottom: 0;
}
@media (max-width: 1300px)
{
    .product-infocard__info ul.full-ul li
    {
        margin-bottom: 8px;
    }
}
@media (max-width: 1024px)
{
    .product-infocard__info ul.full-ul li
    {
        font-size: 15px;

        margin-bottom: 10px;
        padding-left: 27px;
    }
}
.product-infocard__info ul.full-ul li::before
{
    left: 0;
}
.product-infocard__info h6
{
    font-family: 'Montserrat';
    font-size: 15px;
    font-weight: 700;
    font-style: normal;
    line-height: 19px;

    margin-top: 18px;
    margin-bottom: 12px;

    color: #000;
}
@media (max-width: 1024px)
{
    .product-infocard__info h6
    {
        font-size: 16px;
        line-height: 20px;

        margin-top: 15px;
        margin-bottom: 15px;
    }
}
.product-infocard__info i
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 700;
    font-style: normal;
    line-height: 16px;

    display: block;

    width: 100%;
    margin-top: 13px;

    color: #434343;
}
@media (max-width: 1024px)
{
    .product-infocard__info i
    {
        font-size: 15px;
        line-height: 19px;

        margin-top: 10px;
    }
}
.product-infocard__info i + ul
{
    margin-top: 13px;
}
@media (max-width: 1024px)
{
    .product-infocard__info i + ul
    {
        margin-top: 10px;
    }
}
@media (max-width: 1650px)
{
    .product-infocard__info
    {
        margin-bottom: 21px;
    }
}
@media (max-width: 1024px)
{
    .product-infocard__info
    {
        font-size: 16px;
        line-height: 20px;

        margin-bottom: 15px;
    }
}
.product-infocard__bottom
{
    position: relative;
    z-index: 1;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    margin-top: auto;

    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@media (max-width: 1300px)
{
    .product-infocard__bottom
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
}
.product-infocard__bottombox
{
    position: relative;
}
.product-infocard__bottombox .product-infocard__price
{
    font-size: 11px;
    line-height: 12px;
}
@media (max-width: 1800px)
{
    .product-infocard__bottombox .product-infocard__price
    {
        font-size: 10px;
        line-height: 11px;
    }
}
@media (max-width: 1024px)
{
    .product-infocard__bottombox .product-infocard__price
    {
        font-size: 12px;
        line-height: 14px;
    }
}
.product-infocard__bottombox .product-infocard__price b
{
    font-size: 20px;
    line-height: 25px;
}
@media (max-width: 1800px)
{
    .product-infocard__bottombox .product-infocard__price b
    {
        font-size: 17px;
        line-height: 20px;
    }
}
@media (max-width: 1300px)
{
    .product-infocard__bottombox .product-infocard__price b
    {
        font-size: 22px;
        line-height: 27px;
    }
}
@media (max-width: 1024px)
{
    .product-infocard__bottombox .product-infocard__price b
    {
        font-size: 20px;
        line-height: 24px;
    }
}
.product-infocard__bottombox .product-infocard__data::after
{
    height: 17px;
}
@media (max-width: 1024px)
{
    .product-infocard__bottombox .product-infocard__data::after
    {
        height: 20px;
    }
}
@media (max-width: 1300px)
{
    .product-infocard__bottombox
    {
        width: 100%;
    }
}
.product-infocard__bottombox-row
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;

    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@media (max-width: 1500px)
{
    .product-infocard__bottombox-row
    {
            flex-direction: column;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}
.product-infocard__bottom-technology
{
    font-family: 'Montserrat';
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    line-height: 25px;

    margin-right: 8px;
    margin-bottom: 10px;

    background: linear-gradient(244.41deg, #c944d0 -20.74%, #2fcbf9 112.1%);
    -webkit-background-clip: text;
            background-clip: text;

    -webkit-text-fill-color: transparent;
}
@media (max-width: 1800px)
{
    .product-infocard__bottom-technology
    {
        font-size: 17px;
        line-height: 20px;
    }
}
@media (max-width: 1500px)
{
    .product-infocard__bottom-technology
    {
        font-size: 15px;
        line-height: 18px;

        margin-right: 0;
        margin-bottom: 4px;
    }
}
@media (max-width: 1024px)
{
    .product-infocard__bottom-technology
    {
        font-size: 16px;
        line-height: 18px;

        margin-bottom: 5px;
    }
}
.product-infocard__side
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
@media (max-width: 1300px)
{
    .product-infocard__side
    {
        width: 100%;
        margin-bottom: 8px;
    }
}
@media (max-width: 1024px)
{
    .product-infocard__side
    {
        margin-bottom: 5px;
    }
}
.product-infocard__data
{
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: 19px;

    position: relative;

    margin-right: 12px;
    margin-bottom: 8px;
    padding-right: 12px;

    background: linear-gradient(244.41deg, #c944d0 -20.74%, #2fcbf9 112.1%);
    -webkit-background-clip: text;
            background-clip: text;

    -webkit-text-fill-color: transparent;
}
@media (max-width: 1800px)
{
    .product-infocard__data
    {
        font-size: 14px;
        line-height: 17px;
    }
}
@media (max-width: 1650px)
{
    .product-infocard__data
    {
        font-size: 13px;
        line-height: 17px;

        margin-right: 8px;
        padding-right: 8px;
    }
}
@media (max-width: 1024px)
{
    .product-infocard__data
    {
        font-size: 16px;
        line-height: 20px;

        margin-right: 8px;
        margin-bottom: 10px;
        padding-right: 8px;
    }
}
.product-infocard__data b
{
    font-size: 30px;
    font-weight: 500;
    line-height: 37px;
}
@media (max-width: 1800px)
{
    .product-infocard__data b
    {
        font-size: 27px;
        line-height: 32px;
    }
}
@media (max-width: 1650px)
{
    .product-infocard__data b
    {
        font-size: 22px;
        line-height: 29px;
    }
}
@media (max-width: 1024px)
{
    .product-infocard__data b
    {
        font-size: 20px;
        line-height: 26px;
    }
}
.product-infocard__data span
{
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    line-height: 16px;

    display: block;

    max-width: 134px;

    background: linear-gradient(244.41deg, #c944d0 -20.74%, #2fcbf9 112.1%);
    -webkit-background-clip: text;
            background-clip: text;

    -webkit-text-fill-color: transparent;
}
@media (max-width: 1900px)
{
    .product-infocard__data span
    {
        font-size: 11px;
        line-height: 15px;

        max-width: 125px;
    }
}
@media (max-width: 1700px)
{
    .product-infocard__data span
    {
        font-size: 11px;
        line-height: 14px;

        max-width: 117px;
    }
}
@media (max-width: 1450px)
{
    .product-infocard__data span
    {
        font-size: 10px;
        line-height: 11px;

        max-width: 98px;
    }
}
@media (max-width: 1400px)
{
    .product-infocard__data span
    {
        max-width: 74px;
    }
}
@media (max-width: 1350px)
{
    .product-infocard__data span
    {
        font-size: 9px;
        line-height: 11px;

        max-width: 68px;
    }
}
@media (max-width: 1300px)
{
    .product-infocard__data span
    {
        font-size: 10px;
        line-height: 11px;

        max-width: 106px;
    }
}
@media (max-width: 1024px)
{
    .product-infocard__data span
    {
        font-size: 12px;
        line-height: 14px;

        max-width: 125px;
    }
}
.product-infocard__data:last-child
{
    padding-right: 0;
}
.product-infocard__data:last-child::after
{
    display: none;
}
@media (max-width: 1300px)
{
    .product-infocard__data:last-child
    {
        margin-right: 0;
    }
}
.product-infocard__data::after
{
    position: absolute;
    right: 0;
    bottom: 50%;

    width: 1px;
    height: 51px;

    content: '';
    -webkit-transform: translateY(50%);
            transform: translateY(50%);

    background: #9f9f9f;
}
@media (max-width: 1800px)
{
    .product-infocard__data::after
    {
        height: 41px;
    }
}
@media (max-width: 1650px)
{
    .product-infocard__data::after
    {
        height: 35px;
    }
}
@media (max-width: 1024px)
{
    .product-infocard__data::after
    {
        height: 30px;
    }
}
.product-infocard__price
{
    font-weight: 900;
}
.product-infocard__price b
{
    font-weight: 900;
}
.product-infocard__btn
{
    margin-bottom: 8px;
}
@media (max-width: 1850px)
{
    .product-infocard__btn
    {
        min-width: 247px;
        max-width: 247px;
    }
}
@media (max-width: 1700px)
{
    .product-infocard__btn
    {
        min-width: 230px;
        max-width: 230px;
    }
}
@media (max-width: 1500px)
{
    .product-infocard__btn
    {
        min-width: 213px;
        max-width: 213px;
    }
}
@media (max-width: 1300px)
{
    .product-infocard__btn
    {
        min-width: 100%;
        max-width: none;
        margin-bottom: 0;
    }
}

.product-package__list
{
    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: calc(100% + 68px);
    margin: 0 auto;
    margin-right: -35px;
    margin-left: -35px;

    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
@media (max-width: 1800px)
{
    .product-package__list
    {
        width: calc(100% + 51px);
        margin-right: -26px;
        margin-left: -26px;
    }
}
@media (max-width: 1650px)
{
    .product-package__list
    {
        width: calc(100% + 34px);
        margin-right: -18px;
        margin-left: -18px;
    }
}
@media (max-width: 1024px)
{
    .product-package__list
    {
        width: calc(100% + 20px);
        margin-right: -10px;
        margin-left: -10px;
    }
}
@media (max-width: 800px)
{
    .product-package__list
    {
        width: 100%;
        margin: 0;
    }
}
.product-package__item
{
    position: relative;
    z-index: 1;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: 50%;
    margin-bottom: 34px;
    padding: 0 34px;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}
@media (max-width: 1800px)
{
    .product-package__item
    {
        padding: 0 25px;
    }
}
@media (max-width: 1650px)
{
    .product-package__item
    {
        padding: 0 17px;
    }
}
@media (max-width: 1024px)
{
    .product-package__item
    {
        margin-bottom: 40px;
        padding: 0 10px;
    }
}
@media (max-width: 800px)
{
    .product-package__item
    {
        width: 100%;
        padding: 0;
    }
}
.product-package__item::after
{
    position: absolute;
    z-index: -1;
    top: 0;
    left: 50%;

    width: calc(100% - 70px);
    height: calc(100% - 38px);

    content: '';
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    pointer-events: none;

    border: 1px solid #d7d7d7;
    border-radius: 8px;
}
@media (max-width: 1800px)
{
    .product-package__item::after
    {
        width: calc(100% - 52px);
    }
}
@media (max-width: 1650px)
{
    .product-package__item::after
    {
        width: calc(100% - 35px);
    }
}
@media (max-width: 1024px)
{
    .product-package__item::after
    {
        width: calc(100% - 22px);
        height: calc(100% - 45px);

        border-radius: 10px;
    }
}
@media (max-width: 800px)
{
    .product-package__item::after
    {
        width: calc(100% - 2px);
    }
}
.product-package__item.special-package .product-package__name
{
    font-weight: 700;
}
.product-package__item.special-package::after
{
    border-color: transparent;
    -webkit-box-shadow: 0 9.52789px 47.6394px rgba(129, 116, 248, .4);
            box-shadow: 0 9.52789px 47.6394px rgba(129, 116, 248, .4);
}
.product-package__item.special-package .product-package__special-flag
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.product-package__card
{
    position: relative;
    z-index: 1;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
        flex-direction: column;

    width: 100%;
    height: 100%;
    padding: 29px 25px;
    padding-bottom: 0;

    border-radius: 8px;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}
@media (max-width: 1300px)
{
    .product-package__card
    {
        padding: 21px 17px;
        padding-bottom: 0;
    }
}
@media (max-width: 1024px)
{
    .product-package__card
    {
        padding: 20px 15px;
        padding-bottom: 0;

        border-radius: 10px;
    }
}
.product-package__info
{
    position: relative;

    width: 100%;
}
.product-package__name
{
    font-family: 'Montserrat';
    font-size: 29px;
    font-weight: 500;
    font-style: normal;
    line-height: 35px;

    text-align: center;

    background: -webkit-gradient(linear, left top, right top, color-stop(1.2%, #88bcf9), to(#b458ec));
    background: linear-gradient(90deg, #88bcf9 1.2%, #b458ec 100%);
    -webkit-background-clip: text;
            background-clip: text;

    -webkit-text-fill-color: transparent;
}
@media (max-width: 1800px)
{
    .product-package__name
    {
        font-size: 23px;
        line-height: 29px;
    }
}
@media (max-width: 1650px)
{
    .product-package__name
    {
        font-size: 20px;
        line-height: 25px;
    }
}
@media (max-width: 1024px)
{
    .product-package__name
    {
        font-size: 20px;
        line-height: 24px;
    }
}
.product-package__stats
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;

    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.product-package__stat
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: -webkit-fit-content;
    width:    -moz-fit-content;
    width:         fit-content;
    width: calc(50% - 12px);
    margin-bottom: 11px;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}
.product-package__stat:nth-child(2n)
{
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}
@media (max-width: 576px)
{
    .product-package__stat:nth-child(2n) .product-package__stat-text
    {
        text-align: left;
    }
}
@media (max-width: 1650px)
{
    .product-package__stat
    {
        width: calc(50% - 10px);
    }
}
@media (max-width: 1300px)
{
    .product-package__stat
    {
        width: calc(50% - 8px);
    }
}
@media (max-width: 1024px)
{
    .product-package__stat
    {
        width: calc(50% - 7px);
        margin-bottom: 8px;
    }
}
.product-package__stat:last-child
{
    margin-right: 0;
}
.product-package__stat.color-stat .product-package__stat-number
{
    background: -webkit-gradient(linear, left top, right top, color-stop(1.2%, #88bcf9), to(#b458ec));
    background: linear-gradient(90deg, #88bcf9 1.2%, #b458ec 100%);
    -webkit-background-clip: text;
            background-clip: text;

    -webkit-text-fill-color: transparent;
}
.product-package__stat-number
{
    font-family: 'Montserrat';
    font-size: 48px;
    font-weight: 900;
    font-style: normal;
    line-height: 59px;

    color: #000;
}
@media (max-width: 1800px)
{
    .product-package__stat-number
    {
        font-size: 42px;
        line-height: 52px;
    }
}
@media (max-width: 1650px)
{
    .product-package__stat-number
    {
        font-size: 37px;
        line-height: 46px;
    }
}
@media (max-width: 1300px)
{
    .product-package__stat-number
    {
        font-size: 34px;
        line-height: 42px;
    }
}
@media (max-width: 1024px)
{
    .product-package__stat-number
    {
        font-size: 30px;
        line-height: 40px;
    }
}
@media (max-width: 576px)
{
    .product-package__stat-number
    {
        font-size: 24px;
        line-height: 32px;
    }
}
.product-package__stat-number b
{
    font-size: 132px;
    font-weight: 900;
    line-height: 153px;
}
@media (max-width: 1800px)
{
    .product-package__stat-number b
    {
        font-size: 119px;
        line-height: 138px;
    }
}
@media (max-width: 1650px)
{
    .product-package__stat-number b
    {
        font-size: 102px;
        line-height: 119px;
    }
}
@media (max-width: 1300px)
{
    .product-package__stat-number b
    {
        font-size: 85px;
        line-height: 102px;
    }
}
@media (max-width: 1024px)
{
    .product-package__stat-number b
    {
        font-size: 70px;
        line-height: 85px;
    }
}
@media (max-width: 576px)
{
    .product-package__stat-number b
    {
        font-size: 50px;
        line-height: 65px;
    }
}
.product-package__stat-text
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 700;
    font-style: normal;
    line-height: 17px;

    width: 100%;
    max-width: 234px;
    margin-top: -9px;

    text-align: center;

    color: #000;
}
@media (max-width: 1800px)
{
    .product-package__stat-text
    {
        max-width: 213px;
    }
}
@media (max-width: 1650px)
{
    .product-package__stat-text
    {
        font-size: 11px;
        line-height: 15px;

        max-width: 183px;
    }
}
@media (max-width: 1300px)
{
    .product-package__stat-text
    {
        font-size: 10px;
        line-height: 13px;

        max-width: 166px;
        margin-top: -10px;
    }
}
@media (max-width: 1024px)
{
    .product-package__stat-text
    {
        font-size: 10px;
        line-height: 13px;

        max-width: 140px;
    }
}
@media (max-width: 576px)
{
    .product-package__stat-text
    {
        max-width: 100%;

        text-align: right;
    }
}
.product-package__priceinfo
{
    font-family: 'Montserrat';
    font-size: 23px;
    font-weight: 400;
    font-style: italic;
    line-height: 29px;

    margin-bottom: 17px;

    text-align: center;

    background: linear-gradient(89.91deg, #11bbf0 -7.12%, #d83eff 112.36%);
    -webkit-background-clip: text;
            background-clip: text;

    -webkit-text-fill-color: transparent;
}
@media (max-width: 1650px)
{
    .product-package__priceinfo
    {
        font-size: 20px;
        line-height: 25px;
    }
}
@media (max-width: 1024px)
{
    .product-package__priceinfo
    {
        font-size: 16px;
        line-height: 20px;

        margin-bottom: 12px;
    }
}
.product-package__bottom
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: 100%;
    margin-top: auto;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.product-package__btn
{
    width: 100%;
    min-width: auto;
    max-width: 439px;
    margin-bottom: 25px;
}
@media (max-width: 1024px)
{
    .product-package__btn
    {
        max-width: 515px;
        margin-bottom: 15px;
    }
}
.product-package__description
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    line-height: 17px;

    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: 100%;
    max-width: 516px;
    min-height: 76px;
    padding: 8px 17px;

    text-align: center;

    color: #000;
    border: .952669px solid #9c9c9c;
    border-radius: 8px;
    background-color: #fff;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 1650px)
{
    .product-package__description
    {
        font-size: 12px;
        line-height: 16px;
    }
}
@media (max-width: 1024px)
{
    .product-package__description
    {
        font-size: 14px;
        line-height: 18px;

        max-width: 100%;
        min-height: 90px;
        padding: 10px;

        border-radius: 10px;
    }
}
.product-package__description b
{
    font-weight: 700;

    background: -webkit-gradient(linear, left top, right top, color-stop(1.2%, #88bcf9), to(#b458ec));
    background: linear-gradient(90deg, #88bcf9 1.2%, #b458ec 100%);
    -webkit-background-clip: text;
            background-clip: text;

    -webkit-text-fill-color: transparent;
}
.product-package__special-flag
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    line-height: 17px;

    position: absolute;
    z-index: 2;
    top: -18px;
    right: -68px;

    display: none;
        flex-direction: column;

    width: 187px;
    max-width: 187px;
    padding: 25px;
    padding-bottom: 8px;

    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    text-align: center;

    color: #fff;
    background: -webkit-gradient(linear, left top, right top, color-stop(1.2%, #88bcf9), to(#b458ec));
    background: linear-gradient(90deg, #88bcf9 1.2%, #b458ec 100%);

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.product-package__special-flag .icon
{
    max-width: 17px;
    max-height: 17px;
    margin-bottom: 5px;

    -o-object-fit: contain;
       object-fit: contain;
    fill: #fff;
}
@media (max-width: 1300px)
{
    .product-package__special-flag .icon
    {
        max-width: 14px;
        max-height: 14px;
        margin-bottom: 4px;
    }
}
@media (max-width: 1024px)
{
    .product-package__special-flag .icon
    {
        max-width: 15px;
        max-height: 15px;
        margin-bottom: 3px;
    }
}
@media (max-width: 1300px)
{
    .product-package__special-flag
    {
        font-size: 11px;
        line-height: 15px;

        top: -15px;
        right: -73px;

        padding: 17px;
        padding-bottom: 4px;
    }
}
@media (max-width: 1024px)
{
    .product-package__special-flag
    {
        font-size: 10px;
        line-height: 12px;

        top: -9px;
        right: -91px;

        width: 220px;
        max-width: 220px;
        padding: 10px;
        padding-bottom: 3px;
    }
}
@media (max-width: 576px)
{
    .product-package__special-flag
    {
        top: 0;
        right: 0;

        width: auto;
        padding: 10px;

        -webkit-transform: none;
                transform: none;

        background: transparent;
    }
    .product-package__special-flag p
    {
        display: none;
    }
    .product-package__special-flag .icon
    {
        fill: #b35bed;
    }
}

.product-compare__list
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: calc(100% + 25px);
    margin-right: -13px;
    margin-left: -13px;

    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
@media (max-width: 1300px)
{
    .product-compare__list
    {
            flex-direction: column;

        width: 100%;
        margin: 0;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
    }
}
.product-compare__column
{
    position: relative;

    width: 50%;
    margin-bottom: 25px;
    padding: 0 12px;
}
@media (max-width: 1300px)
{
    .product-compare__column
    {
        width: 100%;
        margin-bottom: 30px;
        padding: 0;
    }
    .product-compare__column:last-child
    {
        margin-bottom: 0;
    }
}
.product-compare__table
{
    position: relative;

    width: 100%;

    border: 1px solid #9c9c9c;
    border-radius: 8px;
}
@media (max-width: 1024px)
{
    .product-compare__table
    {
        border-radius: 10px;
    }
}
.product-compare__header
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: calc(100% + 2px);
    margin-top: -1px;
    margin-right: -1px;
    margin-left: -1px;
    padding: 0 1px;

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: linear-gradient(244.41deg, #c944d0 -20.74%, #2fcbf9 112.1%);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@media (max-width: 1024px)
{
    .product-compare__header
    {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
}
.product-compare__th
{
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 17px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    height: 100%;
    min-height: 93px;
    padding: 4px 5px;

    text-align: center;

    color: #fff;
    border-right: 1px solid #88adee;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 1650px)
{
    .product-compare__th
    {
        font-size: 13px;
        line-height: 17px;

        min-height: 85px;
    }
}
@media (max-width: 1450px)
{
    .product-compare__th
    {
        font-size: 11px;
        line-height: 15px;

        min-height: 72px;
    }
}
@media (max-width: 1024px)
{
    .product-compare__th
    {
        font-size: 14px;
        line-height: 18px;

        min-height: 70px;
        padding: 5px 7px;
    }
}
@media (max-width: 576px)
{
    .product-compare__th
    {
        font-size: 12px;
        line-height: 14px;
    }
}
.product-compare__th:last-child
{
    border-right: 0;
}
.product-compare__th:nth-child(1)
{
    width: 44%;
}
.product-compare__th:nth-child(2)
{
    width: 28%;
}
.product-compare__th:nth-child(3)
{
    width: 28%;
}
.product-compare__body
{
    position: relative;

    overflow: hidden;

    width: 100%;
    width: calc(100% + .5px);

    border-top: 0;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
}
@media (max-width: 1300px)
{
    .product-compare__body
    {
        width: 100%;
    }
}
@media (max-width: 1024px)
{
    .product-compare__body
    {
        border-bottom-right-radius: 9px;
        border-bottom-left-radius: 9px;
    }
}
.product-compare__tr
{
    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;

    width: 100%;

    border-bottom: 1px solid #dadada;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.product-compare__tr:last-child
{
    border-bottom: 0;
}
.product-compare__td
{
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    line-height: 17px;

    position: relative;
    z-index: 1;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    min-height: 64px;
    padding: 4px 5px;

    text-align: center;

    color: #fff;
    background-color: #98c5ff;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.product-compare__td::after
{
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 0;

    width: 100%;
    height: 256px;

    content: '';
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    pointer-events: none;

    border-right: 1px solid #dadada;
    background-color: #98c5ff;
}
@media (max-width: 1024px)
{
    .product-compare__td::after
    {
        height: 300px;
    }
}
@media (max-width: 1650px)
{
    .product-compare__td
    {
        font-size: 13px;
        line-height: 17px;
    }
}
@media (max-width: 1450px)
{
    .product-compare__td
    {
        font-size: 11px;
        line-height: 15px;
    }
}
@media (max-width: 1024px)
{
    .product-compare__td
    {
        font-size: 14px;
        line-height: 18px;

        min-height: 70px;
        padding: 5px 7px;
    }
}
@media (max-width: 576px)
{
    .product-compare__td
    {
        font-size: 12px;
        line-height: 14px;
    }
}
.product-compare__td:last-child::after
{
    border: none;
}
.product-compare__td:nth-child(1)
{
    width: 44%;
}
.product-compare__td:nth-child(2)
{
    width: 28%;
}
.product-compare__td:nth-child(3)
{
    width: 28%;
}
.product-compare__td.second-level-td
{
    background-color: #618cfa;
}
.product-compare__td.second-level-td::after
{
    background-color: #618cfa;
}
.product-compare__td.third-level-td
{
    background-color: #6167fa;
}
.product-compare__td.third-level-td::after
{
    background-color: #6167fa;
}
.product-compare__td.fourth-level-td
{
    background-color: #a461fa;
}
.product-compare__td.fourth-level-td::after
{
    background-color: #a461fa;
}
.product-compare__main-td
{
        flex-direction: row;

    height: 100%;

    background-color: #fff;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.product-compare__main-td::after
{
    background-color: #fff;
}
.product-compare__td-number
{
    font-family: 'Montserrat';
    font-size: 19px;
    font-weight: 900;
    font-style: normal;
    line-height: 23px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    min-width: 27px;
    margin-right: 8px;

    text-align: center;

    background: linear-gradient(244.41deg, #c944d0 -20.74%, #2fcbf9 112.1%);
    -webkit-background-clip: text;
            background-clip: text;

    -webkit-text-fill-color: transparent;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 1650px)
{
    .product-compare__td-number
    {
        font-size: 17px;
        line-height: 22px;

        min-width: 22px;
        margin-right: 5px;
    }
}
@media (max-width: 1024px)
{
    .product-compare__td-number
    {
        font-size: 18px;
        line-height: 20px;

        min-width: 24px;
        margin-right: 5px;
    }
}
@media (max-width: 576px)
{
    .product-compare__td-number
    {
        font-size: 16px;
        line-height: 18px;

        min-width: 22px;
        margin-right: 5px;
    }
}
.product-compare__td-name
{
    font-family: 'Montserrat';
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    line-height: 13px;

    text-align: left;

    color: #000;
}
@media (max-width: 1650px)
{
    .product-compare__td-name
    {
        font-size: 11px;
        line-height: 12px;
    }
}
@media (max-width: 1024px)
{
    .product-compare__td-name
    {
        font-size: 11px;
        line-height: 13px;
    }
}
@media (max-width: 576px)
{
    .product-compare__td-name
    {
        font-size: 10px;
        line-height: 12px;
    }
}

.modal-wraper
{
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    visibility: hidden;
    overflow: hidden;
    overflow-y: auto;
        flex-direction: column;

    width: 100%;
    height: 100vh;
    max-height: calc(var(--vh, 1vh) * 100);
    padding: 42px 25px;

    -webkit-transition: .3s;
            transition: .3s;
    pointer-events: none;

    opacity: 0;
    background-color: rgba(255, 255, 255, .6);

    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}
@media (max-width: 1024px)
{
    .modal-wraper
    {
        padding: 40px 15px;
    }
}
.modal-wraper.active
{
    visibility: visible;

    pointer-events: all;

    opacity: 1;
}
.modal-wraper.thanks-popup .modal__header,
.modal-wraper.error-popup .modal__header
{
    font-size: 27px;
}
@media (max-width: 1350px)
{
    .modal-wraper.thanks-popup .modal__header,
    .modal-wraper.error-popup .modal__header
    {
        font-size: 23px;
    }
}
@media (max-width: 1024px)
{
    .modal-wraper.thanks-popup .modal__header,
    .modal-wraper.error-popup .modal__header
    {
        font-size: 24px;
    }
}
.modal-wraper.thanks-popup .modal__text,
.modal-wraper.error-popup .modal__text
{
    font-size: 15px;
}
@media (max-width: 1024px)
{
    .modal-wraper.thanks-popup .modal__text,
    .modal-wraper.error-popup .modal__text
    {
        font-size: 14px;
    }
}
.modal-form-close__btn
{
    min-width: 100%;
}
.modal__box
{
    position: relative;

    width: 100%;
    max-width: 423px;
    margin: auto;

    border: 1px solid #d6d6d6;
    border-radius: 8px;
    background: #fff;
}
@media (max-width: 1024px)
{
    .modal__box
    {
        max-width: 400px;

        border-radius: 10px;
    }
}
@media (max-width: 576px)
{
    .modal__box
    {
        max-width: 100%;
    }
}
.modal__box .iti__selected-flag
{
    height: 100%;
    margin: 0;
}
.modal__box .form-field
{
    width: 100%;
    margin-bottom: 17px;
    padding: 0;
}
@media (max-width: 1024px)
{
    .modal__box .form-field
    {
        margin-bottom: 20px;
    }
}
.modal__box .form__field-name
{
    font-family: 'Montserrat';
    font-size: 11px;
    font-weight: 400;
    font-style: normal;
    line-height: 100%;

    margin-bottom: 8px;

    color: #a4a4a4;
}
@media (max-width: 1024px)
{
    .modal__box .form__field-name
    {
        font-size: 12px;

        margin-bottom: 7px;
    }
}
.modal__box .form__field-require
{
    font-family: 'Montserrat';
    font-size: 11px;
    font-weight: 400;
    font-style: normal;
    line-height: 100%;

    visibility: hidden;

    max-width: 50%;
    margin-bottom: 8px;
    padding-left: 4px;

    -webkit-transition: .3s;
            transition: .3s;
    text-align: right;
    text-align: right;

    opacity: 0;
    color: #fc6e6e;

    text-decoration-line: underline;
}
@media (max-width: 1024px)
{
    .modal__box .form__field-require
    {
        font-size: 12px;

        margin-bottom: 7px;
        padding-left: 5px;
    }
}
.modal__box .form__field-row
{
    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;

    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.modal__box .form__field-input
{
    position: relative;

    width: 100%;
}
.modal__box .form__field-input::after
{
    display: none;
}
.modal__box .form__input
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    line-height: 100%;

    min-height: 51px;
    padding: 12px 21px;

    color: #000;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
}
.modal__box .form__input::-webkit-input-placeholder
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    line-height: 100%;

    -webkit-transition: .3s;
            transition: .3s;

    color: #a4a4a4;
}
.modal__box .form__input::-moz-placeholder
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    line-height: 100%;

    -moz-transition: .3s;
         transition: .3s;

    color: #a4a4a4;
}
.modal__box .form__input:-ms-input-placeholder
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    line-height: 100%;

    -ms-transition: .3s;
        transition: .3s;

    color: #a4a4a4;
}
.modal__box .form__input::-ms-input-placeholder
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    line-height: 100%;

    -ms-transition: .3s;
        transition: .3s;

    color: #a4a4a4;
}
.modal__box .form__input::placeholder
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    line-height: 100%;

    -webkit-transition: .3s;
            transition: .3s;

    color: #a4a4a4;
}
@media (max-width: 1024px)
{
    .modal__box .form__input::-webkit-input-placeholder
    {
        font-size: 14px;
    }
    .modal__box .form__input::-moz-placeholder
    {
        font-size: 14px;
    }
    .modal__box .form__input:-ms-input-placeholder
    {
        font-size: 14px;
    }
    .modal__box .form__input::-ms-input-placeholder
    {
        font-size: 14px;
    }
    .modal__box .form__input::placeholder
    {
        font-size: 14px;
    }
}
.modal__box .form__input:focus
{
    border-color: #c187e9;
}
.modal__box .form__input:focus::-webkit-input-placeholder
{
    color: #c187e9;
}
.modal__box .form__input:focus::-moz-placeholder
{
    color: #c187e9;
}
.modal__box .form__input:focus:-ms-input-placeholder
{
    color: #c187e9;
}
.modal__box .form__input:focus::-ms-input-placeholder
{
    color: #c187e9;
}
.modal__box .form__input:focus::placeholder
{
    color: #c187e9;
}
@media (max-width: 1024px)
{
    .modal__box .form__input
    {
        font-size: 14px;

        min-height: 50px;
        padding: 10px 15px;

        border-radius: 5px;
    }
}
.modal__header
{
    font-family: 'Montserrat';
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
    line-height: 100%;

    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
    width: calc(100% + 2px);
    min-height: 76px;
    margin-top: -1px;
    margin-right: -1px;
    margin-left: -1px;
    padding: 12px;

    text-align: center;

    color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: #75aff9;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 1350px)
{
    .modal__header
    {
        font-size: 17px;
    }
}
@media (max-width: 1024px)
{
    .modal__header
    {
        font-size: 18px;

        min-height: 70px;
        padding: 10px 20px;

        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
}
.modal__close
{
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 25px;
    height: 25px;

    cursor: pointer;
    -webkit-transition: .3s;
            transition: .3s;
    -webkit-transform: translateX(38px) translateY(-30px);
            transform: translateX(38px) translateY(-30px);

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 1024px)
{
    .modal__close
    {
        width: 25px;
        height: 25px;

        -webkit-transform: translateX(25px) translateY(-25px);
                transform: translateX(25px) translateY(-25px);
    }
}
@media (max-width: 576px)
{
    .modal__close
    {
        -webkit-transform: translateX(0) translateY(-30px);
                transform: translateX(0) translateY(-30px);
    }
}
.modal__close .icon
{
    max-width: 25px;
    max-height: 25px;

    -webkit-transition: .3s;
            transition: .3s;

    fill: #75aff9;
}
@media (max-width: 1024px)
{
    .modal__close .icon
    {
        max-width: 25px;
        max-height: 25px;
    }
}
@media (min-width: 1025px)
{
    .modal__close:hover .icon
    {
        fill: #c187e9;
    }
}
.modal__content
{
    position: relative;

    width: 100%;
    padding: 21px 46px;
    padding-bottom: 32px;

    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    background-color: #fff;
}
@media (max-width: 1024px)
{
    .modal__content
    {
        padding: 20px;

        border-bottom-right-radius: 9px;
        border-bottom-left-radius: 9px;
    }
}
.modal__text
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 300;
    font-style: normal;
    line-height: 100%;

    margin-bottom: 25px;

    text-align: center;

    color: #000;
}
@media (max-width: 1024px)
{
    .modal__text
    {
        font-size: 14px;

        margin-bottom: 20px;
    }
}
.modal-form
{
    position: relative;

    width: 100%;
}
.modal-form__btn
{
    min-width: 100%;
}

.hero-section
{
    position: relative;
    z-index: 1;

    overflow: hidden;

    padding-top: 0;
    padding-bottom: 0;

    background: linear-gradient(74.64deg, #c8d5e2 4.11%, #f0f7f9 98.07%);
}
@media (max-width: 1024px)
{
    .hero-section
    {
        padding-top: 10px;
    }
}
@media (max-width: 700px)
{
    .hero-section
    {
        padding-top: 20px;
    }
}
@media (max-width: 576px)
{
    .hero-section
    {
        padding-top: 120px;
        padding-bottom: 50px;
    }
}
.hero-section::after
{
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 209px;

    content: '';
    pointer-events: none;

    background-color: #fff;
}
@media (max-width: 1650px)
{
    .hero-section::after
    {
        height: 192px;
    }
}
@media (max-width: 1024px)
{
    .hero-section::after
    {
        height: 215px;
    }
}
@media (max-width: 700px)
{
    .hero-section::after
    {
        height: 170px;
    }
}
@media (max-width: 576px)
{
    .hero-section::after
    {
        display: none;
    }
}
.hero-box
{
    position: relative;

    width: 100%;
    max-width: 802px;
    margin: 0 auto;
}
@media (max-width: 1650px)
{
    .hero-box
    {
        max-width: 683px;
    }
}
@media (max-width: 1024px)
{
    .hero-box
    {
        max-width: 660px;
    }
}
@media (max-width: 700px)
{
    .hero-box
    {
        max-width: 500px;
    }
}
@media (max-width: 576px)
{
    .hero-box
    {
        max-width: 100%;
    }
}
.hero-desctop__img
{
    width: 100%;

    -webkit-transform: translateY(5.7%);
            transform: translateY(5.7%);
    pointer-events: none;

    -o-object-fit: contain;
       object-fit: contain;
}
@media (max-width: 1650px)
{
    .hero-desctop__img
    {
        -webkit-transform: translateY(4%);
                transform: translateY(4%);
    }
}
@media (max-width: 1024px)
{
    .hero-desctop__img
    {
        -webkit-transform: translate(0);
                transform: translate(0);
    }
}
@media (max-width: 576px)
{
    .hero-desctop__img
    {
        display: none;
    }
}
.hero-info
{
    position: absolute;
    z-index: 1;
    top: 20%;
    left: 50%;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: 100%;
    max-width: 79%;
    height: 100%;
    max-height: 55%;
    padding-top: 3.17%;
    padding-right: 2.65%;
    padding-bottom: 2%;
    padding-left: 2.65%;

    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@media (max-width: 1650px)
{
    .hero-info
    {
        top: 18.2%;
    }
}
@media (max-width: 1024px)
{
    .hero-info
    {
        top: 14.4%;

        padding-bottom: 1.6%;
    }
}
@media (max-width: 700px)
{
    .hero-info
    {
        padding-bottom: 10.7%;

        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}
@media (max-width: 576px)
{
    .hero-info
    {
        position: relative;
        top: unset;

        max-width: 100%;
        max-height: none;
        padding: 0;
    }
}
.hero-info::after
{
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;

    width: 213px;
    height: 213px;

    content: '';
    -webkit-transform: translate(-50%, -60%);
            transform: translate(-50%, -60%);

    opacity: .5;
    border: 1px solid #b5b5b5;
    border-radius: 50%;
}
@media (max-width: 1650px)
{
    .hero-info::after
    {
        -webkit-transform: translate(-50%, -62%);
                transform: translate(-50%, -62%);
    }
}
@media (max-width: 1024px)
{
    .hero-info::after
    {
        width: 170px;
        height: 170px;

        -webkit-transform: translate(-50%, -65%);
                transform: translate(-50%, -65%);
    }
}
@media (max-width: 700px)
{
    .hero-info::after
    {
        width: 100px;
        height: 100px;

        -webkit-transform: translate(-50%, -107%);
                transform: translate(-50%, -107%);
    }
}
@media (max-width: 576px)
{
    .hero-info::after
    {
        display: none;
    }
}
.hero-info .blur-circle__one
{
    top: 33.5%;
    right: 19%;
    left: unset;

    width: 55px;
    height: 55px;

    -webkit-animation-delay: 1s;
            animation-delay: 1s;
}
@media (max-width: 1650px)
{
    .hero-info .blur-circle__one
    {
        top: 30%;
        right: 17%;
    }
}
@media (max-width: 1024px)
{
    .hero-info .blur-circle__one
    {
        width: 65px;
        height: 65px;
    }
}
.hero-info .blur-circle__two
{
    top: unset;
    right: -7%;
    bottom: 7%;
    left: unset;

    width: 68px;
    height: 68px;

    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
}
@media (max-width: 1650px)
{
    .hero-info .blur-circle__two
    {
        width: 59px;
        height: 59px;
    }
}
@media (max-width: 1024px)
{
    .hero-info .blur-circle__two
    {
        width: 70px;
        height: 70px;
    }
}
.hero-info .blur-circle__three
{
    top: 32%;
    left: 19%;

    width: 35px;
    height: 35px;

    -webkit-animation-delay: -1.5s;
            animation-delay: -1.5s;

    opacity: .3;
}
@media (max-width: 1024px)
{
    .hero-info .blur-circle__three
    {
        width: 42px;
        height: 42px;
    }
}
.hero-info .blur-circle__four
{
    top: 8%;
    left: -6%;

    width: 111px;
    height: 111px;
}
@media (max-width: 1650px)
{
    .hero-info .blur-circle__four
    {
        width: 85px;
        height: 85px;
    }
}
@media (max-width: 1024px)
{
    .hero-info .blur-circle__four
    {
        width: 100px;
        height: 100px;
    }
}
.hero__btn
{
    z-index: 1;

    min-width: 294px;
    max-width: 294px;
    margin-top: 12.5%;

    text-transform: uppercase;
}
@media (max-width: 1024px)
{
    .hero__btn
    {
        min-width: 345px;
        max-width: 345px;
        margin-top: 15.5%;
    }
}
@media (max-width: 700px)
{
    .hero__btn
    {
        min-width: 280px;
        max-width: 280px;
        margin-top: 5%;
    }
}
@media (max-width: 576px)
{
    .hero__btn
    {
        margin-top: 35px;
    }
}
@media (max-width: 450px)
{
    .hero__btn
    {
        min-width: 100%;
        max-width: 100%;
    }
}
.hero-pricelist__title
{
    font-family: 'Montserrat';
    font-size: 46px;
    font-weight: 900;
    font-style: normal;
    line-height: 51px;

    z-index: 1;

    text-align: center;
    text-transform: uppercase;

    color: #000;
}
@media (max-width: 1650px)
{
    .hero-pricelist__title
    {
        font-size: 37px;
        line-height: 44px;
    }
}
@media (max-width: 1024px)
{
    .hero-pricelist__title
    {
        font-size: 28px;
        line-height: 36px;
    }
}
@media (max-width: 700px)
{
    .hero-pricelist__title
    {
        font-size: 24px;
        line-height: 30px;
    }
}
@media (max-width: 576px)
{
    .hero-pricelist__title
    {
        font-size: 28px;
        line-height: 36px;

        margin-bottom: 17px;
    }
}
.hero-pricelist__year
{
    font-family: 'Montserrat';
    font-size: 136px;
    font-weight: 900;
    font-style: normal;
    line-height: 136px;

    z-index: 1;

    text-align: center;
    text-transform: uppercase;

    background: -webkit-gradient(linear, left top, right top, color-stop(1.2%, #88bcf9), to(#b458ec));
    background: linear-gradient(90deg, #88bcf9 1.2%, #b458ec 100%);
    -webkit-background-clip: text;
            background-clip: text;

    -webkit-text-fill-color: transparent;
}
@media (max-width: 1650px)
{
    .hero-pricelist__year
    {
        font-size: 119px;
        line-height: 119px;
    }
}
@media (max-width: 1024px)
{
    .hero-pricelist__year
    {
        font-size: 100px;
        line-height: 100px;
    }
}
@media (max-width: 700px)
{
    .hero-pricelist__year
    {
        font-size: 52px;
        line-height: 52px;
    }
}
@media (max-width: 576px)
{
    .hero-pricelist__year
    {
        font-size: 92px;
        line-height: 92px;
    }
}
.hero-one-circle
{
    top: 15%;
    right: -10%;
    bottom: unset;
    left: unset;

    -webkit-animation-duration: 2.5s;
            animation-duration: 2.5s;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
}
@media (max-width: 1024px)
{
    .hero-one-circle
    {
        top: 10%;
        right: -5%;
    }
}
@media (max-width: 576px)
{
    .hero-one-circle
    {
        top: 0;
        right: -3%;
    }
}
.hero-two-circle
{
    bottom: 29%;
    left: -3%;
}
@media (max-width: 1024px)
{
    .hero-two-circle
    {
        left: 0;
    }
}
@media (max-width: 576px)
{
    .hero-two-circle
    {
        bottom: 10%;
    }
}
.hero-three-circle
{
    z-index: 0;
    right: 10%;
    bottom: 24%;
    left: unset;

    width: 123px;
    height: 123px;
}
@media (max-width: 1650px)
{
    .hero-three-circle
    {
        right: 8%;

        width: 93px;
        height: 93px;
    }
}
@media (max-width: 1024px)
{
    .hero-three-circle
    {
        right: 15%;
        bottom: 33%;

        width: 90px;
        height: 90px;
    }
}
@media (max-width: 576px)
{
    .hero-three-circle
    {
        display: none;
    }
}
.hero-four-circle
{
    top: 17%;
    bottom: unset;
    left: 29%;

    width: 81px;
    height: 81px;
}
@media (max-width: 1650px)
{
    .hero-four-circle
    {
        left: 30%;

        width: 70px;
        height: 70px;
    }
}
@media (max-width: 1024px)
{
    .hero-four-circle
    {
        top: 20%;
        left: 34%;

        width: 60px;
        height: 60px;
    }
}
@media (max-width: 576px)
{
    .hero-four-circle
    {
        display: none;
    }
}

.design-list-section
{
    padding-bottom: 25px;
}
@media (max-width: 1024px)
{
    .design-list-section
    {
        padding-top: 35px;
        padding-bottom: 5px;
    }
}

.design-planning-section
{
    padding-top: 59px;
    padding-bottom: 51px;
}
@media (max-width: 1650px)
{
    .design-planning-section
    {
        padding-top: 53px;
        padding-bottom: 42px;
    }
}
@media (max-width: 1024px)
{
    .design-planning-section
    {
        padding-top: 40px;
        padding-bottom: 20px;
    }
}
.design-planning-section .global-title__wrapp
{
    margin-bottom: 64px;
}
@media (max-width: 1650px)
{
    .design-planning-section .global-title__wrapp
    {
        margin-bottom: 55px;
    }
}
@media (max-width: 1024px)
{
    .design-planning-section .global-title__wrapp
    {
        margin-bottom: 40px;
    }
}
.design-planning__title
{
    font-weight: 900;
}

.marketing-planning-section
{
    padding-top: 51px;
    padding-bottom: 8px;
}
@media (max-width: 1024px)
{
    .marketing-planning-section
    {
        padding-top: 40px;
        padding-bottom: 10px;
    }
}
.marketing-planning-section .product-infocard__list
{
    position: relative;
}
.marketing-planning-section .product-infocard__list::after
{
    font-family: 'Montserrat';
    font-size: 170px;
    font-weight: 900;
    font-style: normal;
    line-height: 170px;

    position: absolute;
    top: 0;
    left: 34px;

    content: 'SEO';
    -webkit-transform: translateY(-77%);
            transform: translateY(-77%);
    text-align: center;

    color: #f9f9f9;
}
@media (max-width: 1650px)
{
    .marketing-planning-section .product-infocard__list::after
    {
        font-size: 128px;
        line-height: 128px;
    }
}
@media (max-width: 1024px)
{
    .marketing-planning-section .product-infocard__list::after
    {
        display: none;
    }
}

.marketing-package-section
{
    padding-top: 42px;
    padding-bottom: 34px;
}
@media (max-width: 1024px)
{
    .marketing-package-section
    {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}
.marketing-package-section .global-container
{
    max-width: 1306px;
}
@media (max-width: 1024px)
{
    .marketing-package-section .global-container
    {
        max-width: 1530px;
    }
}
@media (max-width: 1024px)
{
    .marketing-package-section .global-title__wrapp
    {
        margin-bottom: 15px;
    }
}
.marketing-package__text
{
    margin-bottom: 42px;

    text-align: center;
}
@media (max-width: 1650px)
{
    .marketing-package__text
    {
        margin-bottom: 34px;
    }
}
@media (max-width: 1024px)
{
    .marketing-package__text
    {
        margin-bottom: 25px;
    }
}
.marketing-package__btn
{
    min-width: 350px;
    margin-left: 50%;

    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}
@media (max-width: 576px)
{
    .marketing-package__btn
    {
        min-width: 100%;
    }
}

.marketing-compare-section
{
    padding-top: 34px;
    padding-bottom: 8px;
}
@media (max-width: 1300px)
{
    .marketing-compare-section
    {
        padding-bottom: 34px;
    }
}
@media (max-width: 1024px)
{
    .marketing-compare-section
    {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}
@media (max-width: 576px)
{
    .marketing-compare-section .global-title__wrapp
    {
        margin-bottom: 30px;
    }
}

.graphic-design-section
{
    padding-top: 68px;
    padding-bottom: 29px;
}
@media (max-width: 1650px)
{
    .graphic-design-section
    {
        padding-top: 55px;
    }
}
@media (max-width: 1024px)
{
    .graphic-design-section
    {
        padding-top: 40px;
        padding-bottom: 5px;
    }
}

.contact-section
{
    padding-top: 42px;
    padding-bottom: 25px;
}
@media (max-width: 1024px)
{
    .contact-section
    {
        padding-top: 35px;
        padding-bottom: 30px;
    }
}
.contact-section .global-container
{
    max-width: 1195px;
}
@media (max-width: 1024px)
{
    .contact-section .global-container
    {
        max-width: 1400px;
    }
}
@media (min-width: 1025px)
{
    .contact-section a.contact-form__detail:hover::after
    {
        width: 100%;
    }
}
.contact-form
{
    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 100%;
}
@media (max-width: 800px)
{
    .contact-form
    {
            flex-direction: column-reverse;

        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
    }
}
.contact-form__info
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: 100%;
    min-width: 358px;
    max-width: calc(100% - 708px);
    padding: 42px;

    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background: linear-gradient(224.78deg, #538cfb 3.56%, #ee9dfb 96.77%);

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 1650px)
{
    .contact-form__info
    {
        padding: 34px;
    }
}
@media (max-width: 1024px)
{
    .contact-form__info
    {
        min-width: 320px;
        max-width: calc(100% - 830px);
        padding: 20px;
    }
}
@media (max-width: 800px)
{
    .contact-form__info
    {
        min-width: 100%;
        max-width: none;
        padding: 20px 15px;

        border-radius: 5px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}
.contact-form__infobox
{
    margin-bottom: 25px;
}
.contact-form__infobox:last-child
{
    margin-bottom: 0;
}
@media (max-width: 1650px)
{
    .contact-form__infobox
    {
        margin-bottom: 21px;
    }
}
@media (max-width: 1024px)
{
    .contact-form__infobox
    {
        margin-bottom: 20px;
    }
}
.contact-form-infobox__title
{
    font-family: 'Montserrat';
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    line-height: 18px;

    margin-bottom: 8px;

    color: #fff;
}
@media (max-width: 1650px)
{
    .contact-form-infobox__title
    {
        margin-bottom: 6px;
    }
}
@media (max-width: 1024px)
{
    .contact-form-infobox__title
    {
        font-size: 16px;
        line-height: 20px;

        margin-bottom: 7px;
    }
}
.contact-form-infobox__content
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: 100%;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}
.contact-form__detail
{
    font-family: 'Montserrat';
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
    line-height: 24px;

    position: relative;

    width: -webkit-fit-content;
    width:    -moz-fit-content;
    width:         fit-content;
    margin-bottom: 8px;

    -webkit-transition: .3s;
            transition: .3s;

    color: #fff;
}
@media (max-width: 1650px)
{
    .contact-form__detail
    {
        font-size: 17px;
        line-height: 20px;
    }
}
@media (max-width: 1024px)
{
    .contact-form__detail
    {
        font-size: 18px;
        line-height: 22px;

        margin-bottom: 10px;
    }
}
.contact-form__detail::after
{
    position: absolute;
    bottom: 1px;
    left: 0;

    width: 0;
    height: 1px;

    content: '';
    -webkit-transition: .3s;
            transition: .3s;

    background-color: #fff;
}
.contact-form__detail:last-child
{
    margin-bottom: 0;
}
.contact-form__box
{
    width: 100%;
    max-width: 100%;
    padding: 46px;

    border: 1px solid #d7d7d7;
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #fff;
}
@media (max-width: 1650px)
{
    .contact-form__box
    {
        padding: 34px;
    }
}
@media (max-width: 1024px)
{
    .contact-form__box
    {
        padding: 20px;

        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}
@media (max-width: 800px)
{
    .contact-form__box
    {
        padding: 20px 15px;

        border: 1px solid #d7d7d7;
        border-bottom: 0;
        border-radius: 5px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
}
.contact-form__btn
{
    min-width: 307px;
}
@media (max-width: 1024px)
{
    .contact-form__btn
    {
        min-width: 100%;
    }
}

.development-section
{
    padding-top: 55px;
    padding-bottom: 21px;
}
@media (max-width: 1024px)
{
    .development-section
    {
        padding-top: 40px;
        padding-bottom: 20px;
    }
}

.ppc-planning-section
{
    padding-top: 51px;
    padding-bottom: 4px;
}
@media (max-width: 1024px)
{
    .ppc-planning-section
    {
        padding-top: 25px;
        padding-bottom: 10px;
    }
}
.ppc-planning-section .product-infocard__list
{
    position: relative;
}
.ppc-planning-section .product-infocard__list::after
{
    font-family: 'Montserrat';
    font-size: 170px;
    font-weight: 900;
    font-style: normal;
    line-height: 170px;

    position: absolute;
    top: 0;
    left: 34px;

    content: 'PPC';
    -webkit-transform: translateY(-77%);
            transform: translateY(-77%);
    text-align: center;

    color: #f9f9f9;
}
@media (max-width: 1650px)
{
    .ppc-planning-section .product-infocard__list::after
    {
        font-size: 128px;
        line-height: 128px;
    }
}
@media (max-width: 1024px)
{
    .ppc-planning-section .product-infocard__list::after
    {
        display: none;
    }
}

.ppc-packet-section
{
    padding-top: 38px;
    padding-bottom: 38px;
}
@media (max-width: 1300px)
{
    .ppc-packet-section
    {
        padding-bottom: 59px;
    }
}
@media (max-width: 1024px)
{
    .ppc-packet-section
    {
        padding-top: 25px;
        padding-bottom: 40px;
    }
}
.ppc-packet__list
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: calc(100% + 34px);
    margin-right: -18px;
    margin-left: -18px;

    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
@media (max-width: 1300px)
{
    .ppc-packet__list
    {
        width: 100%;
        margin: 0;
    }
}
.ppc-packet__item
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: 33.3333333333%;
    height: -webkit-fit-content;
    height:    -moz-fit-content;
    height:         fit-content;
    margin-bottom: 34px;
    padding: 0 17px;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@media (max-width: 1301px)
{
    .ppc-packet__item:nth-child(3n-2)
    {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}
@media (min-width: 1301px)
{
    .ppc-packet__item:nth-child(3n)
    {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
    }
}
@media (max-width: 1300px)
{
    .ppc-packet__item
    {
        width: 100%;
        padding: 0;
    }
}
@media (max-width: 1300px)
{
    .ppc-packet__item:last-child
    {
        margin-bottom: 0;
    }
}
@media (max-width: 1024px)
{
    .ppc-packet__item
    {
        margin-bottom: 40px;
    }
    .ppc-packet__item:last-child
    {
        margin-bottom: 0;
    }
}
.ppc-packet__card
{
    position: relative;

    overflow: hidden;

    width: 100%;
    max-width: 363px;
}
@media (max-width: 1300px)
{
    .ppc-packet__card
    {
        max-width: 100%;
    }
}
.ppc-packet__name
{
    font-family: 'Montserrat';
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
    line-height: 24px;

    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;

    width: 100%;
    min-height: 68px;
    padding: 12px 25px;

    text-align: center;

    color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #75aff9;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 1650px)
{
    .ppc-packet__name
    {
        padding: 12px 21px;
    }
}
@media (max-width: 1300px)
{
    .ppc-packet__name
    {
        font-size: 17px;
        line-height: 20px;

        min-height: 59px;
    }
}
@media (max-width: 1024px)
{
    .ppc-packet__name
    {
        font-size: 18px;
        line-height: 22px;

        min-height: 60px;
        padding: 10px 15px;

        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }
}
.ppc-packet__content
{
    position: relative;
    z-index: 1;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
        flex-direction: column;

    width: 100%;
    padding: 21px 25px;
    padding-bottom: 34px;

    border: 1px solid #d7d7d7;
    border-top: 0;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: #fff;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@media (max-width: 1650px)
{
    .ppc-packet__content
    {
        padding: 21px;
        padding-bottom: 25px;
    }
}
@media (max-width: 1024px)
{
    .ppc-packet__content
    {
        padding: 20px 15px;

        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }
}
.ppc-packet__text
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 300;
    font-style: normal;
    line-height: 150%;

    margin-bottom: 21px;

    text-align: center;

    color: #000;
}
@media (max-width: 1024px)
{
    .ppc-packet__text
    {
        font-size: 14px;
        line-height: 20px;

        margin-bottom: 15px;
    }
}
.ppc-packet__options
{
    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: 100%;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.ppc-packet__options li
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    line-height: 150%;

    position: relative;

    width: -webkit-fit-content;
    width:    -moz-fit-content;
    width:         fit-content;
    margin-bottom: 21px;
    padding-left: 15px;

    text-align: center;

    color: #000;

    text-decoration-line: underline;
    text-decoration-style: dashed;
    text-decoration-thickness: from-font;
}
.ppc-packet__options li::before
{
    position: absolute;
    top: 5px;
    left: 0;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    width: 11px;
    height: 9px;

    content: '';

    background: url(../svg/done-purple.svg) no-repeat;
    background-size: 11px 9px;
}
@media (max-width: 1024px)
{
    .ppc-packet__options li::before
    {
        top: 6px;

        width: 13px;
        height: 11px;

        background-size: 13px 11px;
    }
}
@media (max-width: 1024px)
{
    .ppc-packet__options li
    {
        font-size: 14px;
        line-height: 20px;

        margin-bottom: 15px;
        padding-left: 18px;
    }
}
@media (max-width: 576px)
{
    .ppc-packet__options li
    {
        width: 100%;

        text-align: left;
    }
}
.ppc-packet__pricebox
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    width: 100%;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.ppc-packet__price
{
    font-family: 'Montserrat';
    font-size: 17px;
    font-weight: 700;
    font-style: normal;
    line-height: 150%;

    margin-bottom: 8px;

    background: -webkit-gradient(linear, left top, right top, color-stop(1.2%, #88bcf9), to(#b458ec));
    background: linear-gradient(90deg, #88bcf9 1.2%, #b458ec 100%);
    -webkit-background-clip: text;
            background-clip: text;

    -webkit-text-fill-color: transparent;
            text-fill-color: transparent;
}
@media (max-width: 1024px)
{
    .ppc-packet__price
    {
        font-size: 16px;
        line-height: 20px;

        margin-bottom: 10px;
    }
}
.ppc-packet__discountprice
{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 700;
    font-style: normal;
    line-height: 150%;

    margin-bottom: 21px;

    text-align: center;

    color: #adadad;

    text-decoration-line: line-through;
}
@media (max-width: 1024px)
{
    .ppc-packet__discountprice
    {
        font-size: 14px;
        line-height: 18px;

        margin-bottom: 15px;
    }
}
.ppc-packet__btn
{
    width: 100%;
    min-width: auto;
    max-width: 277px;
}
@media (max-width: 1024px)
{
    .ppc-packet__btn
    {
        max-width: 325px;
    }
}
@media (max-width: 450px)
{
    .ppc-packet__btn
    {
        max-width: 100%;
    }
}
.ppc-packet__discount-symbol
{
    font-family: 'Montserrat';
    font-size: 136px;
    font-weight: 800;
    font-style: normal;
    line-height: 150%;

    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;

    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;

    color: #fafafa;
}
@media (max-width: 1650px)
{
    .ppc-packet__discount-symbol
    {
        font-size: 102px;
    }
}
@media (max-width: 1024px)
{
    .ppc-packet__discount-symbol
    {
        font-size: 90px;
    }
}
