@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Medium.eot");
  src: url("../fonts/Montserrat-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Medium.woff2") format("woff2"), url("../fonts/Montserrat-Medium.woff") format("woff"), url("../fonts/Montserrat-Medium.ttf") format("truetype"), url("../fonts/Montserrat-Medium.svg#Montserrat-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Light.eot");
  src: url("../fonts/Montserrat-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Light.woff2") format("woff2"), url("../fonts/Montserrat-Light.woff") format("woff"), url("../fonts/Montserrat-Light.ttf") format("truetype"), url("../fonts/Montserrat-Light.svg#Montserrat-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ExtraLight.eot");
  src: url("../fonts/Montserrat-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-ExtraLight.woff2") format("woff2"), url("../fonts/Montserrat-ExtraLight.woff") format("woff"), url("../fonts/Montserrat-ExtraLight.ttf") format("truetype"), url("../fonts/Montserrat-ExtraLight.svg#Montserrat-ExtraLight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ExtraBold.eot");
  src: url("../fonts/Montserrat-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-ExtraBold.woff2") format("woff2"), url("../fonts/Montserrat-ExtraBold.woff") format("woff"), url("../fonts/Montserrat-ExtraBold.ttf") format("truetype"), url("../fonts/Montserrat-ExtraBold.svg#Montserrat-ExtraBold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-BlackItalic.eot");
  src: url("../fonts/Montserrat-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-BlackItalic.woff2") format("woff2"), url("../fonts/Montserrat-BlackItalic.woff") format("woff"), url("../fonts/Montserrat-BlackItalic.ttf") format("truetype"), url("../fonts/Montserrat-BlackItalic.svg#Montserrat-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ExtraBoldItalic.eot");
  src: url("../fonts/Montserrat-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-ExtraBoldItalic.woff2") format("woff2"), url("../fonts/Montserrat-ExtraBoldItalic.woff") format("woff"), url("../fonts/Montserrat-ExtraBoldItalic.ttf") format("truetype"), url("../fonts/Montserrat-ExtraBoldItalic.svg#Montserrat-ExtraBoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Italic.eot");
  src: url("../fonts/Montserrat-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Italic.woff2") format("woff2"), url("../fonts/Montserrat-Italic.woff") format("woff"), url("../fonts/Montserrat-Italic.ttf") format("truetype"), url("../fonts/Montserrat-Italic.svg#Montserrat-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Black.eot");
  src: url("../fonts/Montserrat-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Black.woff2") format("woff2"), url("../fonts/Montserrat-Black.woff") format("woff"), url("../fonts/Montserrat-Black.ttf") format("truetype"), url("../fonts/Montserrat-Black.svg#Montserrat-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-LightItalic.eot");
  src: url("../fonts/Montserrat-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-LightItalic.woff2") format("woff2"), url("../fonts/Montserrat-LightItalic.woff") format("woff"), url("../fonts/Montserrat-LightItalic.ttf") format("truetype"), url("../fonts/Montserrat-LightItalic.svg#Montserrat-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-BoldItalic.eot");
  src: url("../fonts/Montserrat-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-BoldItalic.woff2") format("woff2"), url("../fonts/Montserrat-BoldItalic.woff") format("woff"), url("../fonts/Montserrat-BoldItalic.ttf") format("truetype"), url("../fonts/Montserrat-BoldItalic.svg#Montserrat-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ExtraLightItalic.eot");
  src: url("../fonts/Montserrat-ExtraLightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-ExtraLightItalic.woff2") format("woff2"), url("../fonts/Montserrat-ExtraLightItalic.woff") format("woff"), url("../fonts/Montserrat-ExtraLightItalic.ttf") format("truetype"), url("../fonts/Montserrat-ExtraLightItalic.svg#Montserrat-ExtraLightItalic") format("svg");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Bold.eot");
  src: url("../fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Bold.woff2") format("woff2"), url("../fonts/Montserrat-Bold.woff") format("woff"), url("../fonts/Montserrat-Bold.ttf") format("truetype"), url("../fonts/Montserrat-Bold.svg#Montserrat-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ThinItalic.eot");
  src: url("../fonts/Montserrat-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-ThinItalic.woff2") format("woff2"), url("../fonts/Montserrat-ThinItalic.woff") format("woff"), url("../fonts/Montserrat-ThinItalic.ttf") format("truetype"), url("../fonts/Montserrat-ThinItalic.svg#Montserrat-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-SemiBold.eot");
  src: url("../fonts/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-SemiBold.woff2") format("woff2"), url("../fonts/Montserrat-SemiBold.woff") format("woff"), url("../fonts/Montserrat-SemiBold.ttf") format("truetype"), url("../fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-MediumItalic.eot");
  src: url("../fonts/Montserrat-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-MediumItalic.woff2") format("woff2"), url("../fonts/Montserrat-MediumItalic.woff") format("woff"), url("../fonts/Montserrat-MediumItalic.ttf") format("truetype"), url("../fonts/Montserrat-MediumItalic.svg#Montserrat-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.eot");
  src: url("../fonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Regular.woff2") format("woff2"), url("../fonts/Montserrat-Regular.woff") format("woff"), url("../fonts/Montserrat-Regular.ttf") format("truetype"), url("../fonts/Montserrat-Regular.svg#Montserrat-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Thin.eot");
  src: url("../fonts/Montserrat-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Thin.woff2") format("woff2"), url("../fonts/Montserrat-Thin.woff") format("woff"), url("../fonts/Montserrat-Thin.ttf") format("truetype"), url("../fonts/Montserrat-Thin.svg#Montserrat-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-SemiBoldItalic.eot");
  src: url("../fonts/Montserrat-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-SemiBoldItalic.woff2") format("woff2"), url("../fonts/Montserrat-SemiBoldItalic.woff") format("woff"), url("../fonts/Montserrat-SemiBoldItalic.ttf") format("truetype"), url("../fonts/Montserrat-SemiBoldItalic.svg#Montserrat-SemiBoldItalic") format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
/**
 * Functions for ViewPort Calculating
============
  SCSS
  .selector {
    font-size: px(18);
    @include md
      font-size: pxm(14);
    @include xs
      font-size: pxs(12);
  }

  CSS RESULT
  .selector {
    font-size: 18px;
  }
  @media (max-width: 768px) {
    .selector {
      font-size: 14px;
    }
  }
  @media (max-width: 320px) {
    .selector {
      font-size: 12px;
    }
  }
============
*/
/**
  function VA => use for calculate letter-spacing from photoshop
============
  SCSS
  .selector {
    letter-spacing: VA(120, 14);
  }

  CSS RESULT
  .selector {
    letter-spacing: 1.68px;
  }
============
  $unit => VA value in photoshop
  $font_size => how px font-size in photoshop ||| default 16
  $return px | em ||| default px
 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

.lock {
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch;
  touch-action: none;
}

.disable-display {
  display: none !important;
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  line-height: 1.3;
  font-size: 13px;
  scrollbar-gutter: stable;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  color: #000;
  background-color: #fff;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

ul, ol, li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

textarea {
  resize: none;
}

section {
  position: relative;
}

input,
select,
button,
textarea {
  outline: none !important;
  border-radius: 0;
  appearance: none;
}

button {
  cursor: pointer;
}

*, *::before, *::after {
  box-sizing: border-box;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.global-wrapp {
  width: 100%;
  position: relative;
  padding: 38px 97px;
  background-color: #fff;
}
.global-wrapp.bg-silver {
  background-color: #DDE7EF;
}
@media (max-width: 1600px) {
  .global-wrapp {
    padding: 38px 59px;
  }
}
@media (max-width: 1300px) {
  .global-wrapp {
    padding: 38px 42px;
  }
}
@media (max-width: 1150px) {
  .global-wrapp {
    padding: 38px 25px;
  }
}
@media (max-width: 1024px) {
  .global-wrapp {
    padding: 20px 15px;
  }
}

.global-container {
  width: 100%;
  position: relative;
  max-width: 1435px;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .global-container {
    max-width: 1680px;
  }
}

.global-category__box {
  width: 100%;
  position: relative;
  margin-bottom: 34px;
}
@media (max-width: 1024px) {
  .global-category__box {
    margin-bottom: 20px;
  }
}
.global-category__box:last-child {
  margin-bottom: 0;
}

button, textarea, input {
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
}

.content {
  flex: 1 0 auto;
}

.social__list {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.social__list li {
  margin: 8px;
}
@media (max-width: 1300px) {
  .social__list li {
    margin: 5px;
  }
}
@media (max-width: 1024px) {
  .social__list li {
    margin: 7px;
  }
}

.social__item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.social__item .icon {
  max-width: 18px;
  max-height: 17px;
  object-fit: contain;
  transition: 0.3s;
  fill: #B7B7B7;
}
@media (max-width: 1150px) {
  .social__item .icon {
    max-width: 17px;
    max-height: 14px;
  }
}
@media (max-width: 1024px) {
  .social__item .icon {
    max-width: 20px;
    max-height: 18px;
  }
}
@media (min-width: 1025px) {
  .social__item:hover .icon {
    fill: #C187E9;
  }
}

.global-title__wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  margin-bottom: 46px;
}
.global-title__wrapp .background-circle {
  left: 50%;
  width: 115px;
  transform: translateX(-50%) translateY(50%);
  animation: none;
  bottom: 50%;
}
@media (max-width: 1024px) {
  .global-title__wrapp .background-circle {
    width: 90px;
  }
}
.global-title__wrapp::after {
  content: "";
  width: 52px;
  height: 52px;
  position: absolute;
  z-index: -1;
  border-radius: 50%;
  opacity: 0.5;
  background-color: #F8F8F8;
  transition: 0.3s;
  top: -9px;
  left: -35px;
  backdrop-filter: blur(170px);
  filter: blur(5px);
  border: 1px solid rgb(206, 206, 206);
  animation: blik 2s ease infinite;
  animation-delay: 1.5s;
  pointer-events: none;
}
@media (max-width: 1024px) {
  .global-title__wrapp::after {
    display: none;
  }
}
.global-title__wrapp::before {
  content: "";
  width: 52px;
  height: 52px;
  position: absolute;
  z-index: -1;
  border-radius: 50%;
  opacity: 0.5;
  background-color: #F8F8F8;
  transition: 0.3s;
  top: 35px;
  right: -39px;
  backdrop-filter: blur(170px);
  filter: blur(5px);
  border: 1px solid rgb(206, 206, 206);
  animation: blik 2s ease infinite;
  animation-delay: 2.5s;
  pointer-events: none;
}
@media (max-width: 1024px) {
  .global-title__wrapp::before {
    display: none;
  }
}
@media (max-width: 1650px) {
  .global-title__wrapp {
    margin-bottom: 38px;
  }
}
@media (max-width: 1024px) {
  .global-title__wrapp {
    margin-bottom: 37px;
  }
}

.global-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  width: 100%;
  font-size: 82px;
  line-height: 99px;
  text-align: center;
  background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  position: relative;
  z-index: 1;
  width: fit-content;
  margin: 0 auto;
}
@media (max-width: 1650px) {
  .global-title {
    font-size: 61px;
    line-height: 76px;
  }
}
@media (max-width: 1024px) {
  .global-title {
    font-size: 48px;
    line-height: 58px;
  }
}
@media (max-width: 576px) {
  .global-title {
    font-size: 38px;
    line-height: 46px;
  }
}

.global-subtitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 41px;
  width: 100%;
  line-height: 50px;
  text-align: center;
  background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  position: relative;
  z-index: 1;
  width: fit-content;
  margin: 0 auto;
}
@media (max-width: 1650px) {
  .global-subtitle {
    font-size: 34px;
    line-height: 42px;
  }
}
@media (max-width: 1024px) {
  .global-subtitle {
    font-size: 30px;
    line-height: 40px;
  }
}
@media (max-width: 576px) {
  .global-subtitle {
    font-size: 26px;
    line-height: 34px;
  }
}

.global-category_title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  color: #0E8EE6;
}
@media (max-width: 1650px) {
  .global-category_title {
    font-size: 25px;
    line-height: 32px;
  }
}
@media (max-width: 1024px) {
  .global-category_title {
    font-size: 24px;
    line-height: 30px;
  }
}
@media (max-width: 576px) {
  .global-category_title {
    font-size: 22px;
    line-height: 28px;
  }
}

.global-title + .global-subtitle {
  margin-top: 23px;
}
@media (max-width: 1650px) {
  .global-title + .global-subtitle {
    margin-top: 14px;
  }
}
@media (max-width: 1024px) {
  .global-title + .global-subtitle {
    margin-top: 10px;
  }
}

.global-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #000;
}
@media (max-width: 1024px) {
  .global-text {
    font-size: 16px;
    line-height: 20px;
  }
}

.global-category__subtext {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  color: #000;
}
@media (max-width: 1024px) {
  .global-category__subtext {
    font-size: 16px;
    line-height: 20px;
  }
}
.global-category__subtext b {
  background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.global-category_title + .global-category__subtext {
  margin-top: 31px;
}
@media (max-width: 1650px) {
  .global-category_title + .global-category__subtext {
    margin-top: 25px;
  }
}
@media (max-width: 1024px) {
  .global-category_title + .global-category__subtext {
    margin-top: 20px;
  }
}

.global-category__notification {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  color: #000;
}
@media (max-width: 1650px) {
  .global-category__notification {
    font-size: 15px;
    line-height: 18px;
  }
}
@media (max-width: 1024px) {
  .global-category__notification {
    font-size: 16px;
    line-height: 20px;
  }
}

.global-category__subtext + .global-category__notification {
  margin-top: 34px;
}
@media (max-width: 1650px) {
  .global-category__subtext + .global-category__notification {
    margin-top: 25px;
  }
}
@media (max-width: 1024px) {
  .global-category__subtext + .global-category__notification {
    margin-top: 20px;
  }
}

.global-btn {
  cursor: pointer;
  outline: none;
  background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
  min-height: 51px;
  min-width: 277px;
  color: #fff;
  border-radius: 4px;
  padding: 8px 17px;
  transition: 0.3s;
  position: relative;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  text-align: center;
  z-index: 1;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.global-btn::after {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  right: 50%;
  background: linear-gradient(270deg, #88BCF9 0%, #B458EC 100%);
  transform: translate(50%, -50%);
  transition: 0.3s;
  z-index: -1;
  content: "";
  opacity: 0;
}
.global-btn .icon {
  fill: #75AFF9;
  transition: 0.3s;
  margin-left: 11px;
  max-width: 30px;
  max-height: 30px;
  object-fit: contain;
}
@media (max-width: 1024px) {
  .global-btn .icon {
    margin-left: 14px;
    max-width: 36px;
    max-height: 36px;
  }
}
.global-btn .icon-long-arrow {
  width: 30px;
  height: 6px;
}
@media (max-width: 1024px) {
  .global-btn .icon-long-arrow {
    width: 36px;
    height: 8px;
  }
}
@media (min-width: 1025px) {
  .global-btn:hover {
    color: #fff;
  }
  .global-btn:hover .icon {
    fill: #fff;
  }
}
@media (min-width: 1025px) {
  .global-btn:hover::after {
    transform: translate(50%, -50%);
    opacity: 1;
  }
}
.global-btn.rounded-btn {
  border-radius: 65px;
}
@media (max-width: 1024px) {
  .global-btn.rounded-btn {
    border-radius: 77px;
  }
}
@media (max-width: 1024px) {
  .global-btn {
    min-height: 50px;
    font-size: 14px;
    border-radius: 5px;
    line-height: 18px;
    padding: 7px 15px;
  }
}
@media (max-width: 450px) {
  .global-btn {
    min-width: 100%;
  }
}

.slider-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 34px;
}
@media (max-width: 1024px) {
  .slider-buttons {
    margin-top: 20px;
  }
}

.slider-button {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 17px;
  padding: 2px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
  background-color: #75AFF9;
}
@media (max-width: 1024px) {
  .slider-button {
    width: 35px;
    height: 35px;
    margin-right: 12px;
    border-radius: 4px;
    padding: 3px;
  }
}
.slider-button:last-child {
  margin-right: 0;
}
.slider-button .icon {
  width: 5px;
  height: 10px;
  fill: #fff;
  transition: 0.3s;
  object-fit: contain;
}
@media (max-width: 1024px) {
  .slider-button .icon {
    width: 6px;
    height: 10px;
  }
}
.slider-button.slider-prev .icon {
  transform: scale(-1, 1);
}
@media (min-width: 1025px) {
  .slider-button:hover {
    background-color: #98C5FF;
  }
}
.slider-button.swiper-button-disabled {
  opacity: 0.6;
  pointer-events: none;
}

.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 12;
  left: 0;
  transition: 0.17s;
  background: transparent;
  padding: 25px 97px;
}
.header.active {
  background: #fff;
  padding: 11px 97px;
}
@media (max-width: 1600px) {
  .header.active {
    padding: 11px 59px;
  }
}
@media (max-width: 1300px) {
  .header.active {
    padding: 11px 42px;
  }
}
@media (max-width: 1150px) {
  .header.active {
    padding: 11px 25px;
  }
}
@media (max-width: 1024px) {
  .header.active {
    padding: 16px 15px;
  }
}
@media (max-width: 1600px) {
  .header {
    padding: 25px 59px;
  }
}
@media (max-width: 1300px) {
  .header {
    padding: 25px 42px;
  }
}
@media (max-width: 1150px) {
  .header {
    padding: 25px;
  }
}
@media (max-width: 1024px) {
  .header {
    padding: 16px 15px;
  }
}
.header__container {
  width: 100%;
  max-width: 1435px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .header__container {
    max-width: 1680px;
  }
}
.header__logo {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 17px;
  min-width: 62px;
}
@media (max-width: 1024px) {
  .header__logo {
    min-width: 50px;
    margin-right: 20px;
  }
}
.header__logo img {
  width: 62px;
  object-fit: contain;
  height: 78px;
  pointer-events: none;
}
@media (max-width: 1024px) {
  .header__logo img {
    width: 50px;
    height: 63px;
  }
}
.header__side {
  display: inline-flex;
  align-items: center;
}
@media (max-width: 1024px) {
  .header__side {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    flex-direction: column;
    align-items: flex-start;
  }
}
.header__burger {
  display: none;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  margin-left: 20px;
  transition: 0.3s;
  background: transparent;
  border: none;
  outline: none;
}
.header__burger .icon {
  fill: #000;
  transition: 0.3s;
  max-width: 30px;
  max-height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.header__burger .icon-close-burger {
  opacity: 0;
  visibility: hidden;
  max-height: 20px;
}
.header__burger.active .icon-close-burger {
  opacity: 1;
  visibility: visible;
}
.header__burger.active .icon-burger {
  opacity: 0;
  visibility: hidden;
}
@media (max-width: 1024px) {
  .header__burger {
    display: flex;
  }
}
.header__menu {
  display: inline-flex;
  align-items: center;
}
@media (max-width: 1024px) {
  .header__menu {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    width: 100%;
  }
}
.header-link__option {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  margin: 0 21px;
}
@media (max-width: 1900px) {
  .header-link__option {
    margin: 0 12px;
  }
}
@media (max-width: 1450px) {
  .header-link__option {
    margin: 0 8px;
  }
}
@media (max-width: 1200px) {
  .header-link__option {
    margin: 0 4px;
  }
}
@media (max-width: 1024px) {
  .header-link__option {
    margin: 0;
    margin-bottom: 20px;
    width: 100%;
  }
}
.header-link__option:first-child {
  margin-left: 0;
}
.header-link__option:last-child {
  margin-right: 0;
}
.header__link {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  color: black;
  display: inline-flex;
  align-items: center;
  z-index: 1;
  position: relative;
  transition: 0.3s;
  background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
  background-clip: text;
  -webkit-background-clip: text;
  width: fit-content;
}
@media (max-width: 1200px) {
  .header__link {
    font-size: 11px;
    line-height: 14px;
  }
}
@media (max-width: 1024px) {
  .header__link {
    font-size: 18px;
    line-height: 22px;
  }
}
.header__link .icon {
  width: 6px;
  height: 4px;
  fill: #000;
  transition: 0.3s;
  margin-left: 4px;
  min-width: 5px;
  object-fit: contain;
}
@media (max-width: 1024px) {
  .header__link .icon {
    width: 7px;
    height: 4.5px;
    margin-left: 5px;
    min-width: 6px;
  }
}
@media (min-width: 1025px) {
  .header__link:hover {
    color: rgba(0, 0, 0, 0);
  }
  .header__link:hover .icon {
    fill: #C187E9;
  }
}
.header__link.active {
  color: rgba(0, 0, 0, 0) !important;
}
.header__link.active .icon {
  fill: #C187E9 !important;
  transform: rotate(180deg);
}
.header-link__hidelist {
  display: none;
  position: absolute;
  bottom: 0;
  left: -26px;
  transform: translateY(105%);
  z-index: 10;
  background-color: #fff;
  box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 17px 21px;
  max-width: 307px;
  min-width: 307px;
  max-height: calc(var(--vh, 1vh) * 100 - 179px);
  overflow: hidden;
  overflow-y: auto;
  scrollbar-color: #000 #EBEBEB;
}
@media (max-width: 1850px) {
  .header-link__hidelist {
    max-width: 256px;
    min-width: 256px;
    padding: 17px;
    left: -18px;
  }
}
@media (max-width: 1200px) {
  .header-link__hidelist {
    padding: 12px;
    max-width: 230px;
    min-width: 230px;
  }
}
@media (max-width: 1024px) {
  .header-link__hidelist {
    position: relative;
    transform: none;
    bottom: unset;
    left: unset;
    width: 100%;
    min-width: 100%;
    box-shadow: none;
    border-radius: 0;
    background: transparent;
    padding: 0;
    padding-top: 10px;
    max-height: fit-content;
  }
}
.header-link__hidelist::-webkit-scrollbar {
  width: 3px;
  background-color: #EBEBEB;
}
.header-link__hidelist::-webkit-scrollbar-thumb {
  width: 3px;
  background-color: #000;
  border-radius: 12px;
}
.header-link__hidelist li {
  padding-bottom: 17px;
  margin-bottom: 17px;
  border-bottom: 1px solid #EBEBEB;
  width: 100%;
}
@media (max-width: 1850px) {
  .header-link__hidelist li {
    padding-bottom: 12px;
    margin-bottom: 12px;
  }
}
@media (max-width: 1200px) {
  .header-link__hidelist li {
    padding-bottom: 8px;
    margin-bottom: 8px;
  }
}
@media (max-width: 1024px) {
  .header-link__hidelist li {
    padding: 0;
    margin-bottom: 10px;
    border: none;
  }
}
.header-link__hidelist li:last-child {
  border: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.header__hidelink {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  transition: 0.3s;
  color: black;
  background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
  background-clip: text;
  position: relative;
  -webkit-background-clip: text;
}
.header__hidelink::after {
  width: 0%;
  background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
  height: 1px;
  content: "";
  position: absolute;
  left: 0;
  bottom: 1px;
  transition: 0.3s;
}
@media (max-width: 1850px) {
  .header__hidelink {
    font-size: 11px;
    line-height: 13px;
  }
}
@media (max-width: 1200px) {
  .header__hidelink {
    font-size: 11px;
    line-height: 11px;
  }
}
@media (max-width: 1024px) {
  .header__hidelink {
    font-size: 16px;
    line-height: 20px;
  }
}
@media (min-width: 1025px) {
  .header__hidelink:hover {
    color: rgba(0, 0, 0, 0);
  }
  .header__hidelink:hover::after {
    width: 100%;
  }
}
.header__contacts {
  display: inline-flex;
  align-items: center;
}
@media (max-width: 1800px) {
  .header__contacts {
    flex-direction: column;
    align-items: flex-start;
    margin-right: 25px;
  }
}
@media (max-width: 1600px) {
  .header__contacts {
    margin-right: 17px;
  }
}
@media (max-width: 1100px) {
  .header__contacts {
    margin-right: 12px;
  }
}
@media (max-width: 1024px) {
  .header__contacts {
    margin-bottom: 40px;
    margin-right: 0;
  }
}
.header-contacts__wrapp {
  display: inline-flex;
  align-items: center;
  margin-left: 34px;
}
@media (max-width: 1800px) {
  .header-contacts__wrapp {
    margin-left: 42px;
  }
}
@media (max-width: 1400px) {
  .header-contacts__wrapp {
    margin-left: 17px;
  }
}
@media (max-width: 1100px) {
  .header-contacts__wrapp {
    margin-left: 12px;
  }
}
@media (max-width: 1024px) {
  .header-contacts__wrapp {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    width: 100%;
  }
}
.header-contact__link {
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #000;
  transition: 0.3s;
  margin-right: 38px;
  display: flex;
}
@media (max-width: 1900px) {
  .header-contact__link {
    margin-right: 25px;
  }
}
@media (max-width: 1800px) {
  .header-contact__link {
    margin-right: 0;
    margin-bottom: 4px;
  }
  .header-contact__link:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 1600px) {
  .header-contact__link {
    font-size: 11px;
    line-height: 15px;
  }
}
@media (max-width: 1400px) {
  .header-contact__link {
    font-size: 11px;
    line-height: 14px;
  }
}
@media (max-width: 1350px) {
  .header-contact__link {
    margin-bottom: 8px;
  }
  .header-contact__link .link-value {
    display: none;
  }
}
@media (max-width: 1024px) {
  .header-contact__link {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 18px;
  }
  .header-contact__link .link-value {
    display: inline-flex;
  }
}
.header-contact__link:last-child {
  margin-right: 34px;
}
@media (max-width: 1900px) {
  .header-contact__link:last-child {
    margin-right: 25px;
  }
}
@media (max-width: 1800px) {
  .header-contact__link:last-child {
    margin-right: 0;
  }
}
.header-contact__link .link-icon {
  max-width: 17px;
  max-height: 17px;
  min-width: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}
@media (max-width: 1400px) {
  .header-contact__link .link-icon {
    max-width: 14px;
    max-height: 14px;
    min-width: 14px;
    margin-right: 4px;
  }
}
@media (max-width: 1200px) {
  .header-contact__link .link-icon {
    margin-right: 0;
  }
}
@media (max-width: 1024px) {
  .header-contact__link .link-icon {
    margin-right: 5px;
    max-width: 18px;
    max-height: 18px;
    min-width: 18px;
  }
}
.header-contact__link .icon {
  max-width: 17px;
  max-height: 17px;
  object-fit: contain;
  transition: 0.3s;
  fill: #000;
}
@media (max-width: 1400px) {
  .header-contact__link .icon {
    max-width: 14px;
    max-height: 14px;
  }
}
@media (max-width: 1024px) {
  .header-contact__link .icon {
    max-width: 18px;
    max-height: 18px;
  }
}
@media (min-width: 1025px) {
  .header-contact__link:hover {
    color: #75AFF9;
  }
  .header-contact__link:hover .icon {
    fill: #75AFF9;
  }
}
.header-hide__contact {
  display: none !important;
}
@media (max-width: 1024px) {
  .header-hide__contact {
    display: inline-flex !important;
    margin-bottom: 0;
  }
  .header-hide__contact .icon {
    max-width: 16px;
    max-height: 16px;
  }
}
.header__btn {
  min-width: 209px;
}
@media (max-width: 1500px) {
  .header__btn {
    min-width: 187px;
  }
}
@media (max-width: 1100px) {
  .header__btn {
    min-width: 170px;
  }
}
@media (max-width: 1024px) {
  .header__btn {
    min-width: 260px;
  }
}
@media (max-width: 450px) {
  .header__btn {
    min-width: 100%;
  }
}
.header .select-info {
  margin-right: 17px;
}
@media (max-width: 1900px) {
  .header .select-info {
    margin-right: 12px;
  }
}
@media (max-width: 1400px) {
  .header .select-info {
    margin-right: 8px;
  }
}
@media (max-width: 1024px) {
  .header .select-info {
    margin-right: 0;
    margin-bottom: 40px;
  }
}
.header .select-info.active .select-arrow .icon {
  transform: rotate(180deg);
}
.header .select-info.active .select__trigger {
  border-color: transparent;
}
.header .select-info.active .select__trigger::after {
  opacity: 1;
}
.header .select-info.active .select__trigger .select-arrow .icon {
  fill: #fff;
}
.header .select-info.active .select__trigger .select__value {
  color: #fff;
}
.header .select-info .select__dropdown {
  position: absolute;
  width: 100%;
  max-width: 63px;
  background: transparent;
  display: none;
  padding: 8px 4px;
  z-index: 10;
  margin-top: 8px;
  background: transparent;
  border: 1px solid #000;
  border-radius: 4px;
  background-color: #fff;
}
@media (max-width: 1600px) {
  .header .select-info .select__dropdown {
    max-width: 55px;
    min-width: 55px;
  }
}
@media (max-width: 1200px) {
  .header .select-info .select__dropdown {
    max-width: 46px;
    min-width: 46px;
  }
}
@media (max-width: 1024px) {
  .header .select-info .select__dropdown {
    position: relative;
    margin-top: 5px;
    border-radius: 5px;
    max-width: 65px;
    min-width: 65px;
  }
}
.header .select-info .select__trigger {
  width: 100%;
  max-width: 63px;
  min-width: 63px;
  min-height: 51px;
  border: 1px solid #000;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transition: 0.3s;
  padding: 8px 4px;
}
@media (max-width: 1600px) {
  .header .select-info .select__trigger {
    max-width: 55px;
    min-width: 55px;
  }
}
@media (max-width: 1200px) {
  .header .select-info .select__trigger {
    max-width: 46px;
    min-width: 46px;
  }
}
@media (max-width: 1024px) {
  .header .select-info .select__trigger {
    max-width: 65px;
    border-radius: 5px;
    min-width: 65px;
  }
}
.header .select-info .select__trigger::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
  opacity: 0;
}
@media (min-width: 1025px) {
  .header .select-info .select__trigger:hover {
    border-color: transparent;
  }
  .header .select-info .select__trigger:hover::after {
    opacity: 1;
  }
  .header .select-info .select__trigger:hover .select-arrow .icon {
    fill: #fff;
  }
  .header .select-info .select__trigger:hover .select__value {
    color: #fff;
  }
}
@media (max-width: 1024px) {
  .header .select-info .select__trigger {
    min-height: 50px;
  }
}
.header .select-info .select-arrow {
  width: 6px;
  height: 4px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
}
@media (max-width: 1024px) {
  .header .select-info .select-arrow {
    margin-left: 5px;
    width: 7px;
    height: 4.5px;
  }
}
.header .select-info .select-arrow .icon {
  width: 6px;
  height: 4px;
  fill: #000;
  transition: 0.3s;
}
@media (max-width: 1024px) {
  .header .select-info .select-arrow .icon {
    width: 7px;
    height: 4.5px;
  }
}
.header .select-info .select__value {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  z-index: 1;
  line-height: 150%;
  transition: 0.3s;
  color: #000;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width: 1600px) {
  .header .select-info .select__value {
    font-size: 11px;
    line-height: 15px;
  }
}
@media (max-width: 1200px) {
  .header .select-info .select__value {
    font-size: 11px;
    line-height: 13px;
  }
}
@media (max-width: 1024px) {
  .header .select-info .select__value {
    font-size: 14px;
    line-height: 18px;
  }
}
.header .select-info .select__option {
  width: 100%;
  padding: 0;
  margin-bottom: 8px;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .header .select-info .select__option {
    margin-bottom: 10px;
  }
}
.header .select-info .select__option:last-child {
  margin-bottom: 0;
}
.header .select-info .select__option:last-child {
  border: none;
}
.header .select-info .select__option.active .header-lang__link {
  background: linear-gradient(89.91deg, #11BBF0 -7.12%, #D83EFF 112.36%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.header .select-info .select__option:hover .select__option-info {
  color: #C187E9;
}
.header .select-info .header-lang__link {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  color: #000;
  text-align: center;
  width: 100%;
  display: block;
  transition: 0.3s;
}
@media (max-width: 1600px) {
  .header .select-info .header-lang__link {
    font-size: 11px;
    line-height: 15px;
  }
}
@media (max-width: 1200px) {
  .header .select-info .header-lang__link {
    font-size: 11px;
    line-height: 14px;
  }
}
@media (max-width: 1024px) {
  .header .select-info .header-lang__link {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (min-width: 1025px) {
  .header .select-info .header-lang__link:hover {
    color: #75AFF9;
  }
}
@media (max-width: 1024px) {
  .header-mobile__menu {
    position: fixed;
    top: 95px;
    z-index: 12;
    left: 0;
    background-color: #fff;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100 - 95px);
    padding: 25px 15px;
    border-top: 1px solid #EBEBEB;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    transform: translateX(110%);
    transition: 0.4s;
    transition-delay: 0.15s;
  }
  .header-mobile__menu.active {
    transform: translateX(0);
    transition-delay: 0;
  }
}

.overlay {
  position: fixed;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  filter: blur(2px);
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  backdrop-filter: blur(2px);
}
.overlay.active {
  opacity: 1;
  visibility: visible;
}

.index-english-page .header__lang {
  display: none !important;
}

.footer {
  width: 100%;
  padding: 17px 97px;
  background-color: #fff;
}
@media (max-width: 1600px) {
  .footer {
    padding: 17px 59px;
  }
}
@media (max-width: 1300px) {
  .footer {
    padding: 17px 42px;
  }
}
@media (max-width: 1150px) {
  .footer {
    padding: 17px 25px;
  }
}
@media (max-width: 1024px) {
  .footer {
    padding: 20px;
  }
}
.footer__content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .footer__content {
    flex-wrap: wrap;
    flex-direction: column;
  }
}
.footer__copyright {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #B7B7B7;
}
@media (max-width: 1650px) {
  .footer__copyright {
    font-size: 11px;
    line-height: 15px;
  }
}
@media (max-width: 1024px) {
  .footer__copyright {
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    margin: 0;
    text-align: center;
  }
}
.footer__content-column {
  width: 33.3333333333%;
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  align-items: center;
}
.footer__content-column:nth-child(3n) {
  padding-right: 0;
  align-items: flex-end;
}
.footer__content-column:nth-child(3n-2) {
  padding-left: 0;
  align-items: flex-start;
}
@media (max-width: 1024px) {
  .footer__content-column {
    padding: 0 !important;
    align-items: center !important;
    margin-bottom: 20px;
    width: 100%;
  }
  .footer__content-column:last-child {
    margin-bottom: 0;
  }
}
.footer .footer__link {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #B7B7B7;
  transition: 0.3s;
}
.footer .footer__link.p-none {
  pointer-events: none !important;
}
@media (min-width: 1025px) {
  .footer .footer__link:hover {
    color: #C187E9;
  }
}
@media (max-width: 1650px) {
  .footer .footer__link {
    font-size: 11px;
    line-height: 15px;
  }
}
@media (max-width: 1024px) {
  .footer .footer__link {
    font-size: 14px;
    line-height: 18px;
  }
}

@keyframes blik-scale {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0;
    transform: scale(0.92);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes blik {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes blik-white {
  0% {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
  50% {
    opacity: 0;
    transform: translateX(-50%) scale(0.92);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
}
.background-circle {
  width: 153px;
  height: auto;
  position: absolute;
  z-index: -1;
  pointer-events: none;
  object-fit: contain;
  bottom: 0;
  left: 0;
  transition: 0.3s;
  animation: blik-scale 2s ease infinite;
}
@media (max-width: 1650px) {
  .background-circle {
    width: 128px;
  }
}
@media (max-width: 1024px) {
  .background-circle {
    width: 120px;
  }
}
@media (max-width: 576px) {
  .background-circle {
    width: 65px;
  }
}
.background-circle.big-circle {
  width: 286px;
}
@media (max-width: 1650px) {
  .background-circle.big-circle {
    width: 230px;
  }
}
@media (max-width: 1024px) {
  .background-circle.big-circle {
    width: 200px;
  }
}
@media (max-width: 576px) {
  .background-circle.big-circle {
    width: 150px;
  }
}

.blur-circle {
  width: 52px;
  height: 52px;
  position: absolute;
  border-radius: 50%;
  opacity: 0.5;
  background-color: #F8F8F8;
  transition: 0.3s;
  top: 0;
  left: 0;
  backdrop-filter: blur(170px);
  filter: blur(5px);
  border: 1px solid rgb(206, 206, 206);
  animation: blik 4s ease infinite;
  pointer-events: none;
}
@media (max-width: 1024px) {
  .blur-circle {
    display: none;
  }
}

.form-items__list {
  width: calc(100% + 25px);
  margin-left: -13px;
  margin-right: -13px;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1024px) {
  .form-items__list {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
  }
}
.form-field {
  width: 50%;
  position: relative;
  padding: 0 12px;
  margin-bottom: 34px;
}
@media (max-width: 1650px) {
  .form-field {
    margin-bottom: 25px;
  }
}
@media (max-width: 1024px) {
  .form-field {
    margin-bottom: 20px;
    padding: 0 10px;
  }
}
@media (max-width: 576px) {
  .form-field {
    width: 100% !important;
  }
}
.form-field.full-field {
  width: 100%;
}
.form-field.textarea-field .form__field-input::after {
  display: none;
}
.form-field .select-info.active .icon-trigger {
  transform: rotate(180deg);
}
.form-field .select-info.active + .form__field__line::after {
  width: 100%;
}
.form-field .select-info .select-info {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  background: transparent;
  transition: 0.3s;
}
.form-field .select-info .select__list {
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-color: #000 #fff;
  scrollbar-width: thin;
  padding-right: 12px;
  max-height: 69px;
}
@media (max-width: 1024px) {
  .form-field .select-info .select__list {
    padding-right: 7px;
    max-height: 72px;
  }
}
.form-field .select-info .select__list::-webkit-scrollbar {
  width: 2px;
  background-color: #fff;
}
.form-field .select-info .select__list::-webkit-scrollbar-thumb {
  width: 2px;
  background-color: #000;
}
.form-field .select-info .select__dropdown {
  position: absolute;
  width: 100%;
  background: transparent;
  display: none;
  padding: 8px;
  z-index: 10;
  margin-top: 0;
  background: linear-gradient(224.78deg, #538CFB 3.56%, #EE9DFB 96.77%);
  border-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
@media (max-width: 1024px) {
  .form-field .select-info .select__dropdown {
    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
.form-field .select-info .select__trigger {
  width: 100%;
  padding-bottom: 8px;
  border-radius: 85px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transition: 0.3s;
  padding-right: 14px;
}
@media (max-width: 1024px) {
  .form-field .select-info .select__trigger {
    padding-bottom: 10px;
    border-radius: 100px;
    padding-right: 17px;
  }
}
.form-field .select-info .select-arrow {
  width: 10px;
  height: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-70%);
}
@media (max-width: 1024px) {
  .form-field .select-info .select-arrow {
    width: 10px;
    height: 6px;
  }
}
.form-field .select-info .select-arrow .icon {
  width: 10px;
  height: 6px;
  fill: #707070;
  transition: 0.3s;
}
@media (max-width: 1024px) {
  .form-field .select-info .select-arrow .icon {
    width: 10px;
    height: 6px;
  }
}
.form-field .select-info .select__value {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #707070;
  transition: 0.3s;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width: 1024px) {
  .form-field .select-info .select__value {
    font-size: 14px;
    line-height: 18px;
  }
}
.form-field .select-info .select__option {
  width: fit-content;
  margin-bottom: 8px;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .form-field .select-info .select__option {
    margin-bottom: 10px;
  }
}
.form-field .select-info .select__option:last-child {
  margin-bottom: 0;
}
.form-field .select-info .select__option.active .select__option-info {
  color: #000;
}
@media (min-width: 1025px) {
  .form-field .select-info .select__option:hover .select__option-info {
    color: #000;
  }
}
.form-field .select-info .select__option-info {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  transition: 0.3s;
  line-height: 17px;
  color: #fff;
  width: fit-content;
}
@media (max-width: 1024px) {
  .form-field .select-info .select__option-info {
    font-size: 14px;
    line-height: 18px;
  }
}
.form-field .select-info .select__option.active {
  box-shadow: none;
  max-width: 100%;
}
.form__field-name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #707070;
  margin-bottom: 8px;
}
@media (max-width: 1024px) {
  .form__field-name {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
  }
}
.form__field-input {
  width: 100%;
  position: relative;
}
.form__field-input::after {
  bottom: 0;
  left: 0;
  content: "";
  position: absolute;
  height: 1px;
  background-color: #EDEDED;
  border-radius: 13px;
  width: 100%;
  transition: 0.3s;
}
@media (max-width: 1024px) {
  .form__field-input::after {
    height: 2px;
    border-radius: 16px;
  }
}
.form__field__line {
  position: relative;
  width: 100%;
}
.form__field__line::after {
  background: linear-gradient(224.78deg, #538CFB 3.56%, #EE9DFB 96.77%);
  bottom: 0;
  left: 0;
  content: "";
  position: absolute;
  height: 2px;
  border-radius: 16px;
  width: 0%;
  z-index: 1;
  transition: 0.3s;
}
.form__input {
  width: 100%;
  position: relative;
  transition: 0.3s;
  display: flex;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #707070;
  padding-bottom: 8px;
}
@media (max-width: 1024px) {
  .form__input {
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 10px;
  }
}
.form__input::placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  color: #707070;
  transition: 0.3s;
}
.form__input:focus + .form__field__line::after {
  width: 100%;
}
.form__input:focus::placeholder {
  color: #C187E9;
}
.form__textarea {
  border: 2px solid #EDEDED;
  border-radius: 3px;
  width: 100%;
  display: flex;
  min-height: 100px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #707070;
  transition: 0.3s;
  padding: 8px;
}
@media (max-width: 1024px) {
  .form__textarea {
    font-size: 14px;
    line-height: 18px;
    min-height: 60px;
    padding: 10px;
  }
}
.form__textarea:focus {
  border-image-slice: 1;
  border-image-source: linear-gradient(224.78deg, #538CFB 3.56%, #EE9DFB 96.77%);
}
.form__checkbox {
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 17px;
}
@media (max-width: 1024px) {
  .form__checkbox {
    margin-bottom: 20px;
  }
}
.form__checkbox input {
  display: none;
}
.form__checkbox input:checked + .form__checkbox-retangle {
  border-color: #75AFF9;
}
.form__checkbox input:checked + .form__checkbox-retangle .icon {
  opacity: 1;
}
.form__checkbox:hover .form__checkbox-retangle {
  border-color: #75AFF9;
}
.form__checkbox:hover .form__checkbox-text {
  color: #75AFF9;
}
.form__checkbox-retangle {
  min-width: 17px;
  min-height: 17px;
  max-width: 17px;
  max-height: 17px;
  margin-right: 10px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}
@media (max-width: 1024px) {
  .form__checkbox-retangle {
    min-width: 20px;
    min-height: 20px;
    max-width: 20px;
    max-height: 20px;
    margin-right: 12px;
    border-radius: 5px;
  }
}
.form__checkbox-retangle .icon {
  width: 9px;
  height: 7px;
  opacity: 0;
  object-fit: contain;
  fill: #75AFF9;
  transition: 0.3s;
}
@media (max-width: 1024px) {
  .form__checkbox-retangle .icon {
    width: 11px;
    height: 9px;
  }
}
.form__checkbox-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  transition: 0.3s;
  line-height: 100%;
  color: #656565;
}
@media (max-width: 1024px) {
  .form__checkbox-text {
    font-size: 12px;
  }
}

.form-item.error .form__input, .form-item.error__email .form__input {
  border-color: #FC6E6E !important;
  color: #FC6E6E !important;
}
.form-item.error .form__input::placeholder, .form-item.error__email .form__input::placeholder {
  color: #FC6E6E !important;
}
.form-item.error .form__field-require, .form-item.error__email .form__field-require {
  opacity: 1;
  visibility: visible;
}
.form-item.error .form__checkbox-retangle, .form-item.error__email .form__checkbox-retangle {
  border-color: #FC6E6E !important;
}
.form-item.error .form__checkbox-text, .form-item.error__email .form__checkbox-text {
  color: #FC6E6E !important;
}
.form-item.error .form__field-input::after, .form-item.error__email .form__field-input::after {
  background-color: #FC6E6E !important;
}
.form-item.error textarea, .form-item.error__email textarea {
  border-color: #FC6E6E !important;
}
.form-item.error .select__value, .form-item.error__email .select__value {
  color: #FC6E6E !important;
}

.iti {
  width: 100% !important;
}

.iti__selected-flag {
  margin-bottom: 8px;
  height: calc(100% - 8px);
}
@media (max-width: 1024px) {
  .iti__selected-flag {
    margin-bottom: 10px;
    height: calc(100% - 10px);
  }
}

.iti__country-list {
  padding: 0;
  max-width: 384px;
}
@media (max-width: 1024px) {
  .iti__country-list {
    min-width: 260px;
    max-width: calc(100% - 27px);
    margin-left: -17px;
  }
}

.iti__divider {
  padding: 0;
  margin: 0;
}

.global-fixed-socials {
  position: fixed;
  height: 100vh;
  top: 0;
  right: 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 11;
  padding: 85px 0;
}
@media (max-width: 1600px) {
  .global-fixed-socials {
    right: 17px;
  }
}
@media (max-width: 1300px) {
  .global-fixed-socials {
    right: 8px;
  }
}

.fixed-socials__list {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1150px) {
  .fixed-socials__list {
    display: none;
  }
}
.fixed-socials__list li {
  margin-bottom: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fixed-socials__list li:last-child {
  margin-bottom: 0;
}
@media (min-width: 1025px) and (max-height: 750px) {
  .fixed-socials__list li {
    margin-bottom: 25px;
  }
}
@media (min-width: 1025px) and (max-height: 700px) {
  .fixed-socials__list li {
    margin-bottom: 21px;
  }
}

.fixed-social__link {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fixed-social__link .icon {
  max-width: 18px;
  max-height: 17px;
  object-fit: contain;
  transition: 0.3s;
  fill: #383838;
}
@media (min-width: 1025px) {
  .fixed-social__link:hover .icon {
    fill: #C187E9;
  }
}

.back-to-top {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  position: fixed;
  bottom: 30px;
  right: 34px;
  transform: translateX(25%);
}
@media (max-width: 1600px) {
  .back-to-top {
    width: 34px;
    height: 34px;
    right: 17px;
  }
}
@media (max-width: 1300px) {
  .back-to-top {
    right: 8px;
    transform: translateX(4px);
  }
}
@media (max-width: 1150px) {
  .back-to-top {
    transform: translateX(0);
    right: 25px;
    width: 38px;
    height: 38px;
    background-color: #fff;
    border-radius: 50%;
  }
}
@media (max-width: 1024px) {
  .back-to-top {
    right: 15px;
    bottom: 15px;
  }
}
@media (min-width: 1025px) {
  .back-to-top:hover {
    transform: translateY(-3px) translateX(0);
  }
}
@media (min-width: 1151px) {
  .back-to-top:hover {
    transform: translateY(-3px) translateX(4px);
  }
}
@media (min-width: 1301px) {
  .back-to-top:hover {
    transform: translateY(-3px) translateX(25%);
  }
}
.back-to-top img {
  width: 100%;
  object-fit: contain;
  pointer-events: none;
}

.product-option__container {
  width: 100%;
  position: relative;
}
.product-option__box {
  width: 100%;
  position: relative;
  margin-bottom: 29px;
}
@media (max-width: 1650px) {
  .product-option__box {
    margin-bottom: 21px;
  }
}
@media (max-width: 1024px) {
  .product-option__box {
    margin-bottom: 10px;
  }
}
.product-option__box:last-child {
  margin-bottom: 0;
}
.product-option__box .global-title__wrapp::after {
  display: none;
}
.product-option__box .global-title__wrapp::before {
  display: none;
}
.product-option__list {
  width: calc(100% + 85px);
  margin-left: -43px;
  margin-right: -43px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1800px) {
  .product-option__list {
    width: calc(100% + 51px);
    margin-left: -26px;
    margin-right: -26px;
  }
}
@media (max-width: 1650px) {
  .product-option__list {
    width: calc(100% + 34px);
    margin-left: -18px;
    margin-right: -18px;
  }
}
@media (max-width: 1024px) {
  .product-option__list {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
  }
}
@media (max-width: 700px) {
  .product-option__list {
    width: 100%;
    margin: 0;
  }
}
.product-option__item {
  width: 50%;
  position: relative;
  margin-bottom: 68px;
  padding: 0 42px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1800px) {
  .product-option__item {
    padding: 0 25px;
    margin-bottom: 51px;
  }
}
@media (max-width: 1650px) {
  .product-option__item {
    padding: 0 17px;
    margin-bottom: 34px;
  }
}
@media (max-width: 1024px) {
  .product-option__item {
    padding: 0 10px;
    margin-bottom: 40px;
  }
}
@media (max-width: 700px) {
  .product-option__item {
    width: 100%;
    padding: 0;
  }
}
.product-option__photo {
  width: 100%;
  position: relative;
  margin-bottom: 25px;
  z-index: 1;
}
@media (max-width: 1650px) {
  .product-option__photo {
    margin-bottom: 17px;
  }
}
@media (max-width: 1024px) {
  .product-option__photo {
    margin-bottom: 15px;
  }
}
.product-option__photo img {
  width: 100%;
  position: relative;
  object-fit: contain;
  pointer-events: none;
}
.product-option__info {
  width: 100%;
  position: relative;
  margin-bottom: 29px;
  z-index: 1;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000;
}
.product-option__info h5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;
  background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 12px;
}
@media (max-width: 1800px) {
  .product-option__info h5 {
    font-size: 27px;
    line-height: 35px;
  }
}
@media (max-width: 1650px) {
  .product-option__info h5 {
    font-size: 23px;
    line-height: 30px;
  }
}
@media (max-width: 1024px) {
  .product-option__info h5 {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 12px;
  }
}
.product-option__info p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000;
}
@media (max-width: 1024px) {
  .product-option__info p {
    font-size: 15px;
    line-height: 18px;
  }
}
.product-option__info b {
  background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
}
.product-option__info ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
}
@media (max-width: 1024px) {
  .product-option__info ul {
    margin-top: 12px;
  }
}
.product-option__info ul li {
  width: 50%;
  margin-bottom: 8px;
  padding-right: 4px;
  font-family: "Montserrat";
  position: relative;
  padding-left: 23px;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 17px;
  background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
@media (max-width: 1300px) {
  .product-option__info ul li {
    width: 100%;
    padding: 0;
    padding-left: 23px;
  }
}
.product-option__info ul li::before {
  width: 16px;
  height: 12px;
  content: "";
  background: url("../svg/done.svg") no-repeat;
  position: absolute;
  left: 0;
  top: 1px;
  background-size: contain;
}
@media (max-width: 1024px) {
  .product-option__info ul li::before {
    width: 19px;
    height: 15px;
  }
}
.product-option__info ul li:nth-child(2n) {
  padding-right: 0;
  padding-left: 32px;
}
@media (max-width: 1300px) {
  .product-option__info ul li:nth-child(2n) {
    padding-left: 27px;
  }
}
.product-option__info ul li:nth-child(2n)::before {
  left: 5px;
}
@media (max-width: 1300px) {
  .product-option__info ul li:nth-child(2n)::before {
    left: 0;
  }
}
@media (max-width: 1300px) {
  .product-option__info ul li:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 1024px) {
  .product-option__info ul li {
    padding-left: 27px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 20px;
  }
}
@media (max-width: 1800px) {
  .product-option__info {
    font-size: 13px;
    line-height: 17px;
  }
}
@media (max-width: 1650px) {
  .product-option__info {
    margin-bottom: 21px;
  }
}
@media (max-width: 1024px) {
  .product-option__info {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 20px;
  }
}
.product-option__bottom {
  margin-top: auto;
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1300px) {
  .product-option__bottom {
    flex-direction: column;
  }
}
.product-option__bottombox {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 1301px) {
  .product-option__bottombox .product-option__side {
    width: 100%;
  }
}
@media (min-width: 1301px) {
  .product-option__bottombox .product-option__btn {
    max-width: 100%;
    height: fit-content;
  }
}
.product-option__bottombox .product-option__duration {
  font-size: 13px;
  line-height: 17px;
}
.product-option__bottombox .product-option__duration b {
  font-size: 23px;
  line-height: 29px;
}
@media (max-width: 1800px) {
  .product-option__bottombox .product-option__duration b {
    font-size: 20px;
    line-height: 23px;
  }
}
@media (max-width: 1450px) {
  .product-option__bottombox .product-option__duration b {
    font-size: 18px;
    line-height: 22px;
  }
}
@media (max-width: 1350px) {
  .product-option__bottombox .product-option__duration b {
    font-size: 17px;
    line-height: 20px;
  }
}
@media (max-width: 1300px) {
  .product-option__bottombox .product-option__duration b {
    font-size: 22px;
    line-height: 29px;
  }
}
@media (max-width: 1024px) {
  .product-option__bottombox .product-option__duration b {
    font-size: 20px;
    line-height: 26px;
  }
}
@media (max-width: 1800px) {
  .product-option__bottombox .product-option__duration {
    font-size: 11px;
    line-height: 15px;
  }
}
@media (max-width: 1450px) {
  .product-option__bottombox .product-option__duration {
    font-size: 11px;
    line-height: 13px;
  }
}
@media (max-width: 1300px) {
  .product-option__bottombox .product-option__duration {
    font-size: 13px;
    line-height: 17px;
    padding-right: 8px;
    margin-right: 8px;
  }
}
@media (max-width: 1024px) {
  .product-option__bottombox .product-option__duration {
    padding-right: 8px;
    margin-right: 8px;
    font-size: 16px;
    line-height: 20px;
  }
}
.product-option__bottombox .product-option__price {
  font-size: 13px;
  line-height: 17px;
}
.product-option__bottombox .product-option__price b {
  font-size: 23px;
  line-height: 29px;
}
@media (max-width: 1800px) {
  .product-option__bottombox .product-option__price b {
    font-size: 20px;
    line-height: 23px;
  }
}
@media (max-width: 1450px) {
  .product-option__bottombox .product-option__price b {
    font-size: 18px;
    line-height: 22px;
  }
}
@media (max-width: 1350px) {
  .product-option__bottombox .product-option__price b {
    font-size: 17px;
    line-height: 20px;
  }
}
@media (max-width: 1300px) {
  .product-option__bottombox .product-option__price b {
    font-size: 22px;
    line-height: 29px;
  }
}
@media (max-width: 1024px) {
  .product-option__bottombox .product-option__price b {
    font-size: 20px;
    line-height: 26px;
  }
}
@media (max-width: 1800px) {
  .product-option__bottombox .product-option__price {
    font-size: 11px;
    line-height: 15px;
  }
}
@media (max-width: 1450px) {
  .product-option__bottombox .product-option__price {
    font-size: 11px;
    line-height: 13px;
  }
}
@media (max-width: 1300px) {
  .product-option__bottombox .product-option__price {
    font-size: 13px;
    line-height: 17px;
    padding-right: 8px;
    margin-right: 8px;
  }
}
@media (max-width: 1024px) {
  .product-option__bottombox .product-option__price {
    padding-right: 8px;
    margin-right: 8px;
    font-size: 16px;
    line-height: 20px;
  }
}
.product-option__bottombox .product-option__data::after {
  height: 42px;
}
@media (max-width: 1800px) {
  .product-option__bottombox .product-option__data::after {
    height: 34px;
  }
}
@media (max-width: 1024px) {
  .product-option__bottombox .product-option__data::after {
    height: 30px;
  }
}
.product-option__bottom-col {
  width: calc(50% - 8px);
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}
@media (min-width: 1301px) {
  .product-option__bottom-col:nth-child(2), .product-option__bottom-col:nth-child(1) {
    margin-top: 0;
  }
}
@media (min-width: 1301px) {
  .product-option__bottom-col:nth-child(2n) {
    align-items: flex-end;
  }
  .product-option__bottom-col:nth-child(2n) .product-option__side {
    justify-content: flex-end;
  }
  .product-option__bottom-col:nth-child(2n) .product-option__data:last-child {
    margin-right: 0;
  }
}
@media (max-width: 1800px) {
  .product-option__bottom-col {
    margin-top: 8px;
  }
}
@media (max-width: 1300px) {
  .product-option__bottom-col {
    width: 100%;
    margin-top: 0;
  }
}
.product-option__side {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 1300px) {
  .product-option__side {
    width: 100%;
    margin-bottom: 8px;
  }
}
@media (max-width: 1024px) {
  .product-option__side {
    margin-bottom: 5px;
  }
}
.product-option__data {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  padding-right: 12px;
  margin-right: 12px;
  position: relative;
  margin-bottom: 8px;
}
@media (max-width: 1800px) {
  .product-option__data {
    font-size: 14px;
    line-height: 17px;
  }
}
@media (max-width: 1650px) {
  .product-option__data {
    font-size: 13px;
    line-height: 17px;
    padding-right: 8px;
    margin-right: 8px;
  }
}
@media (max-width: 1024px) {
  .product-option__data {
    padding-right: 8px;
    margin-right: 8px;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }
}
.product-option__data b {
  font-size: 30px;
  line-height: 37px;
  font-weight: 500;
}
@media (max-width: 1800px) {
  .product-option__data b {
    font-size: 27px;
    line-height: 32px;
  }
}
@media (max-width: 1650px) {
  .product-option__data b {
    font-size: 22px;
    line-height: 29px;
  }
}
@media (max-width: 1024px) {
  .product-option__data b {
    font-size: 20px;
    line-height: 26px;
  }
}
.product-option__data span {
  max-width: 134px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  display: block;
  font-size: 12px;
  line-height: 16px;
  background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
@media (max-width: 1900px) {
  .product-option__data span {
    font-size: 11px;
    line-height: 15px;
    max-width: 125px;
  }
}
@media (max-width: 1700px) {
  .product-option__data span {
    font-size: 11px;
    line-height: 14px;
    max-width: 117px;
  }
}
@media (max-width: 1450px) {
  .product-option__data span {
    font-size: 10px;
    line-height: 11px;
    max-width: 98px;
  }
}
@media (max-width: 1400px) {
  .product-option__data span {
    max-width: 74px;
  }
}
@media (max-width: 1350px) {
  .product-option__data span {
    font-size: 9px;
    line-height: 11px;
    max-width: 68px;
  }
}
@media (max-width: 1300px) {
  .product-option__data span {
    font-size: 10px;
    line-height: 11px;
    max-width: 106px;
  }
}
@media (max-width: 1024px) {
  .product-option__data span {
    font-size: 12px;
    line-height: 14px;
    max-width: 125px;
  }
}
.product-option__data:last-child {
  padding-right: 0;
}
.product-option__data:last-child::after {
  display: none;
}
@media (max-width: 1300px) {
  .product-option__data:last-child {
    margin-right: 0;
  }
}
.product-option__data::after {
  width: 1px;
  height: 51px;
  background: #9F9F9F;
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translateY(50%);
  content: "";
}
@media (max-width: 1800px) {
  .product-option__data::after {
    height: 41px;
  }
}
@media (max-width: 1650px) {
  .product-option__data::after {
    height: 35px;
  }
}
@media (max-width: 1024px) {
  .product-option__data::after {
    height: 30px;
  }
}
.product-option__price b {
  font-weight: 900;
}
.product-option__btn {
  margin-bottom: 8px;
}
@media (max-width: 1700px) {
  .product-option__btn {
    max-width: 239px;
    min-width: 239px;
  }
}
@media (max-width: 1500px) {
  .product-option__btn {
    max-width: 222px;
    min-width: 222px;
  }
}
@media (max-width: 1350px) {
  .product-option__btn {
    max-width: 213px;
    min-width: 213px;
  }
}
@media (max-width: 1300px) {
  .product-option__btn {
    max-width: none;
    min-width: 100%;
    margin-bottom: 0;
  }
}

.product-infocard__list {
  width: calc(100% + 34px);
  margin-left: -18px;
  margin-right: -18px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
@media (max-width: 1024px) {
  .product-infocard__list {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
  }
}
@media (max-width: 700px) {
  .product-infocard__list {
    width: 100%;
    margin: 0;
  }
}
.product-infocard__item {
  width: 50%;
  padding: 0 17px;
  position: relative;
  margin-bottom: 38px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1650px) {
  .product-infocard__item {
    margin-bottom: 34px;
  }
}
@media (max-width: 1024px) {
  .product-infocard__item {
    padding: 0 10px;
    margin-bottom: 20px;
  }
}
@media (max-width: 700px) {
  .product-infocard__item {
    width: 100%;
    padding: 0;
  }
}
.product-infocard__item-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  z-index: 1;
  padding: 32px 25px;
  padding-bottom: 17px;
}
@media (max-width: 1650px) {
  .product-infocard__item-inner {
    padding: 25px 21px;
    padding-bottom: 12px;
  }
}
@media (max-width: 1300px) {
  .product-infocard__item-inner {
    padding: 17px;
  }
}
@media (max-width: 1024px) {
  .product-infocard__item-inner {
    padding: 20px;
    border-radius: 5px;
  }
}
.product-infocard__item-inner::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -2;
  pointer-events: none;
  border-radius: 4px;
  background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
}
@media (max-width: 1024px) {
  .product-infocard__item-inner::before {
    border-radius: 5px;
  }
}
.product-infocard__item-inner::after {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border-radius: 3px;
  background-color: #fff;
  position: absolute;
  z-index: -1;
  top: 1px;
  left: 1px;
  content: "";
  pointer-events: none;
}
@media (max-width: 1024px) {
  .product-infocard__item-inner::after {
    border-radius: 4px;
  }
}
.product-infocard__info {
  width: 100%;
  position: relative;
  margin-bottom: 25px;
  z-index: 1;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #000;
}
.product-infocard__info h5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 17px;
}
@media (max-width: 1650px) {
  .product-infocard__info h5 {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 14px;
  }
}
@media (max-width: 1024px) {
  .product-infocard__info h5 {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 13px;
  }
}
.product-infocard__info p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #000;
}
@media (max-width: 1024px) {
  .product-infocard__info p {
    font-size: 15px;
    line-height: 18px;
  }
}
.product-infocard__info b {
  background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
}
.product-infocard__info ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
}
.product-infocard__info ul li {
  width: 50%;
  margin-bottom: 8px;
  padding-right: 4px;
  font-family: "Montserrat";
  position: relative;
  padding-left: 23px;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 17px;
  background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
@media (max-width: 1300px) {
  .product-infocard__info ul li {
    width: 100%;
    padding: 0;
    padding-left: 23px;
  }
}
.product-infocard__info ul li::before {
  width: 19px;
  height: 15px;
  content: "";
  background: url("../svg/done.svg") no-repeat;
  position: absolute;
  left: 0;
  top: 1px;
  background-size: contain;
}
.product-infocard__info ul li:nth-child(2n) {
  padding-right: 0;
  padding-left: 32px;
}
@media (max-width: 1300px) {
  .product-infocard__info ul li:nth-child(2n) {
    padding-left: 27px;
  }
}
.product-infocard__info ul li:nth-child(2n)::before {
  left: 5px;
}
@media (max-width: 1300px) {
  .product-infocard__info ul li:nth-child(2n)::before {
    left: 0;
  }
}
@media (max-width: 1300px) {
  .product-infocard__info ul li:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 1024px) {
  .product-infocard__info ul li {
    margin-bottom: 10px;
    padding-left: 27px;
    font-size: 16px;
    line-height: 20px;
  }
}
@media (max-width: 1024px) {
  .product-infocard__info ul {
    margin-top: 12px;
  }
}
.product-infocard__info ul.price-ul li {
  width: 100%;
  padding-left: 23px;
  padding-right: 0;
  font-size: 13px;
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  -webkit-text-fill-color: #75AFF9;
  background: #fff;
  z-index: 1;
}
.product-infocard__info ul.price-ul li:last-child {
  margin-bottom: 0;
}
.product-infocard__info ul.price-ul li span {
  padding-right: 4px;
}
@media (max-width: 1024px) {
  .product-infocard__info ul.price-ul li span {
    padding-right: 0;
  }
}
.product-infocard__info ul.price-ul li .value {
  font-weight: 500;
  min-width: fit-content;
  margin-left: 46px;
  padding-right: 0;
  padding-left: 2px;
  -webkit-text-fill-color: #9F68DB;
}
@media (max-width: 1650px) {
  .product-infocard__info ul.price-ul li .value {
    margin-left: 38px;
  }
}
@media (max-width: 1450px) {
  .product-infocard__info ul.price-ul li .value {
    margin-left: 29px;
  }
}
@media (max-width: 1024px) {
  .product-infocard__info ul.price-ul li .value {
    margin-left: 0;
    margin-top: 5px;
    min-width: auto;
    padding-left: 0;
  }
}
@media (max-width: 1300px) {
  .product-infocard__info ul.price-ul li {
    margin-bottom: 8px;
  }
}
@media (max-width: 1024px) {
  .product-infocard__info ul.price-ul li {
    flex-direction: column;
    align-items: flex-start;
    font-size: 15px;
    padding-left: 27px;
    margin-bottom: 10px;
  }
}
.product-infocard__info ul.price-ul li span {
  position: relative;
  z-index: 1;
}
.product-infocard__info ul.price-ul li span::before {
  background-color: #fff;
  width: 100%;
  height: 100%;
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
}
@media (max-width: 1024px) {
  .product-infocard__info ul.price-ul li span::before {
    display: none;
  }
}
@media (max-width: 1024px) {
  .product-infocard__info ul.price-ul li span {
    position: static;
    z-index: 0;
  }
}
.product-infocard__info ul.price-ul li::before {
  left: 0;
}
.product-infocard__info ul.price-ul li::after {
  width: calc(100% - 23px);
  position: absolute;
  content: "";
  background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
  height: 1px;
  bottom: 5px;
  left: 23px;
  z-index: -1;
  pointer-events: none;
}
@media (max-width: 1024px) {
  .product-infocard__info ul.price-ul li::after {
    display: none;
  }
}
.product-infocard__info ul.full-ul li {
  width: 100%;
  padding-left: 23px;
  padding-right: 0;
  font-size: 13px;
  margin-bottom: 13px;
}
.product-infocard__info ul.full-ul li:last-child {
  margin-bottom: 0;
}
@media (max-width: 1300px) {
  .product-infocard__info ul.full-ul li {
    margin-bottom: 8px;
  }
}
@media (max-width: 1024px) {
  .product-infocard__info ul.full-ul li {
    font-size: 15px;
    margin-bottom: 10px;
    padding-left: 27px;
  }
}
.product-infocard__info ul.full-ul li::before {
  left: 0;
}
.product-infocard__info h6 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #000;
  margin-bottom: 12px;
  margin-top: 18px;
}
@media (max-width: 1024px) {
  .product-infocard__info h6 {
    margin-top: 15px;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 15px;
  }
}
.product-infocard__info i {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #434343;
  width: 100%;
  margin-top: 13px;
  display: block;
}
@media (max-width: 1024px) {
  .product-infocard__info i {
    font-size: 15px;
    line-height: 19px;
    margin-top: 10px;
  }
}
.product-infocard__info i + ul {
  margin-top: 13px;
}
@media (max-width: 1024px) {
  .product-infocard__info i + ul {
    margin-top: 10px;
  }
}
@media (max-width: 1650px) {
  .product-infocard__info {
    margin-bottom: 21px;
  }
}
@media (max-width: 1024px) {
  .product-infocard__info {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 20px;
  }
}
.product-infocard__bottom {
  margin-top: auto;
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1300px) {
  .product-infocard__bottom {
    flex-direction: column;
  }
}
.product-infocard__bottombox {
  position: relative;
}
.product-infocard__bottombox .product-infocard__price {
  font-size: 11px;
  line-height: 12px;
}
@media (max-width: 1800px) {
  .product-infocard__bottombox .product-infocard__price {
    font-size: 10px;
    line-height: 11px;
  }
}
@media (max-width: 1024px) {
  .product-infocard__bottombox .product-infocard__price {
    font-size: 12px;
    line-height: 14px;
  }
}
.product-infocard__bottombox .product-infocard__price b {
  font-size: 20px;
  line-height: 25px;
}
@media (max-width: 1800px) {
  .product-infocard__bottombox .product-infocard__price b {
    font-size: 17px;
    line-height: 20px;
  }
}
@media (max-width: 1300px) {
  .product-infocard__bottombox .product-infocard__price b {
    font-size: 22px;
    line-height: 27px;
  }
}
@media (max-width: 1024px) {
  .product-infocard__bottombox .product-infocard__price b {
    font-size: 20px;
    line-height: 24px;
  }
}
.product-infocard__bottombox .product-infocard__data::after {
  height: 17px;
}
@media (max-width: 1024px) {
  .product-infocard__bottombox .product-infocard__data::after {
    height: 20px;
  }
}
@media (max-width: 1300px) {
  .product-infocard__bottombox {
    width: 100%;
  }
}
.product-infocard__bottombox-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1500px) {
  .product-infocard__bottombox-row {
    flex-direction: column;
    align-items: flex-start;
  }
}
.product-infocard__bottom-technology {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 10px;
  margin-right: 8px;
}
@media (max-width: 1800px) {
  .product-infocard__bottom-technology {
    font-size: 17px;
    line-height: 20px;
  }
}
@media (max-width: 1500px) {
  .product-infocard__bottom-technology {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 4px;
    margin-right: 0;
  }
}
@media (max-width: 1024px) {
  .product-infocard__bottom-technology {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 5px;
  }
}
.product-infocard__side {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 1300px) {
  .product-infocard__side {
    width: 100%;
    margin-bottom: 8px;
  }
}
@media (max-width: 1024px) {
  .product-infocard__side {
    margin-bottom: 5px;
  }
}
.product-infocard__data {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  padding-right: 12px;
  margin-right: 12px;
  position: relative;
  margin-bottom: 8px;
}
@media (max-width: 1800px) {
  .product-infocard__data {
    font-size: 14px;
    line-height: 17px;
  }
}
@media (max-width: 1650px) {
  .product-infocard__data {
    font-size: 13px;
    line-height: 17px;
    padding-right: 8px;
    margin-right: 8px;
  }
}
@media (max-width: 1024px) {
  .product-infocard__data {
    padding-right: 8px;
    margin-right: 8px;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }
}
.product-infocard__data b {
  font-size: 30px;
  line-height: 37px;
  font-weight: 500;
}
@media (max-width: 1800px) {
  .product-infocard__data b {
    font-size: 27px;
    line-height: 32px;
  }
}
@media (max-width: 1650px) {
  .product-infocard__data b {
    font-size: 22px;
    line-height: 29px;
  }
}
@media (max-width: 1024px) {
  .product-infocard__data b {
    font-size: 20px;
    line-height: 26px;
  }
}
.product-infocard__data span {
  max-width: 134px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  display: block;
  font-size: 12px;
  line-height: 16px;
  background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
@media (max-width: 1900px) {
  .product-infocard__data span {
    font-size: 11px;
    line-height: 15px;
    max-width: 125px;
  }
}
@media (max-width: 1700px) {
  .product-infocard__data span {
    font-size: 11px;
    line-height: 14px;
    max-width: 117px;
  }
}
@media (max-width: 1450px) {
  .product-infocard__data span {
    font-size: 10px;
    line-height: 11px;
    max-width: 98px;
  }
}
@media (max-width: 1400px) {
  .product-infocard__data span {
    max-width: 74px;
  }
}
@media (max-width: 1350px) {
  .product-infocard__data span {
    font-size: 9px;
    line-height: 11px;
    max-width: 68px;
  }
}
@media (max-width: 1300px) {
  .product-infocard__data span {
    font-size: 10px;
    line-height: 11px;
    max-width: 106px;
  }
}
@media (max-width: 1024px) {
  .product-infocard__data span {
    font-size: 12px;
    line-height: 14px;
    max-width: 125px;
  }
}
.product-infocard__data:last-child {
  padding-right: 0;
}
.product-infocard__data:last-child::after {
  display: none;
}
@media (max-width: 1300px) {
  .product-infocard__data:last-child {
    margin-right: 0;
  }
}
.product-infocard__data::after {
  width: 1px;
  height: 51px;
  background: #9F9F9F;
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translateY(50%);
  content: "";
}
@media (max-width: 1800px) {
  .product-infocard__data::after {
    height: 41px;
  }
}
@media (max-width: 1650px) {
  .product-infocard__data::after {
    height: 35px;
  }
}
@media (max-width: 1024px) {
  .product-infocard__data::after {
    height: 30px;
  }
}
.product-infocard__price {
  font-weight: 900;
}
.product-infocard__price b {
  font-weight: 900;
}
.product-infocard__btn {
  margin-bottom: 8px;
}
@media (max-width: 1850px) {
  .product-infocard__btn {
    max-width: 247px;
    min-width: 247px;
  }
}
@media (max-width: 1700px) {
  .product-infocard__btn {
    max-width: 230px;
    min-width: 230px;
  }
}
@media (max-width: 1500px) {
  .product-infocard__btn {
    max-width: 213px;
    min-width: 213px;
  }
}
@media (max-width: 1300px) {
  .product-infocard__btn {
    max-width: none;
    min-width: 100%;
    margin-bottom: 0;
  }
}

.product-package__list {
  width: calc(100% + 68px);
  margin: 0 auto;
  margin-left: -35px;
  margin-right: -35px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
@media (max-width: 1800px) {
  .product-package__list {
    width: calc(100% + 51px);
    margin-left: -26px;
    margin-right: -26px;
  }
}
@media (max-width: 1650px) {
  .product-package__list {
    width: calc(100% + 34px);
    margin-left: -18px;
    margin-right: -18px;
  }
}
@media (max-width: 1024px) {
  .product-package__list {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
  }
}
@media (max-width: 800px) {
  .product-package__list {
    width: 100%;
    margin: 0;
  }
}
.product-package__item {
  width: 50%;
  position: relative;
  padding: 0 34px;
  margin-bottom: 34px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}
@media (max-width: 1800px) {
  .product-package__item {
    padding: 0 25px;
  }
}
@media (max-width: 1650px) {
  .product-package__item {
    padding: 0 17px;
  }
}
@media (max-width: 1024px) {
  .product-package__item {
    padding: 0 10px;
    margin-bottom: 40px;
  }
}
@media (max-width: 800px) {
  .product-package__item {
    padding: 0;
    width: 100%;
  }
}
.product-package__item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: calc(100% - 70px);
  transform: translateX(-50%);
  height: calc(100% - 38px);
  border: 1px solid #D7D7D7;
  pointer-events: none;
  z-index: -1;
  border-radius: 8px;
}
@media (max-width: 1800px) {
  .product-package__item::after {
    width: calc(100% - 52px);
  }
}
@media (max-width: 1650px) {
  .product-package__item::after {
    width: calc(100% - 35px);
  }
}
@media (max-width: 1024px) {
  .product-package__item::after {
    width: calc(100% - 22px);
    border-radius: 10px;
    height: calc(100% - 45px);
  }
}
@media (max-width: 800px) {
  .product-package__item::after {
    width: calc(100% - 2px);
  }
}
.product-package__item.special-package .product-package__name {
  font-weight: 700;
}
.product-package__item.special-package::after {
  box-shadow: 0px 9.52789px 47.6394px rgba(129, 116, 248, 0.4);
  border-color: transparent;
}
.product-package__item.special-package .product-package__special-flag {
  display: flex;
}
.product-package__card {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  border-radius: 8px;
  padding: 29px 25px;
  padding-bottom: 0;
}
@media (max-width: 1300px) {
  .product-package__card {
    padding: 21px 17px;
    padding-bottom: 0;
  }
}
@media (max-width: 1024px) {
  .product-package__card {
    padding: 20px 15px;
    padding-bottom: 0;
    border-radius: 10px;
  }
}
.product-package__info {
  width: 100%;
  position: relative;
}
.product-package__name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 29px;
  line-height: 35px;
  text-align: center;
  background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
@media (max-width: 1800px) {
  .product-package__name {
    font-size: 23px;
    line-height: 29px;
  }
}
@media (max-width: 1650px) {
  .product-package__name {
    font-size: 20px;
    line-height: 25px;
  }
}
@media (max-width: 1024px) {
  .product-package__name {
    font-size: 20px;
    line-height: 24px;
  }
}
.product-package__stats {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.product-package__stat {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 11px;
  width: calc(50% - 12px);
}
.product-package__stat:nth-child(2n) {
  align-items: flex-start;
}
@media (max-width: 576px) {
  .product-package__stat:nth-child(2n) .product-package__stat-text {
    text-align: left;
  }
}
@media (max-width: 1650px) {
  .product-package__stat {
    width: calc(50% - 10px);
  }
}
@media (max-width: 1300px) {
  .product-package__stat {
    width: calc(50% - 8px);
  }
}
@media (max-width: 1024px) {
  .product-package__stat {
    width: calc(50% - 7px);
    margin-bottom: 8px;
  }
}
.product-package__stat:last-child {
  margin-right: 0;
}
.product-package__stat.color-stat .product-package__stat-number {
  background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.product-package__stat-number {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 59px;
  color: #000;
}
@media (max-width: 1800px) {
  .product-package__stat-number {
    font-size: 42px;
    line-height: 52px;
  }
}
@media (max-width: 1650px) {
  .product-package__stat-number {
    font-size: 37px;
    line-height: 46px;
  }
}
@media (max-width: 1300px) {
  .product-package__stat-number {
    font-size: 34px;
    line-height: 42px;
  }
}
@media (max-width: 1024px) {
  .product-package__stat-number {
    font-size: 30px;
    line-height: 40px;
  }
}
@media (max-width: 576px) {
  .product-package__stat-number {
    font-size: 24px;
    line-height: 32px;
  }
}
.product-package__stat-number b {
  font-size: 132px;
  line-height: 153px;
  font-weight: 900;
}
@media (max-width: 1800px) {
  .product-package__stat-number b {
    font-size: 119px;
    line-height: 138px;
  }
}
@media (max-width: 1650px) {
  .product-package__stat-number b {
    font-size: 102px;
    line-height: 119px;
  }
}
@media (max-width: 1300px) {
  .product-package__stat-number b {
    font-size: 85px;
    line-height: 102px;
  }
}
@media (max-width: 1024px) {
  .product-package__stat-number b {
    font-size: 70px;
    line-height: 85px;
  }
}
@media (max-width: 576px) {
  .product-package__stat-number b {
    font-size: 50px;
    line-height: 65px;
  }
}
.product-package__stat-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  color: #000;
  width: 100%;
  max-width: 234px;
  margin-top: -9px;
}
@media (max-width: 1800px) {
  .product-package__stat-text {
    max-width: 213px;
  }
}
@media (max-width: 1650px) {
  .product-package__stat-text {
    font-size: 11px;
    line-height: 15px;
    max-width: 183px;
  }
}
@media (max-width: 1300px) {
  .product-package__stat-text {
    font-size: 10px;
    line-height: 13px;
    max-width: 166px;
    margin-top: -10px;
  }
}
@media (max-width: 1024px) {
  .product-package__stat-text {
    font-size: 10px;
    line-height: 13px;
    max-width: 140px;
  }
}
@media (max-width: 576px) {
  .product-package__stat-text {
    text-align: right;
    max-width: 100%;
  }
}
.product-package__priceinfo {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-size: 23px;
  line-height: 29px;
  text-align: center;
  background: linear-gradient(89.91deg, #11BBF0 -7.12%, #D83EFF 112.36%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 17px;
}
@media (max-width: 1650px) {
  .product-package__priceinfo {
    font-size: 20px;
    line-height: 25px;
  }
}
@media (max-width: 1024px) {
  .product-package__priceinfo {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 12px;
  }
}
.product-package__bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  align-items: center;
}
.product-package__btn {
  min-width: auto;
  width: 100%;
  max-width: 439px;
  margin-bottom: 25px;
}
@media (max-width: 1024px) {
  .product-package__btn {
    margin-bottom: 15px;
    max-width: 515px;
  }
}
.product-package__description {
  width: 100%;
  max-width: 516px;
  padding: 8px 17px;
  border: 0.952669px solid #9C9C9C;
  border-radius: 8px;
  position: relative;
  min-height: 76px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  color: #000;
  background-color: #fff;
}
@media (max-width: 1650px) {
  .product-package__description {
    font-size: 12px;
    line-height: 16px;
  }
}
@media (max-width: 1024px) {
  .product-package__description {
    font-size: 14px;
    line-height: 18px;
    padding: 10px;
    max-width: 100%;
    border-radius: 10px;
    min-height: 90px;
  }
}
.product-package__description b {
  background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
}
.product-package__special-flag {
  width: 187px;
  max-width: 187px;
  position: absolute;
  top: -18px;
  right: -68px;
  z-index: 2;
  background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
  transform: rotate(45deg);
  display: none;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  padding-bottom: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  color: #FFFFFF;
}
.product-package__special-flag .icon {
  max-width: 17px;
  max-height: 17px;
  object-fit: contain;
  fill: #fff;
  margin-bottom: 5px;
}
@media (max-width: 1300px) {
  .product-package__special-flag .icon {
    max-width: 14px;
    max-height: 14px;
    margin-bottom: 4px;
  }
}
@media (max-width: 1024px) {
  .product-package__special-flag .icon {
    max-width: 15px;
    max-height: 15px;
    margin-bottom: 3px;
  }
}
@media (max-width: 1300px) {
  .product-package__special-flag {
    font-size: 11px;
    line-height: 15px;
    padding: 17px;
    padding-bottom: 4px;
    top: -15px;
    right: -73px;
  }
}
@media (max-width: 1024px) {
  .product-package__special-flag {
    font-size: 10px;
    line-height: 12px;
    padding: 10px;
    padding-bottom: 3px;
    top: -9px;
    right: -91px;
    width: 220px;
    max-width: 220px;
  }
}
@media (max-width: 576px) {
  .product-package__special-flag {
    transform: none;
    top: 0;
    right: 0;
    background: transparent;
    width: auto;
    padding: 10px;
  }
  .product-package__special-flag p {
    display: none;
  }
  .product-package__special-flag .icon {
    fill: #B35BED;
  }
}

.product-compare__list {
  width: calc(100% + 25px);
  margin-left: -13px;
  margin-right: -13px;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1300px) {
  .product-compare__list {
    flex-direction: column;
    margin: 0;
    width: 100%;
  }
}
.product-compare__column {
  width: 50%;
  padding: 0 12px;
  margin-bottom: 25px;
  position: relative;
}
@media (max-width: 1300px) {
  .product-compare__column {
    width: 100%;
    padding: 0;
    margin-bottom: 30px;
  }
  .product-compare__column:last-child {
    margin-bottom: 0;
  }
}
.product-compare__table {
  width: 100%;
  position: relative;
  border-radius: 8px;
  border: 1px solid #9C9C9C;
}
@media (max-width: 1024px) {
  .product-compare__table {
    border-radius: 10px;
  }
}
.product-compare__header {
  width: calc(100% + 2px);
  margin-left: -1px;
  margin-right: -1px;
  padding: 0 1px;
  margin-top: -1px;
  display: flex;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
}
@media (max-width: 1024px) {
  .product-compare__header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}
.product-compare__th {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  min-height: 93px;
  border-right: 1px solid #88ADEE;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
  padding: 4px 5px;
}
@media (max-width: 1650px) {
  .product-compare__th {
    font-size: 13px;
    line-height: 17px;
    min-height: 85px;
  }
}
@media (max-width: 1450px) {
  .product-compare__th {
    font-size: 11px;
    line-height: 15px;
    min-height: 72px;
  }
}
@media (max-width: 1024px) {
  .product-compare__th {
    min-height: 70px;
    font-size: 14px;
    line-height: 18px;
    padding: 5px 7px;
  }
}
@media (max-width: 576px) {
  .product-compare__th {
    font-size: 12px;
    line-height: 14px;
  }
}
.product-compare__th:last-child {
  border-right: 0;
}
.product-compare__th:nth-child(1) {
  width: 44%;
}
.product-compare__th:nth-child(2) {
  width: 28%;
}
.product-compare__th:nth-child(3) {
  width: 28%;
}
.product-compare__body {
  width: 100%;
  position: relative;
  border-top: 0;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  overflow: hidden;
  width: calc(100% + 0.5px);
}
@media (max-width: 1300px) {
  .product-compare__body {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .product-compare__body {
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
  }
}
.product-compare__tr {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DADADA;
  overflow: hidden;
}
.product-compare__tr:last-child {
  border-bottom: 0;
}
.product-compare__td {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px 5px;
  align-items: center;
  min-height: 64px;
  background-color: #98C5FF;
  color: #fff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  position: relative;
  z-index: 1;
}
.product-compare__td::after {
  content: "";
  position: absolute;
  pointer-events: none;
  top: 50%;
  left: 0;
  width: 100%;
  height: 256px;
  transform: translateY(-50%);
  z-index: -1;
  background-color: #98C5FF;
  border-right: 1px solid #DADADA;
}
@media (max-width: 1024px) {
  .product-compare__td::after {
    height: 300px;
  }
}
@media (max-width: 1650px) {
  .product-compare__td {
    font-size: 13px;
    line-height: 17px;
  }
}
@media (max-width: 1450px) {
  .product-compare__td {
    font-size: 11px;
    line-height: 15px;
  }
}
@media (max-width: 1024px) {
  .product-compare__td {
    font-size: 14px;
    line-height: 18px;
    min-height: 70px;
    padding: 5px 7px;
  }
}
@media (max-width: 576px) {
  .product-compare__td {
    font-size: 12px;
    line-height: 14px;
  }
}
.product-compare__td:last-child::after {
  border: none;
}
.product-compare__td:nth-child(1) {
  width: 44%;
}
.product-compare__td:nth-child(2) {
  width: 28%;
}
.product-compare__td:nth-child(3) {
  width: 28%;
}
.product-compare__td.second-level-td {
  background-color: #618CFA;
}
.product-compare__td.second-level-td::after {
  background-color: #618CFA;
}
.product-compare__td.third-level-td {
  background-color: #6167FA;
}
.product-compare__td.third-level-td::after {
  background-color: #6167FA;
}
.product-compare__td.fourth-level-td {
  background-color: #A461FA;
}
.product-compare__td.fourth-level-td::after {
  background-color: #A461FA;
}
.product-compare__main-td {
  background-color: #fff;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
}
.product-compare__main-td::after {
  background-color: #fff;
}
.product-compare__td-number {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 19px;
  line-height: 23px;
  background: linear-gradient(244.41deg, #C944D0 -20.74%, #2FCBF9 112.1%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-right: 8px;
  min-width: 27px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1650px) {
  .product-compare__td-number {
    font-size: 17px;
    line-height: 22px;
    min-width: 22px;
    margin-right: 5px;
  }
}
@media (max-width: 1024px) {
  .product-compare__td-number {
    font-size: 18px;
    line-height: 20px;
    margin-right: 5px;
    min-width: 24px;
  }
}
@media (max-width: 576px) {
  .product-compare__td-number {
    font-size: 16px;
    line-height: 18px;
    margin-right: 5px;
    min-width: 22px;
  }
}
.product-compare__td-name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-align: left;
  color: #000;
}
@media (max-width: 1650px) {
  .product-compare__td-name {
    font-size: 11px;
    line-height: 12px;
  }
}
@media (max-width: 1024px) {
  .product-compare__td-name {
    font-size: 11px;
    line-height: 13px;
  }
}
@media (max-width: 576px) {
  .product-compare__td-name {
    font-size: 10px;
    line-height: 12px;
  }
}

.modal-wraper {
  width: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100vh;
  max-height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(6px);
  display: flex;
  flex-direction: column;
  padding: 42px 25px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.3s;
}
@media (max-width: 1024px) {
  .modal-wraper {
    padding: 40px 15px;
  }
}
.modal-wraper.active {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.modal-wraper.thanks-popup .modal__header, .modal-wraper.error-popup .modal__header {
  font-size: 27px;
}
@media (max-width: 1350px) {
  .modal-wraper.thanks-popup .modal__header, .modal-wraper.error-popup .modal__header {
    font-size: 23px;
  }
}
@media (max-width: 1024px) {
  .modal-wraper.thanks-popup .modal__header, .modal-wraper.error-popup .modal__header {
    font-size: 24px;
  }
}
.modal-wraper.thanks-popup .modal__text, .modal-wraper.error-popup .modal__text {
  font-size: 15px;
}
@media (max-width: 1024px) {
  .modal-wraper.thanks-popup .modal__text, .modal-wraper.error-popup .modal__text {
    font-size: 14px;
  }
}
.modal-form-close__btn {
  min-width: 100%;
}
.modal__box {
  margin: auto;
  width: 100%;
  max-width: 423px;
  background: #fff;
  border: 1px solid #D6D6D6;
  border-radius: 8px;
  position: relative;
}
@media (max-width: 1024px) {
  .modal__box {
    max-width: 400px;
    border-radius: 10px;
  }
}
@media (max-width: 576px) {
  .modal__box {
    max-width: 100%;
  }
}
.modal__box .iti__selected-flag {
  margin: 0;
  height: 100%;
}
.modal__box .form-field {
  padding: 0;
  width: 100%;
  margin-bottom: 17px;
}
@media (max-width: 1024px) {
  .modal__box .form-field {
    margin-bottom: 20px;
  }
}
.modal__box .form__field-name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 100%;
  color: #A4A4A4;
  margin-bottom: 8px;
}
@media (max-width: 1024px) {
  .modal__box .form__field-name {
    font-size: 12px;
    margin-bottom: 7px;
  }
}
.modal__box .form__field-require {
  max-width: 50%;
  padding-left: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 100%;
  text-align: right;
  text-decoration-line: underline;
  margin-bottom: 8px;
  text-align: right;
  color: #FC6E6E;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}
@media (max-width: 1024px) {
  .modal__box .form__field-require {
    font-size: 12px;
    margin-bottom: 7px;
    padding-left: 5px;
  }
}
.modal__box .form__field-row {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.modal__box .form__field-input {
  width: 100%;
  position: relative;
}
.modal__box .form__field-input::after {
  display: none;
}
.modal__box .form__input {
  min-height: 51px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  padding: 12px 21px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 100%;
  color: #000;
}
.modal__box .form__input::placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 100%;
  color: #A4A4A4;
  transition: 0.3s;
}
@media (max-width: 1024px) {
  .modal__box .form__input::placeholder {
    font-size: 14px;
  }
}
.modal__box .form__input:focus {
  border-color: #C187E9;
}
.modal__box .form__input:focus::placeholder {
  color: #C187E9;
}
@media (max-width: 1024px) {
  .modal__box .form__input {
    font-size: 14px;
    padding: 10px 15px;
    min-height: 50px;
    border-radius: 5px;
  }
}
.modal__header {
  width: 100%;
  position: relative;
  min-height: 76px;
  background: #75AFF9;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  padding: 12px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: calc(100% + 2px);
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
}
@media (max-width: 1350px) {
  .modal__header {
    font-size: 17px;
  }
}
@media (max-width: 1024px) {
  .modal__header {
    font-size: 18px;
    min-height: 70px;
    padding: 10px 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}
.modal__close {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.3s;
  transform: translateX(38px) translateY(-30px);
}
@media (max-width: 1024px) {
  .modal__close {
    width: 25px;
    height: 25px;
    transform: translateX(25px) translateY(-25px);
  }
}
@media (max-width: 576px) {
  .modal__close {
    transform: translateX(0) translateY(-30px);
  }
}
.modal__close .icon {
  max-width: 25px;
  max-height: 25px;
  fill: #75AFF9;
  transition: 0.3s;
}
@media (max-width: 1024px) {
  .modal__close .icon {
    max-width: 25px;
    max-height: 25px;
  }
}
@media (min-width: 1025px) {
  .modal__close:hover .icon {
    fill: #C187E9;
  }
}
.modal__content {
  width: 100%;
  position: relative;
  background-color: #fff;
  padding: 21px 46px;
  padding-bottom: 32px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
@media (max-width: 1024px) {
  .modal__content {
    padding: 20px;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
  }
}
.modal__text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 100%;
  text-align: center;
  color: #000;
  margin-bottom: 25px;
}
@media (max-width: 1024px) {
  .modal__text {
    font-size: 14px;
    margin-bottom: 20px;
  }
}
.modal-form {
  width: 100%;
  position: relative;
}
.modal-form__btn {
  min-width: 100%;
}

.hero-section {
  background: linear-gradient(74.64deg, #C8D5E2 4.11%, #F0F7F9 98.07%);
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  z-index: 1;
  position: relative;
}
@media (max-width: 1024px) {
  .hero-section {
    padding-top: 10px;
  }
}
@media (max-width: 700px) {
  .hero-section {
    padding-top: 20px;
  }
}
@media (max-width: 576px) {
  .hero-section {
    padding-top: 120px;
    padding-bottom: 50px;
  }
}
.hero-section::after {
  width: 100%;
  height: 209px;
  background-color: #fff;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
}
@media (max-width: 1650px) {
  .hero-section::after {
    height: 192px;
  }
}
@media (max-width: 1024px) {
  .hero-section::after {
    height: 215px;
  }
}
@media (max-width: 700px) {
  .hero-section::after {
    height: 170px;
  }
}
@media (max-width: 576px) {
  .hero-section::after {
    display: none;
  }
}
.hero-box {
  width: 100%;
  max-width: 802px;
  margin: 0 auto;
  position: relative;
}
@media (max-width: 1650px) {
  .hero-box {
    max-width: 683px;
  }
}
@media (max-width: 1024px) {
  .hero-box {
    max-width: 660px;
  }
}
@media (max-width: 700px) {
  .hero-box {
    max-width: 500px;
  }
}
@media (max-width: 576px) {
  .hero-box {
    max-width: 100%;
  }
}
.hero-desctop__img {
  width: 100%;
  object-fit: contain;
  pointer-events: none;
  transform: translateY(5.7%);
}
@media (max-width: 1650px) {
  .hero-desctop__img {
    transform: translateY(4%);
  }
}
@media (max-width: 1024px) {
  .hero-desctop__img {
    transform: translate(0);
  }
}
@media (max-width: 576px) {
  .hero-desctop__img {
    display: none;
  }
}
.hero-info {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  max-height: 55%;
  top: 20%;
  max-width: 79%;
  padding-top: 3.17%;
  padding-bottom: 2%;
  padding-left: 2.65%;
  padding-right: 2.65%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 1650px) {
  .hero-info {
    top: 18.2%;
  }
}
@media (max-width: 1024px) {
  .hero-info {
    top: 14.4%;
    padding-bottom: 1.6%;
  }
}
@media (max-width: 700px) {
  .hero-info {
    justify-content: center;
    padding-bottom: 10.7%;
  }
}
@media (max-width: 576px) {
  .hero-info {
    position: relative;
    top: unset;
    max-width: 100%;
    max-height: none;
    padding: 0;
  }
}
.hero-info::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  content: "";
  width: 213px;
  height: 213px;
  border: 1px solid #B5B5B5;
  z-index: -1;
  border-radius: 50%;
  opacity: 0.5;
}
@media (max-width: 1650px) {
  .hero-info::after {
    transform: translate(-50%, -62%);
  }
}
@media (max-width: 1024px) {
  .hero-info::after {
    width: 170px;
    height: 170px;
    transform: translate(-50%, -65%);
  }
}
@media (max-width: 700px) {
  .hero-info::after {
    transform: translate(-50%, -107%);
    width: 100px;
    height: 100px;
  }
}
@media (max-width: 576px) {
  .hero-info::after {
    display: none;
  }
}
.hero-info .blur-circle__one {
  width: 55px;
  height: 55px;
  left: unset;
  right: 19%;
  top: 33.5%;
  animation-delay: 1s;
}
@media (max-width: 1650px) {
  .hero-info .blur-circle__one {
    top: 30%;
    right: 17%;
  }
}
@media (max-width: 1024px) {
  .hero-info .blur-circle__one {
    width: 65px;
    height: 65px;
  }
}
.hero-info .blur-circle__two {
  width: 68px;
  height: 68px;
  left: unset;
  right: -7%;
  top: unset;
  bottom: 7%;
  animation-delay: 1.5s;
}
@media (max-width: 1650px) {
  .hero-info .blur-circle__two {
    width: 59px;
    height: 59px;
  }
}
@media (max-width: 1024px) {
  .hero-info .blur-circle__two {
    width: 70px;
    height: 70px;
  }
}
.hero-info .blur-circle__three {
  width: 35px;
  height: 35px;
  left: 19%;
  top: 32%;
  opacity: 0.3;
  animation-delay: -1.5s;
}
@media (max-width: 1024px) {
  .hero-info .blur-circle__three {
    width: 42px;
    height: 42px;
  }
}
.hero-info .blur-circle__four {
  width: 111px;
  height: 111px;
  top: 8%;
  left: -6%;
}
@media (max-width: 1650px) {
  .hero-info .blur-circle__four {
    width: 85px;
    height: 85px;
  }
}
@media (max-width: 1024px) {
  .hero-info .blur-circle__four {
    width: 100px;
    height: 100px;
  }
}
.hero__btn {
  max-width: 294px;
  min-width: 294px;
  text-transform: uppercase;
  margin-top: 12.5%;
  z-index: 1;
}
@media (max-width: 1024px) {
  .hero__btn {
    margin-top: 15.5%;
    max-width: 345px;
    min-width: 345px;
  }
}
@media (max-width: 700px) {
  .hero__btn {
    margin-top: 5%;
    max-width: 280px;
    min-width: 280px;
  }
}
@media (max-width: 576px) {
  .hero__btn {
    margin-top: 35px;
  }
}
@media (max-width: 450px) {
  .hero__btn {
    min-width: 100%;
    max-width: 100%;
  }
}
.hero-pricelist__title {
  z-index: 1;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 46px;
  line-height: 51px;
  text-align: center;
  text-transform: uppercase;
  color: #000;
}
@media (max-width: 1650px) {
  .hero-pricelist__title {
    font-size: 37px;
    line-height: 44px;
  }
}
@media (max-width: 1024px) {
  .hero-pricelist__title {
    font-size: 28px;
    line-height: 36px;
  }
}
@media (max-width: 700px) {
  .hero-pricelist__title {
    font-size: 24px;
    line-height: 30px;
  }
}
@media (max-width: 576px) {
  .hero-pricelist__title {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 17px;
  }
}
.hero-pricelist__year {
  z-index: 1;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 136px;
  line-height: 136px;
  text-align: center;
  text-transform: uppercase;
  background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
@media (max-width: 1650px) {
  .hero-pricelist__year {
    font-size: 119px;
    line-height: 119px;
  }
}
@media (max-width: 1024px) {
  .hero-pricelist__year {
    font-size: 100px;
    line-height: 100px;
  }
}
@media (max-width: 700px) {
  .hero-pricelist__year {
    font-size: 52px;
    line-height: 52px;
  }
}
@media (max-width: 576px) {
  .hero-pricelist__year {
    font-size: 92px;
    line-height: 92px;
  }
}
.hero-one-circle {
  bottom: unset;
  left: unset;
  right: -10%;
  top: 15%;
  animation-duration: 2.5s;
  animation-delay: 1s;
}
@media (max-width: 1024px) {
  .hero-one-circle {
    top: 10%;
    right: -5%;
  }
}
@media (max-width: 576px) {
  .hero-one-circle {
    top: 0;
    right: -3%;
  }
}
.hero-two-circle {
  bottom: 29%;
  left: -3%;
}
@media (max-width: 1024px) {
  .hero-two-circle {
    left: 0;
  }
}
@media (max-width: 576px) {
  .hero-two-circle {
    bottom: 10%;
  }
}
.hero-three-circle {
  bottom: 24%;
  left: unset;
  right: 10%;
  width: 123px;
  height: 123px;
  z-index: 0;
}
@media (max-width: 1650px) {
  .hero-three-circle {
    width: 93px;
    height: 93px;
    right: 8%;
  }
}
@media (max-width: 1024px) {
  .hero-three-circle {
    width: 90px;
    height: 90px;
    bottom: 33%;
    right: 15%;
  }
}
@media (max-width: 576px) {
  .hero-three-circle {
    display: none;
  }
}
.hero-four-circle {
  width: 81px;
  height: 81px;
  bottom: unset;
  top: 17%;
  left: 29%;
}
@media (max-width: 1650px) {
  .hero-four-circle {
    width: 70px;
    height: 70px;
    left: 30%;
  }
}
@media (max-width: 1024px) {
  .hero-four-circle {
    left: 34%;
    width: 60px;
    height: 60px;
    top: 20%;
  }
}
@media (max-width: 576px) {
  .hero-four-circle {
    display: none;
  }
}

.design-list-section {
  padding-bottom: 25px;
}
@media (max-width: 1024px) {
  .design-list-section {
    padding-top: 35px;
    padding-bottom: 5px;
  }
}

.design-planning-section {
  padding-top: 59px;
  padding-bottom: 51px;
}
@media (max-width: 1650px) {
  .design-planning-section {
    padding-bottom: 42px;
    padding-top: 53px;
  }
}
@media (max-width: 1024px) {
  .design-planning-section {
    padding-top: 40px;
    padding-bottom: 20px;
  }
}
.design-planning-section .global-title__wrapp {
  margin-bottom: 64px;
}
@media (max-width: 1650px) {
  .design-planning-section .global-title__wrapp {
    margin-bottom: 55px;
  }
}
@media (max-width: 1024px) {
  .design-planning-section .global-title__wrapp {
    margin-bottom: 40px;
  }
}
.design-planning__title {
  font-weight: 900;
}

.marketing-planning-section {
  padding-top: 51px;
  padding-bottom: 8px;
}
@media (max-width: 1024px) {
  .marketing-planning-section {
    padding-top: 40px;
    padding-bottom: 10px;
  }
}
.marketing-planning-section .product-infocard__list {
  position: relative;
}
.marketing-planning-section .product-infocard__list::after {
  position: absolute;
  top: 0;
  left: 34px;
  content: "SEO";
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 170px;
  line-height: 170px;
  text-align: center;
  color: #F9F9F9;
  transform: translateY(-77%);
}
@media (max-width: 1650px) {
  .marketing-planning-section .product-infocard__list::after {
    font-size: 128px;
    line-height: 128px;
  }
}
@media (max-width: 1024px) {
  .marketing-planning-section .product-infocard__list::after {
    display: none;
  }
}

.marketing-package-section {
  padding-top: 42px;
  padding-bottom: 34px;
}
@media (max-width: 1024px) {
  .marketing-package-section {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.marketing-package-section .global-container {
  max-width: 1306px;
}
@media (max-width: 1024px) {
  .marketing-package-section .global-container {
    max-width: 1530px;
  }
}
@media (max-width: 1024px) {
  .marketing-package-section .global-title__wrapp {
    margin-bottom: 15px;
  }
}
.marketing-package__text {
  margin-bottom: 42px;
  text-align: center;
}
@media (max-width: 1650px) {
  .marketing-package__text {
    margin-bottom: 34px;
  }
}
@media (max-width: 1024px) {
  .marketing-package__text {
    margin-bottom: 25px;
  }
}
.marketing-package__btn {
  min-width: 350px;
  margin-left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 576px) {
  .marketing-package__btn {
    min-width: 100%;
  }
}

.marketing-compare-section {
  padding-top: 34px;
  padding-bottom: 8px;
}
@media (max-width: 1300px) {
  .marketing-compare-section {
    padding-bottom: 34px;
  }
}
@media (max-width: 1024px) {
  .marketing-compare-section {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media (max-width: 576px) {
  .marketing-compare-section .global-title__wrapp {
    margin-bottom: 30px;
  }
}

.graphic-design-section {
  padding-bottom: 29px;
  padding-top: 68px;
}
@media (max-width: 1650px) {
  .graphic-design-section {
    padding-top: 55px;
  }
}
@media (max-width: 1024px) {
  .graphic-design-section {
    padding-top: 40px;
    padding-bottom: 5px;
  }
}

.contact-section {
  padding-top: 42px;
  padding-bottom: 25px;
}
@media (max-width: 1024px) {
  .contact-section {
    padding-top: 35px;
    padding-bottom: 30px;
  }
}
.contact-section .global-container {
  max-width: 1195px;
}
@media (max-width: 1024px) {
  .contact-section .global-container {
    max-width: 1400px;
  }
}
@media (min-width: 1025px) {
  .contact-section a.contact-form__detail:hover::after {
    width: 100%;
  }
}
.contact-form {
  width: 100%;
  position: relative;
  display: flex;
}
@media (max-width: 800px) {
  .contact-form {
    flex-direction: column-reverse;
  }
}
.contact-form__info {
  width: 100%;
  max-width: calc(100% - 708px);
  min-width: 358px;
  background: linear-gradient(224.78deg, #538CFB 3.56%, #EE9DFB 96.77%);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 42px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
@media (max-width: 1650px) {
  .contact-form__info {
    padding: 34px;
  }
}
@media (max-width: 1024px) {
  .contact-form__info {
    padding: 20px;
    min-width: 320px;
    max-width: calc(100% - 830px);
  }
}
@media (max-width: 800px) {
  .contact-form__info {
    min-width: 100%;
    max-width: none;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 20px 15px;
  }
}
.contact-form__infobox {
  margin-bottom: 25px;
}
.contact-form__infobox:last-child {
  margin-bottom: 0;
}
@media (max-width: 1650px) {
  .contact-form__infobox {
    margin-bottom: 21px;
  }
}
@media (max-width: 1024px) {
  .contact-form__infobox {
    margin-bottom: 20px;
  }
}
.contact-form-infobox__title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #fff;
  margin-bottom: 8px;
}
@media (max-width: 1650px) {
  .contact-form-infobox__title {
    margin-bottom: 6px;
  }
}
@media (max-width: 1024px) {
  .contact-form-infobox__title {
    margin-bottom: 7px;
    font-size: 16px;
    line-height: 20px;
  }
}
.contact-form-infobox__content {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.contact-form__detail {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  transition: 0.3s;
  margin-bottom: 8px;
  position: relative;
  width: fit-content;
}
@media (max-width: 1650px) {
  .contact-form__detail {
    font-size: 17px;
    line-height: 20px;
  }
}
@media (max-width: 1024px) {
  .contact-form__detail {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
  }
}
.contact-form__detail::after {
  width: 0;
  height: 1px;
  position: absolute;
  bottom: 1px;
  transition: 0.3s;
  left: 0;
  content: "";
  background-color: #fff;
}
.contact-form__detail:last-child {
  margin-bottom: 0;
}
.contact-form__box {
  width: 100%;
  max-width: 100%;
  background-color: #fff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid #D7D7D7;
  border-left: none;
  padding: 46px;
}
@media (max-width: 1650px) {
  .contact-form__box {
    padding: 34px;
  }
}
@media (max-width: 1024px) {
  .contact-form__box {
    padding: 20px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
@media (max-width: 800px) {
  .contact-form__box {
    padding: 20px 15px;
    border: 1px solid #D7D7D7;
    border-bottom: 0;
    border-radius: 5px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.contact-form__btn {
  min-width: 307px;
}
@media (max-width: 1024px) {
  .contact-form__btn {
    min-width: 100%;
  }
}

.development-section {
  padding-top: 55px;
  padding-bottom: 21px;
}
@media (max-width: 1024px) {
  .development-section {
    padding-top: 40px;
    padding-bottom: 20px;
  }
}

.ppc-planning-section {
  padding-top: 51px;
  padding-bottom: 4px;
}
@media (max-width: 1024px) {
  .ppc-planning-section {
    padding-top: 25px;
    padding-bottom: 10px;
  }
}
.ppc-planning-section .product-infocard__list {
  position: relative;
}
.ppc-planning-section .product-infocard__list::after {
  position: absolute;
  top: 0;
  left: 34px;
  content: "PPC";
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 170px;
  line-height: 170px;
  text-align: center;
  color: #F9F9F9;
  transform: translateY(-77%);
}
@media (max-width: 1650px) {
  .ppc-planning-section .product-infocard__list::after {
    font-size: 128px;
    line-height: 128px;
  }
}
@media (max-width: 1024px) {
  .ppc-planning-section .product-infocard__list::after {
    display: none;
  }
}

.ppc-packet-section {
  padding-top: 38px;
  padding-bottom: 38px;
}
@media (max-width: 1300px) {
  .ppc-packet-section {
    padding-bottom: 59px;
  }
}
@media (max-width: 1024px) {
  .ppc-packet-section {
    padding-top: 25px;
    padding-bottom: 40px;
  }
}
.ppc-packet__list {
  width: calc(100% + 34px);
  margin-right: -18px;
  margin-left: -18px;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1300px) {
  .ppc-packet__list {
    width: 100%;
    margin: 0;
  }
}
.ppc-packet__item {
  width: 33.3333333333%;
  display: flex;
  flex-direction: column;
  height: fit-content;
  align-items: center;
  padding: 0 17px;
  margin-bottom: 34px;
}
@media (max-width: 1301px) {
  .ppc-packet__item:nth-child(3n-2) {
    align-items: flex-start;
  }
}
@media (min-width: 1301px) {
  .ppc-packet__item:nth-child(3n) {
    align-items: flex-end;
  }
}
@media (max-width: 1300px) {
  .ppc-packet__item {
    width: 100%;
    padding: 0;
  }
}
@media (max-width: 1300px) {
  .ppc-packet__item:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 1024px) {
  .ppc-packet__item {
    margin-bottom: 40px;
  }
  .ppc-packet__item:last-child {
    margin-bottom: 0;
  }
}
.ppc-packet__card {
  width: 100%;
  max-width: 363px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1300px) {
  .ppc-packet__card {
    max-width: 100%;
  }
}
.ppc-packet__name {
  width: 100%;
  position: relative;
  min-height: 68px;
  padding: 12px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  background-color: #75AFF9;
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
@media (max-width: 1650px) {
  .ppc-packet__name {
    padding: 12px 21px;
  }
}
@media (max-width: 1300px) {
  .ppc-packet__name {
    font-size: 17px;
    line-height: 20px;
    min-height: 59px;
  }
}
@media (max-width: 1024px) {
  .ppc-packet__name {
    min-height: 60px;
    padding: 10px 15px;
    font-size: 18px;
    line-height: 22px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
}
.ppc-packet__content {
  width: 100%;
  position: relative;
  background-color: #fff;
  padding: 21px 25px;
  padding-bottom: 34px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #D7D7D7;
  border-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  overflow: hidden;
}
@media (max-width: 1650px) {
  .ppc-packet__content {
    padding: 21px;
    padding-bottom: 25px;
  }
}
@media (max-width: 1024px) {
  .ppc-packet__content {
    padding: 20px 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
.ppc-packet__text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 150%;
  text-align: center;
  color: #000;
  margin-bottom: 21px;
}
@media (max-width: 1024px) {
  .ppc-packet__text {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 15px;
  }
}
.ppc-packet__options {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ppc-packet__options li {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #000;
  margin-bottom: 21px;
  width: fit-content;
  text-align: center;
  position: relative;
  padding-left: 15px;
  text-decoration-line: underline;
  text-decoration-style: dashed;
  text-decoration-thickness: from-font;
}
.ppc-packet__options li::before {
  background: url(../svg/done-purple.svg) no-repeat;
  background-size: 11px 9px;
  width: 11px;
  height: 9px;
  display: flex;
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
}
@media (max-width: 1024px) {
  .ppc-packet__options li::before {
    background-size: 13px 11px;
    width: 13px;
    height: 11px;
    top: 6px;
  }
}
@media (max-width: 1024px) {
  .ppc-packet__options li {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 15px;
    padding-left: 18px;
  }
}
@media (max-width: 576px) {
  .ppc-packet__options li {
    text-align: left;
    width: 100%;
  }
}
.ppc-packet__pricebox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ppc-packet__price {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 150%;
  background: linear-gradient(90deg, #88BCF9 1.2%, #B458EC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 8px;
}
@media (max-width: 1024px) {
  .ppc-packet__price {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }
}
.ppc-packet__discountprice {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 150%;
  text-align: center;
  text-decoration-line: line-through;
  color: #ADADAD;
  margin-bottom: 21px;
}
@media (max-width: 1024px) {
  .ppc-packet__discountprice {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
  }
}
.ppc-packet__btn {
  min-width: auto;
  width: 100%;
  max-width: 277px;
}
@media (max-width: 1024px) {
  .ppc-packet__btn {
    max-width: 325px;
  }
}
@media (max-width: 450px) {
  .ppc-packet__btn {
    max-width: 100%;
  }
}
.ppc-packet__discount-symbol {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 136px;
  line-height: 150%;
  text-align: center;
  color: #FAFAFA;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 1650px) {
  .ppc-packet__discount-symbol {
    font-size: 102px;
  }
}
@media (max-width: 1024px) {
  .ppc-packet__discount-symbol {
    font-size: 90px;
  }
}